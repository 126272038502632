import { COWeight } from 'app/shared/components/CO-weight';
import { Quantity } from 'app/shared/components/Quantity';
import {
    BOXES,
    BOX_UNIT,
    PALLETS,
    PALLETS_SINGLE,
    PRODUCT_INFO_WAREHOUSE_LABEL,
    LOT,
    OUTLET, SKU,
} from 'i18n/translations/TR';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import { Packing } from '../../../products/domain/model/Packing';
import OrderLine from '../../model/OrderLine';
import { OrderLineDeliveryDate } from './OrderLineDeliveryDate';

interface OrderLineProductInfoProps {
    getPackingCatalogIsLoading: boolean;
    line: OrderLine;
    packing: Packing | undefined;
}

export const OrderLineProductInfo = (props: OrderLineProductInfoProps): React.ReactElement => {
    const image = props.packing?.image;
    const collection = props.packing?.collectionName;

    const { t } = useTranslation();
    const portalOrigin = useOrigin();
    return (
        <div className="product-info">
            {props.getPackingCatalogIsLoading ? (
                <Skeleton
                    circle
                    width={70}
                    height={70}
                />
            ) : (
                <div className="product-image">
                    {image ? (<img src={image.value} alt="product" />) : null }
                </div>
            )}

            <div className="product-item-details">

                {props.getPackingCatalogIsLoading ? (
                    <Skeleton />
                ) : (
                    <div className="product-subtitle cart">
                        {collection?.value}
                    </div>
                )}

                <div className="product-title">
                    <span>
                        {props.line.description}
                        {props.getPackingCatalogIsLoading ? (
                            <Skeleton inline width={70} />
                        ) : (
                            <>
                                <span className="product-title-measure">
                                    {props.packing?.format?.value}
                                </span>
                                {props.packing && props.packing?.outlet ? (
                                    <span className="product-outlet-label">
                                        <span className="product-stock">
                                            {t(OUTLET)}
                                        </span>
                                    </span>
                                ) : null}
                            </>

                        ) }
                    </span>

                </div>

                <div className="product-cart-data-wrap">
                    <div className="product-tag">
                        <span className="product-tag-label">{t(SKU).toUpperCase()}</span>
                        <span className="product-tag-value">{props.line.productId.value}</span>
                    </div>
                    {props.line.tone !== null ? (
                        <div className="product-tag">
                            <span className="product-tag-label">{t(LOT).toUpperCase()}</span>
                            <span className="product-tag-value">{props.line.tone?.value ?? '-' }</span>
                        </div>
                    ) : null}
                    {props.line.deliveryDate !== null ? (
                        <OrderLineDeliveryDate line={props.line} />
                    ) : null}
                    {props.line.pallets.value == null || props.line.pallets.value < 1 || props.line.esPico ? null : (
                        <div className="product-tag">
                            <Quantity
                                value={props.line.pallets.value}
                                label={
                                    props.line.pallets.value > 1 ?
                                        `${t(PALLETS).toLowerCase()}`
                                        : `${t(PALLETS_SINGLE).toLowerCase()}`
                                }
                            />
                        </div>
                    )}
                    { props.line.boxesTotal.value < 1 ? null : (
                        <div className="product-tag">
                            <Quantity
                                value={props.line.boxesTotal.value}
                                label={
                                    props.line.boxesTotal.value > 1 ?
                                        `${t(BOXES).toLowerCase()}`
                                        : `${t(BOX_UNIT).toLowerCase()}`
                                }
                            />
                        </div>
                    )}
                    <div className="product-tag">
                        <Quantity
                            value={props.line.quantity.amount}
                            label={props.line.quantity.unitLabel(portalOrigin)}
                        />
                    </div>

                    <div className="product-tag">
                        <COWeight value={props.line.weight?.weight ? props.line.weight.weight : 0} />
                    </div>
                    {props.line.warehouse ? (
                        <div className="product-tag">
                            <span className="product-tag-label">
                                {t(PRODUCT_INFO_WAREHOUSE_LABEL)}
                            </span>
                            <span className="product-tag-value">
                                {props.line.warehouse.code}
                            </span>
                        </div>
                    ) : null}
                    {props.line.status ? (
                        <div className="product-tag">
                            <span className="product-tag-label">
                                {props.line.status.translate().toUpperCase()}
                            </span>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
