import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { useMutation, UseMutationResult } from 'react-query';
import { ApiCartSummaryRepository } from '../../infrastructure/repository/ApiCartSummaryRepository';

interface CartSummaryRepositoryMutationsInterface {
    mutationCheckout: UseMutationResult<void, unknown, void, unknown>
}

export const useCartSummaryRepository = (): CartSummaryRepositoryMutationsInterface => {
    const client = TencerApiClientSingleton.getInstance();
    const repository = new ApiCartSummaryRepository(client);

    const mutationCheckout = useMutation(
        async () => repository.checkout(),
    );

    return {
        mutationCheckout,
    };
};
