import { ApiProduct } from 'app/home/infrastructure/api/response/ApiProduct';
import { ProductPrice as APIProductPacking } from 'app/shared/infrastructure/tencer-api/dto/responses/ProductPrice';
import { ApiCollectionList } from '../../../../../collection-details/components/shared/ApiCollectionList';
import { ApiProductDetail } from '../../../../../product-details/shared/domain/ApiProductDetail';
import { Packing } from '../../../../../products/domain/model/Packing';
import { Product } from '../../../../../products/domain/model/Product';
import { CollectionId } from '../../../../../products/domain/value-object/CollectionId';
import { CollectionName } from '../../../../../products/domain/value-object/CollectionName';
import { Image } from '../../../../../products/domain/value-object/Image';
import { ProductFormat } from '../../../../../products/domain/value-object/ProductFormat';
import { ProductId } from '../../../../../products/domain/value-object/ProductId';
import { ProductPrice } from '../../../../../products/domain/value-object/ProductPrice';

export class ProductMapper {

    private static mapPrice(price: APIProductPacking | null):ProductPrice | null {
        if (!price) {
            return null;
        }
        return new ProductPrice(
            price.value,
            price.quality,
            price.discount,
            price.palletPrice,
        );
    }

    static fromApiProduct(response: ApiProduct): Product {

        return new Product(
            new ProductId(response.product),
            new Packing(
                response.collectionId ? new CollectionId(response.collectionId) : null,
                new ProductId(response.product),
                new Image(response.image),
                new CollectionName(response.collection),
                new ProductFormat(response.format),
                response.description,
                response.boxMeter,
                response.boxMeterL,
                response.boxPiece,
                response.boxWeight,
                response.default,
                response.palletBox,
                response.palletPlane,
                response.palletPiece,
                response.palletMeter,
                response.heights,
                response.box,
                response.code,
                response.outlet,
                response.stockEntryDate ? new Date(response.stockEntryDate) : null,
                response.tile,
                null,
                response.code,
                response.unit,
                response.paste,
            ),
            response.box,
            this.mapPrice(response.price),
        );
    }

    static fromApiProductDetail(response: ApiProductDetail): Product {
        const price = response.price ? new ProductPrice(
            response.price.value,
            response.price.quality,
            response.price.discount,
            response.price.palletPrice,
        ) : null;
        return new Product(
            new ProductId(response.product),
            new Packing(
                response.collectionId ? new CollectionId(response.collectionId) : null,
                new ProductId(response.product),
                new Image(response.image),
                new CollectionName(response.collection),
                new ProductFormat(response.format),
                response.description,
                response.boxMeter,
                response.boxMeterL,
                response.boxPiece,
                response.boxWeight,
                response.default,
                response.palletBox,
                response.palletPlane,
                response.palletPiece,
                response.palletMeter,
                response.heights,
                response.box,
                response.code,
                response.outlet,
                response.stockEntryDate ? new Date(response.stockEntryDate) : null,
                response.tile,
                null,
                response.code,
                response.unit,
                response.paste,
            ),
            response.box,
            price,
        );
    }

    static fromApiCollectionList(response: ApiCollectionList): Product {
        const price = response.price ? new ProductPrice(
            response.price.value,
            response.price.quality,
            response.price.discount,
            response.price.palletPrice,
        ) : null;
        return new Product(
            new ProductId(response.product),
            new Packing(
                response.collectionId ? new CollectionId(response.collectionId) : null,
                new ProductId(response.product),
                response.image ? new Image(response.image) : null,
                response.collection ? new CollectionName(response.collection) : null,
                response.format ? new ProductFormat(response.format) : null,
                response.description,
                response.boxMeter,
                response.boxMeterL,
                response.boxPiece,
                response.boxWeight,
                response.default,
                response.palletBox,
                response.palletPlane,
                response.palletPiece,
                response.palletMeter,
                response.heights,
                response.box,
                response.code,
                response.outlet,
                response.stockEntryDate ? new Date(response.stockEntryDate) : null,
                response.tile,
                response.stock,
                response.code,
                response.unit,
                response.paste,
                response.enabled,
            ),
            response.box,
            price,
        );
    }

}
