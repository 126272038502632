import React, { createContext, ReactElement, useMemo } from 'react';
import { InvoiceRepository } from '../../invoices/domain/repository/InvoiceRepository';
import { CreditMemoRepository } from '../../cartSummary/domain/repository/CreditMemoRepository';
import { ErpUserRepository } from '../../account/domain/repository/ErpUserRepository';
import { ICartSummaryRepository } from '../../cartSummary/domain/repository/CartSummaryRepository';
import { AuthenticationRepository } from '../../login/domain/repository/AuthenticationRepository';
import { TencerApiClientSingleton } from '../hooks/TencerApiClientSingleton';
import ApiCreditMemoRepository from '../../orders/infrastructure/repository/api/ApiCreditMemoRepository';
import { ApiErpUserRepository } from '../../account/infrastructure/api/repository/ApiErpUserRepository';
import { ApiCartSummaryRepository } from '../../cartSummary/infrastructure/repository/ApiCartSummaryRepository';
import { ApiAuthenticationRepository } from '../../login/infrastructure/repository/api/ApiAuthenticationRepository';
import { ApiInvoiceRepository } from '../../invoices/infrastructure/repository/api/ApiInvoiceRepository';
import { ApiCourierRepository } from '../../cartSummary/infrastructure/repository/ApiCourierRepository';
import { ApiTransportRepository } from '../../cartSummary/infrastructure/repository/ApiTransportRepository';
import { ApiWowCourierRepository } from '../../cartSummary/infrastructure/repository/ApiWowCourierRepository';
import { CourierRepository } from '../../cartSummary/domain/repository/CourierRepository';
import { TransportRepository } from '../../cartSummary/domain/repository/TransportRepository';
import { WowCourierRepository } from '../../cartSummary/domain/repository/WowCourierRepository';
import { ProductFilterRepository } from '../../products/domain/repository/ProductFilterRepository';
import { LocalProductFilterRepository } from '../../products/infrastructure/repository/local/LocalProductFilterRepository';

export interface IDependencyInjection {
    invoiceRepository: InvoiceRepository;
    erpUserRepository: ErpUserRepository;
    courierRepository: CourierRepository;
    cartRepository: ICartSummaryRepository;
    transportRepository: TransportRepository;
    wowCourierRepository: WowCourierRepository;
    authenticationRepository: AuthenticationRepository;
    creditMemoRepository: CreditMemoRepository;
    productFilterRepository: ProductFilterRepository;
}

export const DependencyInjectionContext = createContext<IDependencyInjection>(
    {} as IDependencyInjection,
);

type ProviderProps = {
    children: ReactElement,
};

export const DependencyInjectionProvider = ({ children }: ProviderProps): ReactElement => {

    const stateValues = useMemo(() => {
        const apiClient = TencerApiClientSingleton.getInstance();

        const invoiceRepository = new ApiInvoiceRepository(apiClient);
        const courierRepository = new ApiCourierRepository(apiClient);
        const erpUserRepository = new ApiErpUserRepository(apiClient);
        const transportRepository = new ApiTransportRepository(apiClient);
        const cartRepository = new ApiCartSummaryRepository(apiClient);
        const wowCourierRepository = new ApiWowCourierRepository(apiClient);
        const creditMemoRepository = new ApiCreditMemoRepository(apiClient);
        const authenticationRepository = new ApiAuthenticationRepository(apiClient);
        const productFilterRepository = new LocalProductFilterRepository();

        return {
            cartRepository,
            invoiceRepository,
            courierRepository,
            erpUserRepository,
            transportRepository,
            wowCourierRepository,
            creditMemoRepository,
            authenticationRepository,
            productFilterRepository,
        };
    }, []);

    return (
        <DependencyInjectionContext.Provider value={stateValues}>
            {children}
        </DependencyInjectionContext.Provider>
    );
};
