import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseCartSummaryResponse } from '../../../../hooks/useCartSummary';
import { PO_LABEL } from '../../../../../../../i18n/translations/TR';

interface IOrderPoProps {
    UseCartSummary: UseCartSummaryResponse
}
export const OrderPoField = ({
    UseCartSummary,
}: IOrderPoProps): React.ReactElement => {
    const { t } = useTranslation();

    const poRegister = UseCartSummary.cartSummary.cartCheckoutForm.clientRefInput;
    const poError = UseCartSummary.cartSummary.cartCheckoutForm.form.formState.errors.clientRef;

    return (
        <div className="po-container">
            <label htmlFor="clientRef" className="cart-content-subtitle">
                <span>{t(PO_LABEL)}</span>
            </label>
            <input
                type="text"
                className="po-input"
                id="clientRef"
                maxLength={20}
                {...poRegister}
            />
            {
                poError ? (
                    <div className="error-input-form">
                        {poError.message}
                    </div>
                ) : null
            }
        </div>
    );
};
