import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    CONTACT_SUPPORT, PAYMENT_KO_TEXT,
    PAYMENT_KO_TITLE,
    TRY_AGAIN,
} from '../../../../../../i18n/translations/TR';
import { CActionButton } from '../../../../../shared/components/CActionButton/CActionButton';
import { TencerRoutes } from '../../../../../shared/tencer-routing/TencerRoutes';

import { ReactComponent as IconError } from '../../../../../../assets/img/icons/ic-error.svg';
import { CONTACT_EMAIL } from '../../../../../constants/hrefConstants';

export const PaymentKo: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onRequestSupport = (): void => { window.location.href = `mailto:${CONTACT_EMAIL}`; };

    return (
        <div className="payment-result payment-result-ko">
            <div className="header">
                <div className="title">
                    <h1 className="section-title">
                        <span className="icon"><IconError /></span>
                        <span className="text">{t(PAYMENT_KO_TITLE)}</span>
                    </h1>
                </div>
            </div>
            <div className="body">
                <p>{t(PAYMENT_KO_TEXT)}</p>
                <div className="actions">
                    <CActionButton
                        text={t(TRY_AGAIN)}
                        onClick={():void => { navigate(TencerRoutes.CART_SUMMARY); }}
                    />
                    <CActionButton
                        text={t(CONTACT_SUPPORT)}
                        onClick={onRequestSupport}
                        className="btn-primary-outline"
                    />
                </div>
            </div>
        </div>
    );
};
