import { ErpUserRepository } from '../../../domain/repository/ErpUserRepository';
import { ErpUserId } from '../../../domain/value-objects/erp-user-id';
import { ErpUser } from '../../../domain/erp-user';
import { ErpUserMapper } from '../data-mapper/erp-user.mapper';
import { TencerApiClient } from '../../../../shared/infrastructure/tencer-api/TencerApiClient';
import { GetErpUserRequestDTO } from '../dto/request/get-erp-user-request.DTO';
import { PaymentTerms } from '../../../../orders/model/value-objects/PaymentTerms';
import { PaymentTermsResponse } from '../../../../shared/infrastructure/tencer-api/dto/responses/PaymentTermsResponse';
import { PaymentTermsMapper } from '../../../../shared/infrastructure/tencer-api/data-mapper/payment-terms.mapper';

export class ApiErpUserRepository implements ErpUserRepository {

    constructor(private readonly apiClient: TencerApiClient) {
    }

    public async getPaymentTerms(): Promise<PaymentTerms> {
        const response: PaymentTermsResponse = await this.apiClient.getErpUserPaymentTerms();
        return PaymentTermsMapper.map(response);
    }

    public async getErpUser(erpUserId: ErpUserId): Promise<ErpUser> {
        const request = {
            erpUserId: erpUserId.value,
        } as GetErpUserRequestDTO;
        const erpUser = await this.apiClient.getErpUser(request);

        return ErpUserMapper.toDomain(erpUser);
    }

}
