import {
    PRODUCT_INFO_WAREHOUSE_LABEL,
    LOT,
} from 'i18n/translations/TR';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceLine } from '../../../domain/model/InvoiceLine';

interface Props {
    line: InvoiceLine;
}

export const InvoiceLineProductInfo = (props: Props): React.ReactElement => {

    const { t } = useTranslation();

    return (
        <div className="product-info">
            <div className="product-item-details">
                <div className="product-title">
                    <span>
                        {props.line.description}
                    </span>
                </div>

                <div className="product-cart-data-wrap">
                    {props.line.tone !== null && props.line.isItem ? (
                        <div className="product-tag">
                            <span className="product-tag-label">{t(LOT).toUpperCase()}</span>
                            <span className="product-tag-value">{props.line.tone ?? '-' }</span>
                        </div>
                    ) : null}

                    {props.line.locationId && props.line.isItem ? (
                        <div className="product-tag">
                            <span className="product-tag-label">
                                {t(PRODUCT_INFO_WAREHOUSE_LABEL)}
                            </span>
                            <span className="product-tag-value">
                                {props.line.locationId}
                            </span>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
