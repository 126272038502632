import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    APPLY_FILTERS,
    BRANDS,
    COLLECTIONS,
    FILTERS,
    MATERIAL,
    REMOVE_FILTERS,
    SELECT,
    SHOW_ONLY,
} from 'i18n/translations/TR';
import { Offcanvas } from 'react-bootstrap';
import { ProductFilter } from 'app/orders/model/ProductFilter';
import { ProductFilterCollection } from 'app/orders/model/ProductFilterCollection';
import { Spinner } from 'app/shared/Spinner';
import { useFilterFieldsList } from './shared/FilterFieldsList';

interface Props {
    show: boolean,
    onHide: () => void,
    onReset: () => void,
    filters: ProductFilterCollection,
    onApply: (filters : ProductFilterCollection) => void,
}
interface FormInputs {
    material: string,
    collection: string,
    checkbox: boolean,
    collectionString: string,
    pasteString: string,
    brand: string,
    brandString: string,
}

export const FilterModal: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, reset } = useForm<FormInputs>({
        mode: 'onChange',
    });
    const response = useFilterFieldsList();
    const data = response.data;

    const removeFilters = (): void => {
        reset();
    };

    useEffect(() => {
        const filters = props.filters;
        setValue('material', filters.getValue('material')?.value.toString() ?? '');
        setValue('collection', filters.getValue('collection')?.value.toString() ?? '');
        setValue('checkbox', !!filters.getValue('stock')?.value.toString());
        setValue('brand', filters.getValue('brand')?.value.toString() ?? '');
    }, []);

    const submitHandler: SubmitHandler<FormInputs> = (formData: FormInputs) => {
        const appliedFilters = [];

        if (formData.collection) {
            appliedFilters.push(new ProductFilter(
                'collection',
                formData.collection,
            ));
        }

        if (formData.material) {
            appliedFilters.push(
                new ProductFilter(
                    'material',
                    formData.material,
                ),
            );
        }

        if (formData.checkbox) {
            appliedFilters.push(
                new ProductFilter(
                    'stock',
                    formData.checkbox ? 'true' : 'false',
                ),
            );
        }

        if (formData.brand) {
            appliedFilters.push(
                new ProductFilter(
                    'brand',
                    formData.brand,
                ),
            );
        }

        const collection = new ProductFilterCollection(appliedFilters);
        props.onApply(collection);
    };

    return (
        <Offcanvas
            show={props.show}
            placement="end"
            className="offcanvas"
            onHide={(): void => {
                props.onHide();
            }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t(FILTERS)}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

                { response.loading ? <Spinner classCss="header-section-spinner" /> : (

                    <form id="filter-form" onSubmit={handleSubmit(submitHandler)}>
                        <div className="mb-4">

                            <label htmlFor="selectFormat" className="form-label">{t(MATERIAL)}</label>
                            <select
                                className="form-select"
                                id="selectFormat"
                                {...register(
                                    'material',
                                )}
                                onChange={(e): void => setValue('pasteString', e.target.selectedOptions[0].label)}

                            >
                                <option value="">{t(SELECT)}</option>
                                {data?.pastes ?
                                    (
                                        data.pastes.map((paste) => (
                                            <option key={paste} value={paste}>{paste}</option>
                                        ))
                                    ) : null}
                            </select>
                        </div>

                        <div className="mb-4">

                            <label htmlFor="selectCollection" className="form-label">{t(COLLECTIONS)}</label>
                            <select
                                className="form-select"
                                id="selectCollection"
                                {...register(
                                    'collection',
                                )}
                                onChange={(e): void => {
                                    props.filters.addFilter(
                                        new ProductFilter('collection', e.target.selectedOptions[0].value),
                                    );
                                    setValue('collectionString', e.target.selectedOptions[0].label);
                                    setValue('collection', e.target.selectedOptions[0].value);
                                }}
                            >
                                <option value="">{t(SELECT)}</option>
                                {data?.collections ?
                                    (
                                        data.collections.map((collection) => (
                                            <option
                                                key={collection.id}
                                                value={collection.id}
                                                label={collection.name}
                                            >
                                                {collection.name}
                                            </option>

                                        ))
                                    ) : null}
                            </select>
                        </div>

                        <div className="mb-4">

                            <label htmlFor="selectBrand" className="form-label">{t(BRANDS)}</label>
                            <select
                                className="form-select"
                                id="selectBrand"
                                {...register(
                                    'brand',
                                )}
                                onChange={(e): void => setValue('brandString', e.target.selectedOptions[0].label)}
                            >
                                <option value="">{t(SELECT)}</option>
                                {data?.brands ?
                                    (
                                        data.brands.map((brand) => (
                                            <option key={brand} value={brand}>{brand}</option>

                                        ))
                                    ) : null}
                            </select>
                        </div>

                        <div className="row form-check mt-5 mb-4">
                            <div className="col">
                                <label className="form-check-label" htmlFor="defaultCheckbox">
                                    {t(SHOW_ONLY)}
                                </label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheckbox"
                                    {...register(
                                        'checkbox',
                                    )}
                                />
                            </div>
                        </div>
                    </form>
                ) }
            </Offcanvas.Body>
            <div className="filter-footer">
                <button
                    type="submit"
                    className="btn btn-link text-decoration-none"
                    onClick={removeFilters}
                >
                    {t(REMOVE_FILTERS)}
                </button>
                <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="offcanvas"
                    form="filter-form"
                >
                    {t(APPLY_FILTERS)}
                </button>
            </div>

        </Offcanvas>
    );
};
