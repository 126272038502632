import { ICreditMemo } from 'app/orders/model/CreditMemo';
import { CreditMemoRepository } from '../../../../cartSummary/domain/repository/CreditMemoRepository';
import { TencerApiClient } from '../../../../shared/infrastructure/tencer-api/TencerApiClient';
import { ICreditMemoResponse } from '../../../../shared/infrastructure/tencer-api/dto/responses/ICreditMemoResponse';
import { CreditMemoMapper } from '../../../../shared/infrastructure/tencer-api/data-mapper/credit-memo.mapper';

export default class ApiCreditMemoRepository implements CreditMemoRepository {

    constructor(private client: TencerApiClient) {}
    async getAvailableCreditMemos(): Promise<ICreditMemo[]> {
        const apiCreditMemos: ICreditMemoResponse[] = await this.client.getCreditMemos();

        return apiCreditMemos.map((apiCreditMemo: ICreditMemoResponse) => CreditMemoMapper.map(apiCreditMemo));
    }

}
