import { AuthenticationRepository } from '../../../domain/repository/AuthenticationRepository';
import { RequestAccess } from '../../../domain/model/RequestAccess';
import { TencerApiClient } from '../../../../shared/infrastructure/tencer-api/TencerApiClient';
import { ApplicationAccessType } from '../../../domain/value-objects/ApplicationAccessType';
import { Origin } from '../../../../shared/domain/value-objects/Origin';

export class ApiAuthenticationRepository implements AuthenticationRepository {

    constructor(private _apiClient: TencerApiClient) {
    }

    async requestAccess(requestAccess: RequestAccess): Promise<void> {
        const apiRequest = {
            payload: {
                email: requestAccess.email.toString(),
                company: requestAccess.companyName,
                will_order: requestAccess.accessType.equals(ApplicationAccessType.PLACE_ORDERS),
                origin: requestAccess.origin === Origin.EUROPE ? 0 : 1,
            },
        };
        return this._apiClient.postRequestAccess(apiRequest);
    }

}
