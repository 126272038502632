export class Address {

    constructor(
        public readonly line? : string,
        public readonly line2? : string,
        public readonly city? : string,
        public readonly country? : string,
        public readonly state? : string,
        public readonly postalCode? : string,
        public readonly methodCode? : string,
    ) { }

    public toString() : string {
        return `${this.line ?? ''}
        ${this.line2 ?? ''} ${this.city ?? ''} ${this.country ?? ''} ${this.state ?? ''} ${this.postalCode ?? ''}`;
    }

}
