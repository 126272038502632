export class Email {

    private readonly _email: string;

    public constructor(value: string) {
        this.ensureIsNotUndefined(value);
        this._email = value;
    }

    private ensureIsNotUndefined(value: string): void {
        if (value === undefined) {
            throw new Error('Email cannot be undefined ');
        }
    }

    public get email(): string {
        return this._email;
    }

    public toString(): string {
        return this.email;
    }

}
