import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SHOW_ORDER_TRACKING } from 'i18n/translations/TR';
import { CActionButton } from 'app/shared/components/CActionButton/CActionButton';
import { LoadId } from '../../model/value-objects/LoadId';

interface Props {
    loadId: LoadId
}

export const TrackingButton = (props: Props): ReactElement => {
    const { t } = useTranslation();

    const openTracking = (): void => {
        window.open(`https://www.echo.com/shippers/track-a-shipment/?ref={${props.loadId.value}}`);
    };

    return (
        <CActionButton
            text={t(SHOW_ORDER_TRACKING)}
            onClick={openTracking}
        />
    );
};
