import { useState } from 'react';
import { InvoiceFilter } from '../../domain/model/InvoiceFilter';
import { InvoiceFilterCollection } from '../../domain/model/InvoiceFilterCollection';

export interface useOrderFiltersInterface {
    filters: InvoiceFilterCollection,
    applyFilters: (filters: InvoiceFilterCollection) => void,
    addFilter: (filter: InvoiceFilter) => void,
}

export enum InvoiceFilterName {
    START_DATE = 'startDate',
    FINAL_DATE = 'finalDate',
    ORDER_NUMBER = 'orderNumber',
}
export const useInvoiceFilters = (): useOrderFiltersInterface => {
    const defaultFilters = new InvoiceFilterCollection([]);

    const [
        filters,
        setFilters,
    ] = useState<InvoiceFilterCollection>(defaultFilters);

    const applyFilters = (appliedFilters: InvoiceFilterCollection): void => {
        const allFilters = new InvoiceFilterCollection(
            [...defaultFilters.toArray(), ...appliedFilters.toArray()],
        );
        setFilters(allFilters);
    };

    const addFilter = (filter: InvoiceFilter): void => {
        const allFilters = new InvoiceFilterCollection(
            [...filters.toArray(), filter],
        );
        setFilters(allFilters);
    };

    return {
        filters,
        applyFilters,
        addFilter,
    };
};
