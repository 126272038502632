import React, { ReactElement } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RequestAccessFormData, RequestAccessFormDataName } from './RequestAccessFormData';
import {
    ACCOUNT_FIELD_COMPANY_NAME,
    APPLICATION_ACCESS_TYPE, APPLICATION_ACCESS_TYPE_INQUIRE_LABEL, APPLICATION_ACCESS_TYPE_PLACE_ORDERS_LABEL,
    CORREO,
    OBLIGATORY_FIELD_ERROR, PORTAL,
} from '../../../../../../i18n/translations/TR';
import { Origin } from '../../../../../shared/domain/value-objects/Origin';
import { ApplicationAccessType } from '../../../../domain/value-objects/ApplicationAccessType';

interface Props {
    useForm: UseFormReturn<RequestAccessFormData>;
}

export const RequestAccessForm = (props: Props): ReactElement => {

    const { t } = useTranslation();
    const registers = {
        email: props.useForm.register(
            RequestAccessFormDataName.EMAIL,
            {
                required: {
                    value: true,
                    message: t(OBLIGATORY_FIELD_ERROR),
                },
            },
        ),
        companyName: props.useForm.register(
            RequestAccessFormDataName.COMPANY_NAME,
            {
                required: {
                    value: true,
                    message: t(OBLIGATORY_FIELD_ERROR),
                },
            },
        ),
        accessType: props.useForm.register(
            RequestAccessFormDataName.ACCESS_TYPE,
            {
                required: {
                    value: true,
                    message: t(OBLIGATORY_FIELD_ERROR),
                },
            },
        ),
        origin: props.useForm.register(
            RequestAccessFormDataName.ORIGIN,
            {
                required: {
                    value: true,
                    message: t(OBLIGATORY_FIELD_ERROR),
                },
            },
        ),

    };

    const accessTypeOptions = [
        { value: ApplicationAccessType.INQUIRE.value, label: t(APPLICATION_ACCESS_TYPE_INQUIRE_LABEL) },
        { value: ApplicationAccessType.PLACE_ORDERS.value, label: t(APPLICATION_ACCESS_TYPE_PLACE_ORDERS_LABEL) },
    ];

    return (
        <form>
            <div className="form-group">
                <label
                    htmlFor="apply-access-email"
                    className="form-label is-required"
                >
                    {t(CORREO)}
                </label>
                <input
                    id="apply-access-email"
                    {...registers.email}
                    className="form-control"
                />
            </div>
            <div className="form-group">
                <label
                    htmlFor="apply-access-company-name form-label"
                    className="form-label is-required"
                >
                    {t(ACCOUNT_FIELD_COMPANY_NAME)}
                </label>
                <input
                    id="apply-access-company-name"
                    {...registers.companyName}
                    className="form-control"
                />
            </div>

            <div className="form-group">
                <label
                    htmlFor="apply-access-type form-label"
                    className="form-label is-required"
                >
                    {t(APPLICATION_ACCESS_TYPE)}
                </label>
                <select
                    {...registers.accessType}
                    id="apply-access-type"
                    className="select-field form-select"
                >
                    {accessTypeOptions.map((item) => (
                        <option
                            key={item.value}
                            value={item.value}
                            className="select-field-option"
                        >
                            {item.label}
                        </option>

                    ))}
                </select>
            </div>

            <div className="form-group">
                <label
                    htmlFor="apply-access-origin form-label"
                    className="form-label is-required"
                >
                    {t(PORTAL)}
                </label>
                <select
                    {...registers.origin}
                    id="apply-access-origin"
                    className="select-field form-select"
                >
                    {[Origin.USA, Origin.EUROPE].map((item) => (
                        <option
                            key={item}
                            value={item}
                            className="select-field-option"
                        >
                            {item}
                        </option>

                    ))}
                </select>
            </div>

        </form>
    );
};
