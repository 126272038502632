import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CONTINUE, ORDERS, PAYMENT_OK_TEXT, PAYMENT_OK_TITLE } from '../../../../../../i18n/translations/TR';
import { CActionButton } from '../../../../../shared/components/CActionButton/CActionButton';
import { TencerRoutes } from '../../../../../shared/tencer-routing/TencerRoutes';
import { ReactComponent as IconCheck } from '../../../../../../assets/img/icons/ic-check-done.svg';
import { usePaymentOkController } from './usePaymentOkController';
import { PageSpinner } from '../../../../../shared/components/page-spinner/page-spinner';

interface Props {
    koURL: string,
}

export const PaymentOk: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const controller = usePaymentOkController({
        koURL: props.koURL,
    });

    return (
        <>
            <PageSpinner isBusy={controller.checkingOut} />
            {
                controller.checkingOut ? null : (
                    <div className="payment-result payment-result-ok">
                        <div className="header">
                            <div className="title">
                                <h1 className="section-title">
                                    <span className="icon"><IconCheck /></span>
                                    <span className="text">{t(PAYMENT_OK_TITLE)}</span>
                                </h1>
                            </div>
                        </div>
                        <div className="body">
                            <p>{t(PAYMENT_OK_TEXT)}</p>
                            <div className="actions">
                                <CActionButton
                                    text={t(CONTINUE)}
                                    onClick={
                                        (): void => { navigate(TencerRoutes.CATALOG); }
                                    }
                                />
                                <CActionButton
                                    text={t(ORDERS)}
                                    onClick={
                                        (): void => { navigate(TencerRoutes.ORDERS); }
                                    }
                                    className="btn-primary-outline"
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};
