import { ProductFilter } from 'app/orders/model/ProductFilter';
import { ProductFilterCollection } from 'app/orders/model/ProductFilterCollection';
import { ProductFilterRepository } from 'app/products/domain/repository/ProductFilterRepository';

export class LocalProductFilterRepository implements ProductFilterRepository {

    findAll(): Promise<ProductFilterCollection> {
        const json = localStorage.getItem(this._storageKey);
        const filters = json ? JSON.parse(json) : {};
        const collection = new ProductFilterCollection([]);

        Object.keys(filters).forEach((key: string) => {
            const filter = new ProductFilter(key, filters[key].value);
            collection.addFilter(filter);
        });

        return Promise.resolve(collection);
    }

    private _storageKey = 'product-filters';
    add(filter: ProductFilter): Promise<void> {
        const json = localStorage.getItem(this._storageKey);
        const filters = json ? JSON.parse(json) : {};
        filters[filter.name] = { name: filter.name, value: filter.value };
        localStorage.setItem(this._storageKey, JSON.stringify(filters));

        return Promise.resolve();
    }

    find(key: string): Promise<ProductFilter> {
        const json = localStorage.getItem(this._storageKey);
        const filters = json ? JSON.parse(json) : {};
        return Promise.resolve(filters[key]);
    }

    async remove(key: string): Promise<void> {
        const filters = await this.findAll();
        delete filters.filters[key];

        localStorage.setItem(this._storageKey, JSON.stringify(filters.filters));

        return Promise.resolve();
    }

    reset(): Promise<void> {
        localStorage.removeItem(this._storageKey);
        return Promise.resolve();
    }

}
