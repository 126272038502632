import { Courier } from 'app/cartSummary/domain/entity/Courier';
import { CourierId } from '../../../../../shared/domain/value-objects/CourierId';
import { CourierResponse } from '../dto/response/CourierResponse';
import { Price } from '../../../../../orders/model/value-objects/Price';

export class CourierMapper {

    public static fromArrayResponse(response: CourierResponse[]): Courier[] {
        return response.map((courierResponse) => {
            return this.fromResponse(courierResponse);
        });

    }

    public static fromResponse(courierResponse: CourierResponse): Courier {
        return new Courier(
            new CourierId(courierResponse.code),
            courierResponse.name,
            courierResponse.price ? new Price(courierResponse.price) : undefined,
        );
    }

}
