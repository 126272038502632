import { Address } from 'app/account/domain/value-objects/address';
import { PostalCode } from 'app/account/domain/value-objects/postal-code';
import { Country } from './country';
import { ShippingAddressId } from './ShippingAddressId';
import { ProvinceId } from './value-objects/ProvinceId';
import { Name } from '../../shared/domain/value-objects/name';
import { Phone } from '../../account/domain/value-objects/phone';

export class ShippingAddress {

    private _code: ShippingAddressId;
    public get code(): ShippingAddressId {
        return this._code;
    }

    private _description: string;
    public get description(): string {
        return this._description;
    }

    private _country: Country | null;
    public get country(): Country | null {
        return this._country;
    }

    private _provinceId: ProvinceId;
    public get provinceId(): ProvinceId {
        return this._provinceId;
    }

    private _postalCode: PostalCode;
    public get postalCode(): PostalCode {
        return this._postalCode;
    }

    private _locality: string;
    public get locality(): string {
        return this._locality;
    }

    private _address: Address;
    public get address(): Address {
        return this._address;
    }

    private _address1: Address;
    public get address1(): Address {
        return this._address1;
    }

    private _address2: Address;
    public get address2(): Address {
        return this._address2;
    }

    protected _portal: boolean;
    public get portal(): boolean {
        return this._portal;
    }

    private _contact: Name | null;
    public get contact(): Name | null {
        return this._contact;
    }

    private _phone: Phone | null;
    public get phone(): Phone | null {
        return this._phone;
    }

    protected _fax: string | undefined;
    public get fax(): string | undefined {
        return this._fax;
    }

    public buildAddressInfo(): string {

        const phone = this.phone;
        const contact = this.contact;
        const countryName = this.country?.name || '';
        const address1Info = this.address1.value ? `${this.address1.value},` : '';
        const address2Info = this.address2.value ? `${this.address2.value},` : '';
        const countryRegion = this.country?.region ? this.country.region.trim() : '';

        return `
        ${this.description},
        ${this.address.value},
        ${address1Info}
        ${address2Info}
        ${this.postalCode.value}
        ${this.locality}
        ${countryRegion ? `(${countryRegion})` : ''}
        ${countryName}
        ${contact && phone ? `/ ${contact.value}: ${phone.value}` : ''}
    `;
    }

    constructor(
        code: ShippingAddressId,
        description: string,
        country: Country | null,
        provinceId: ProvinceId,
        postalCode: PostalCode,
        locality: string,
        address: Address,
        address1: Address,
        address2: Address,
        portal: boolean,
        phone: Phone | null,
        contact: Name | null,
        fax?: string,
    ) {
        this._code = code;
        this._description = description;
        this._country = country;
        this._provinceId = provinceId;
        this._postalCode = postalCode;
        this._locality = locality;
        this._address = address;
        this._address1 = address1;
        this._address2 = address2;
        this._portal = portal;
        this._phone = phone;
        this._contact = contact;
        this._fax = fax;
    }

}
