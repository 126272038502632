import { TransportType } from './value-objects/transport-type';
import { Price } from './value-objects/Price';
import { LoadId } from './value-objects/LoadId';
import { Courier } from '../../cartSummary/domain/entity/Courier';
import { CourierService } from '../../shared/domain/CourierService';

export default class Transport {

    private _type: TransportType;
    private _isConsolidated: boolean;
    private _comments: string;
    private _courier: Courier | undefined;
    private _courierService: CourierService | undefined;
    private _liftGate: boolean | undefined;
    private _loadId: LoadId | undefined;
    private _pickHandlePrice: Price | undefined;

    constructor(
        type: TransportType,
        isConsolidated: boolean,
        comments: string,
        courier: Courier | undefined,
        courierService: CourierService | undefined,
        liftGate: boolean | undefined,
        loadId: LoadId | undefined,
        pickHandlePrice: Price | undefined,
    ) {
        this._type = type;
        this._isConsolidated = isConsolidated;
        this._comments = comments;
        this._courier = courier;
        this._courierService = courierService;
        this._liftGate = liftGate;
        this._loadId = loadId;
        this._pickHandlePrice = pickHandlePrice;
    }

    public get type(): TransportType {
        return this._type;
    }

    public get isConsolidated(): boolean {
        return this._isConsolidated;
    }

    public set isConsolidated(value: boolean) {
        this._isConsolidated = value;
    }

    public get comments(): string | null {
        return this._comments;
    }

    public get courier(): Courier | undefined {
        return this._courier;
    }

    public set courier(value: Courier | undefined) {
        this._courier = value;
    }

    public get courierService(): CourierService | undefined {
        return this._courierService;
    }

    public set courierService(value: CourierService | undefined) {
        this._courierService = value;
    }

    public get liftGate(): boolean | undefined {
        return this._liftGate;
    }

    public get loadId(): LoadId | undefined {
        return this._loadId;
    }

    public get pickHandlePrice(): Price | undefined {
        return this._pickHandlePrice;
    }

    public set pickHandlePrice(value: Price | undefined) {
        this._pickHandlePrice = value;
    }

}
