import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { SHIPPING_ADDRESS_LABEL_PHONE } from '../../../../../../i18n/translations/TR';

interface ShippingAddressPhoneFieldProps {
    phoneRegister: UseFormRegisterReturn
    errorField: FieldError | undefined
}

export const ShippingAddressPhoneField = (
    {
        phoneRegister,
        errorField,
    }: ShippingAddressPhoneFieldProps,
): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="form-field phone-field">
            <label
                htmlFor="phone"
                className="form-label is-required"
            >
                {t(SHIPPING_ADDRESS_LABEL_PHONE)}
            </label>
            <input
                id="phone"
                type="text"
                {...phoneRegister}
                className="form-control"
            />
            {errorField ? (
                <div className="error-input-form">
                    {errorField.message}
                </div>
            ) : null}
        </div>
    );
};
