import { InvoiceResponse } from '../response/InvoiceResponse';
import { Invoice } from '../../../../domain/model/Invoice';
import { InvoiceNumber } from '../../../../domain/vo/InvoiceNumber';
import { InvoiceLineMapper } from './InvoiceLineMapper';
import { InvoiceLineResponse } from '../response/InvoiceLineResponse';
import { Price } from '../../../../../orders/model/value-objects/Price';
import { Discount } from '../../../../../orders/model/value-objects/Discount';
import { Address } from '../../../../domain/vo/Address';
import { OrderId } from '../../../../../shared/domain/value-objects/OrderId';
import { CreditMemoMapper } from '../../../../../shared/infrastructure/tencer-api/data-mapper/credit-memo.mapper';

export function InvoiceMapper(invoice: InvoiceResponse): Invoice {

    if (!invoice.order_number) {
        throw new Error('Invoice must have an order number');
    }

    const orderId = new OrderId(invoice.order_number);
    return new Invoice(
        new InvoiceNumber(invoice.id),
        new InvoiceNumber(invoice.number),
        invoice.customer_number,
        orderId,
        invoice.discount_amount ? new Discount(invoice.discount_amount) : Discount.zero(),
        new Date(invoice.invoice_date),
        invoice.lines ? invoice.lines.map((line: InvoiceLineResponse) => InvoiceLineMapper(line)) : [],
        invoice.total_amount_excluding_tax ? new Price(invoice.total_amount_excluding_tax) : Price.zero(),
        invoice.total_amount_including_tax ? new Price(invoice.total_amount_including_tax) : Price.zero(),
        new Address(
            invoice.shipping_address.line1,
            invoice.shipping_address.line2,
            invoice.shipping_address.city,
            invoice.shipping_address.country,
            invoice.shipping_address.state,
            invoice.shipping_address.postal_code,
            invoice.shipping_address.method_code,
        ),
        invoice.lift_gate_price ? new Price(invoice.lift_gate_price) : Price.zero(),
        invoice.freight_price ? new Price(invoice.freight_price) : Price.zero(),
        invoice.credit_memo ? CreditMemoMapper.map(invoice.credit_memo) : undefined,
    );

}
