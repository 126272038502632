import { ErpUserId } from 'app/account/domain/value-objects/erp-user-id';
import { PostShippingAddressRequest } from 'app/account/infrastructure/api/dto/request/post-shipping-address-request.DTO';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { useMutation, UseMutationResult } from 'react-query';
import { ApiShippingAddressRepository } from '../../infrastructure/repository/api/ApiShippingAddressRepository';
import { CountryByProvince } from '../../model/countryByProvince';
import { ShippingAddressId } from '../../model/ShippingAddressId';
import { ShippingAddressDefault } from '../../model/ShippingAddressDefault';
import { UserCredentialId } from '../../../shared/domain/value-objects/UserCredentialId';
import { ShippingAddress } from '../../model/ShippingAddress';

interface getDefaultShippingAddressRequest {
    userCredentialId: UserCredentialId
}

interface getShippingAddressRequest {
    shippingAddressId: ShippingAddressId
}

interface putDefaultShippingAddressRequest {
    erpCode: string,
    userCredentialId: UserCredentialId,
    shippingAddressId: ShippingAddressId,
}

interface putShippingAddressRequest extends PostShippingAddressRequest{
    shippingAddressId: ShippingAddressId,
}

interface getShippingAddressesRequest {
    erpCode: ErpUserId
    limit?: number
    description?: string
    excludeDefault?: boolean
}

interface deleteShippingAddressRequest {
    shippingAddressId: ShippingAddressId,
}

export interface ShippingAddressRepositoryInterface {
    getUserCountriesMutation: UseMutationResult<CountryByProvince[], unknown, void, unknown>,
    putShippingAddressMutation: UseMutationResult<ShippingAddress, unknown, putShippingAddressRequest, unknown>,
    postShippingAddressMutation: UseMutationResult<ShippingAddress, unknown, PostShippingAddressRequest, unknown>,
    deleteShippingAddressMutation: UseMutationResult<void, unknown, deleteShippingAddressRequest, unknown>,
    getShippingAddressMutation: UseMutationResult<ShippingAddress, unknown, getShippingAddressRequest, unknown>,
    putDefaultShippingAddressMutation: UseMutationResult<void, unknown, putDefaultShippingAddressRequest, unknown>,
    getDefaultShippingAddressMutation:
    UseMutationResult<ShippingAddressDefault, unknown, getDefaultShippingAddressRequest, unknown>,
    getShippingAddressesMutation: UseMutationResult<ShippingAddress[], unknown, getShippingAddressesRequest, unknown>,
}

export function useShippingAddressRepository(): ShippingAddressRepositoryInterface {
    const client = TencerApiClientSingleton.getInstance();
    const repository = new ApiShippingAddressRepository(client);

    const getDefaultShippingAddressMutation = useMutation(async (
        request: getDefaultShippingAddressRequest,
    ) => repository.getDefaultShippingAddress(request.userCredentialId.value));

    const getShippingAddressMutation = useMutation(async (
        request: getShippingAddressRequest,
    ) => repository.getShippingAddress(request.shippingAddressId));

    const putDefaultShippingAddressMutation = useMutation(async (
        request: putDefaultShippingAddressRequest,
    ) => repository.putDefaultShippingAddress(
        request.erpCode,
        request.userCredentialId.value,
        request.shippingAddressId,
    ));

    const putShippingAddressMutation = useMutation(async (
        request: putShippingAddressRequest,
    ) => repository.putShippingAddress(
        request.shippingAddressId,
        {
            country_id: request.country_id,
            province_id: request.province_id,
            postal_code: request.postal_code,
            locality: request.locality,
            address: request.address,
            address1: request.address1,
            address2: request.address2,
            phone: request.phone,
            fax: request.fax,
            description: request.description,
            contact: request.contact,
        },
    ));

    const deleteShippingAddressMutation = useMutation(async (
        request: deleteShippingAddressRequest,
    ) => repository.deleteShippingAddress(request.shippingAddressId));

    const getShippingAddressesMutation = useMutation(async (
        request: getShippingAddressesRequest,
    ) => repository.getShippingAddresses(request.erpCode, request.limit, request.description, request.excludeDefault));

    const getUserCountriesMutation = useMutation(async () => repository.getUserCountries());

    const postShippingAddressMutation = useMutation(async (
        request: PostShippingAddressRequest,
    ) => repository.postShippingAddress(request));

    return {
        getUserCountriesMutation,
        getShippingAddressMutation,
        putShippingAddressMutation,
        postShippingAddressMutation,
        getShippingAddressesMutation,
        deleteShippingAddressMutation,
        putDefaultShippingAddressMutation,
        getDefaultShippingAddressMutation,
    };
}
