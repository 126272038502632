import { CourierId } from '../../../shared/domain/value-objects/CourierId';
import { Price } from '../../../orders/model/value-objects/Price';

export class Courier {

    private _id: CourierId;
    private _name: string;
    private _price: Price | undefined;

    public constructor(
        id: CourierId,
        name: string,
        price: Price | undefined,
    ) {
        this._id = id;
        this._name = name;
        this._price = price;
    }

    get id(): CourierId {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get price(): Price | undefined {
        return this._price;
    }

}
