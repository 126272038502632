import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import {
    ORDER_SHIPPING_ADDRESS,
    SELECT_DIFFERENT_ADDRESS,
    YOU_MUST_SELECT_A_SHIPPING_ADDRESS,
} from '../../../../../../../../i18n/translations/TR';
import { ErpUserId } from '../../../../../../../account/domain/value-objects/erp-user-id';
import { UserCredentialId } from '../../../../../../../shared/domain/value-objects/UserCredentialId';
import { ShippingAddress } from '../../../../../../../orders/model/ShippingAddress';
import { CDeliveryAddress } from '../../../../../../../cart/components/CDeliveryAddress';
import { AccountShippingAddressCard } from '../../../../../../../account/application/screen/container/view/account-shipping-address-card/account-shipping-address-card';
import { useShippingAddressRepository } from '../../../../../../../orders/application/hooks/useShippingAddressRepository';
import { CountryByProvince } from '../../../../../../../orders/model/countryByProvince';
import { ShippingAddressDefault } from '../../../../../../../orders/model/ShippingAddressDefault';

interface IOrderShippingAddressSelectorModal {
    erpUserId: ErpUserId;
    countries: CountryByProvince[];
    userCredentialId: UserCredentialId;
    afterChangeShippingAddressDefault: (shippingAddress: ShippingAddress) => void;
}

// TODO (Álvaro) refactor and split into two components
export const OrderShippingAddressSelectorModal = (props: IOrderShippingAddressSelectorModal): React.ReactElement => {
    const { t } = useTranslation();
    const shippingAddressRepository = useShippingAddressRepository();
    const [showShippingAddressSelector, setShowShippingAddressSelector] = React.useState<boolean>(false);
    const [shippingAddressDefault, setShippingAddressDefault] = React.useState<ShippingAddressDefault>();
    const [shippingAddresses, setShippingAddresses] = useState<ShippingAddress[]>([]);
    const [isChangingDefaultShippingAddress, setIsChangingDefaultShippingAddress] = useState<boolean>(false);
    const handleIsChangingDefaultShippingAddress = (state: boolean): void => {
        setIsChangingDefaultShippingAddress(state);
    };

    const toggleAddressSelector = (): void => {
        setShowShippingAddressSelector(!showShippingAddressSelector);
    };

    const handleHideShippingAddressSelector = (): void => {
        setShowShippingAddressSelector(false);
    };

    const getDefaultShippingAddress = (): void => {
        shippingAddressRepository.getDefaultShippingAddressMutation.mutate(
            {
                userCredentialId: props.userCredentialId,
            },
            {
                onSuccess: (response) => {
                    setShippingAddressDefault(response);
                },
            },
        );
    };

    const getShippingAdresses = (): void => {
        shippingAddressRepository.getShippingAddressesMutation.mutate(
            {
                erpCode: props.erpUserId,
                limit: 10000,
                excludeDefault: false,
            },
            {
                onSuccess: (response) => {
                    setShippingAddresses(response);
                },
            },
        );
    };

    useEffect((): void => {
        getShippingAdresses();
    }, []);

    const getShippingAddressesIsLoading = shippingAddressRepository.getShippingAddressesMutation.isLoading;

    const renderShippingAddressSelectorModal = (): React.ReactElement => {
        return (
            <Modal
                onHide={(): void => {
                    if (!isChangingDefaultShippingAddress) {
                        setShowShippingAddressSelector(false);
                    }
                }}
                show={showShippingAddressSelector}
                className="modal fade"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="xl"
            >
                <Modal.Header
                    closeButton
                    onClick={(): void => {
                        if (!isChangingDefaultShippingAddress) {
                            setShowShippingAddressSelector(false);
                        }
                    }}
                >
                    <h5 className="modal-title">{t(SELECT_DIFFERENT_ADDRESS)}</h5>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {!props.userCredentialId ? null : (
                        <AccountShippingAddressCard
                            shippingAddresses={shippingAddresses}
                            erpUserId={props.erpUserId}
                            userCredentialId={props.userCredentialId}
                            onSelect={(shippingAddress: ShippingAddress): void => {
                                getDefaultShippingAddress();
                                props.afterChangeShippingAddressDefault(shippingAddress);
                            }}
                            handleHideShippingAddressSelector={handleHideShippingAddressSelector}
                            defaultShippingAddress={shippingAddressDefault}
                            getShippingAddressesIsLoading={getShippingAddressesIsLoading}
                            getShippingAddresses={getShippingAdresses}
                            getDefaultShippingAddress={getDefaultShippingAddress}
                            countries={props.countries}
                            handleIsChangingDefaultShippingAddress={handleIsChangingDefaultShippingAddress}
                            defaultAddress={shippingAddressDefault}
                        />
                    )}
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <>
            <span className="cart-content-subtitle">
                {t(ORDER_SHIPPING_ADDRESS)}
            </span>
            {shippingAddressDefault ? (
                <CDeliveryAddress address={shippingAddressDefault} />
            ) : (
                <div className="info-message">
                    {t(YOU_MUST_SELECT_A_SHIPPING_ADDRESS)}
                </div>
            )}
            <button
                type="button"
                className="btn-link bg-transparent text-decoration-none shipping-address-edit-btn"
                onClick={toggleAddressSelector}
            >
                {t(SELECT_DIFFERENT_ADDRESS)}
            </button>

            {renderShippingAddressSelectorModal()}
        </>
    );
};
