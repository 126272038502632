import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    INVOICES_TABLE_COLUMN_AMOUNT_BRUTE,
    INVOICES_TABLE_COLUMN_AMOUNT_NET,
    INVOICES_TABLE_COLUMN_DATE,
    INVOICES_TABLE_COLUMN_DISCOUNT,
    INVOICES_TABLE_COLUMN_ORDER,
} from '../../../../../i18n/translations/TR';

export const InvoicesTableHead = (): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <thead>
            <tr>
                <th>{t(INVOICES_TABLE_COLUMN_ORDER)}</th>
                <th>{t(INVOICES_TABLE_COLUMN_DATE)}</th>
                <th>{t(INVOICES_TABLE_COLUMN_AMOUNT_BRUTE)}</th>
                <th>{t(INVOICES_TABLE_COLUMN_DISCOUNT)}</th>
                <th>{t(INVOICES_TABLE_COLUMN_AMOUNT_NET)}</th>
                <th>{}</th>
            </tr>
        </thead>
    );
};
