import { InvoiceLineResponse } from '../response/InvoiceLineResponse';
import { InvoiceLine } from '../../../../domain/model/InvoiceLine';
import { InvoiceLineId } from '../../../../domain/vo/InvoiceLineId';
import ProductQuantity from '../../../../../orders/model/value-objects/ProductQuantity';
import { Price } from '../../../../../orders/model/value-objects/Price';
import { InvoiceLineType } from '../../../../domain/vo/InvoiceLineType';

export function InvoiceLineMapper(line: InvoiceLineResponse): InvoiceLine {
    return new InvoiceLine(
        new InvoiceLineId(line.id),
        line.item_id,
        new InvoiceLineType(line.line_type),
        line.line_object_number,
        line.description,
        new ProductQuantity(line.quantity, { name: line.unit_of_measure_id, value: line.unit_of_measure_id }),
        new Price(line.unit_price),
        new Price(line.discount_amount),
        line.discount_percent,
        line.amount_excluding_tax,
        line.tax_code,
        line.tax_percent,
        new Price(line.net_amount),
        new Date(line.shipment_date),
        line.location_id,
        line.quality_code,
        line.tone,
        line.caliber,
        line.packaging_no,
        new ProductQuantity(
            line.original_quantity,
            {
                name: line.origin_unit_of_measure,
                value: line.origin_unit_of_measure,
            },
        ),
        new ProductQuantity(
            line.actual_quantity,
            {
                name: line.actual_unit_of_measure,
                value: line.actual_unit_of_measure,
            },
        ),
        new Price(line.actual_unit_price),
        new Price(line.actual_line_discount),
    );
}
