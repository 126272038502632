import React from 'react';
import { useTranslation } from 'react-i18next';
import { DISCOUNT } from 'i18n/translations/TR';
import 'react-loading-skeleton/dist/skeleton.css';
import { getRoundedValues } from 'app/shared/getRoundedValues';
import { Currency } from 'app/shared/Currency';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import { Quantity } from 'app/shared/components/Quantity';
import { InvoiceLine } from '../../../domain/model/InvoiceLine';
import { InvoiceLineProductInfo } from './InvoiceLineProductInfo';

interface LineItemProp{
    line:InvoiceLine,
}
export const InvoiceLineItem = (
    {
        line,
    } :LineItemProp,
):React.ReactElement => {
    const { t } = useTranslation();
    const origin = useOrigin();

    const productActionsRender = (
        <div className="detail-price">
            <div className="product-actions">
                <div className="product-price ">
                    {(!line.actualLineDiscount || line.actualLineDiscount.amount <= 0) ? null :
                        (
                            <div className="product-discount-cart order-line-discount-special2">
                                {`${line.actualLineDiscount} ${t(DISCOUNT)}`}
                            </div>
                        )}
                </div>
            </div>
            <div className="product-actions">
                {line.isItem ? (
                    <div className="total-price">
                        <strong>
                            <Currency
                                value={line.actualUnitPrice ? line.actualUnitPrice.amount : 0}
                                className="import"
                            />
                        </strong>
                        {' x '}
                        <Quantity
                            value={line.actualQuantity ? line.actualQuantity.amount : 0}
                            label={line.actualQuantity.unitLabel(origin)}
                        />
                    </div>
                ) : null}
                <div>
                    <strong>
                        <Currency
                            value={
                                getRoundedValues(line.netAmount?.amount ? line.netAmount.amount : 0)
                            }
                            className="import"
                        />
                    </strong>
                </div>
            </div>
        </div>
    );

    return (
        <li className="list-item">
            <InvoiceLineProductInfo
                line={line}
            />
            {productActionsRender}
        </li>
    );
};
