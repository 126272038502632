import { FEET2, M2_UNIT, PALLET_UNIT, PALLETS, PIECE_UNIT, PIECE_UNIT_SINGLE } from 'i18n/translations/TR';
import { useTranslation } from 'react-i18next';
import { AmountUnit } from '../../../product-details/value_objects/AmountVO';

export default class ProductQuantity {

    private _amount: number;
    public get amount(): number {
        return this._amount;
    }

    private _unit: AmountUnit;
    public get unit(): AmountUnit {
        return this._unit;
    }

    public constructor(amount: number, unit: AmountUnit) {
        this._amount = amount;
        this._unit = unit;
    }

    public unitLabel = (origin: string|undefined): string => {
        const { t } = useTranslation();
        if (this._unit.name === 'Unit') {
            if (this._amount > 1) {
                return `${t(PIECE_UNIT)}`;
            }
            return `${t(PIECE_UNIT_SINGLE)}`;
        }

        if (this._unit.name === 'SF') {
            return t(FEET2);
        }

        if (this._unit.name === 'PALLET') {
            return this._amount > 1 ? t(PALLETS) : t(PALLET_UNIT);
        }

        if (origin === 'USA') {
            return t(FEET2);
        }
        return t(M2_UNIT);
    };

}
