import React from 'react';
import { InvoiceLine } from '../../domain/model/InvoiceLine';
import { InvoiceLineItem } from '../screens/detail/InvoiceLineItem';

interface LinesListProps{
    lines:InvoiceLine[],
}
export const InvoiceLinesList = (
    {
        lines,
    }:LinesListProps,
):React.ReactElement => {
    return (
        <ul className="section-list">
            {
                (
                    lines.map((line: InvoiceLine) => (
                        <InvoiceLineItem
                            key={line.id.toString()}
                            line={line}
                        />
                    ))
                )
            }
        </ul>
    );
};
