import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import { Courier } from '../../domain/entity/Courier';
import { CourierMapper } from './api/dataMappers/CourierMapper';
import { CourierService } from '../../../shared/domain/CourierService';
import { CourierServiceMapper } from './api/dataMappers/CourierServiceMapper';
import { CourierRepository } from '../../domain/repository/CourierRepository';
import { CourierId } from '../../../shared/domain/value-objects/CourierId';

export class ApiCourierRepository implements CourierRepository {

    private readonly client: TencerApiClient;

    constructor(client: TencerApiClient) {
        this.client = client;
    }

    async findAll(isSmallParcel: boolean): Promise<Courier[]> {
        const response = await this.client.getCouriers({
            'is-small-parcel': isSmallParcel,
        });

        return CourierMapper.fromArrayResponse(response);
    }

    async getCourierServices(courierId: CourierId): Promise<CourierService[]> {
        const response = await this.client.getCourierServices({
            code: courierId.value,
        });

        return CourierServiceMapper.fromArrayResponse(response);
    }

}
