export interface InvoiceFilterValue {}

export class InvoiceFilter {

    private _name: string;
    public get name(): string {
        return this._name;
    }

    private _value: InvoiceFilterValue;
    public get value(): InvoiceFilterValue {
        return this._value;
    }

    constructor(name: string, value: InvoiceFilterValue) {
        this._name = name;
        this._value = value;
    }

}
