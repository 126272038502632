import ProductQuantity from '../../../orders/model/value-objects/ProductQuantity';
import { InvoiceLineId } from '../vo/InvoiceLineId';
import { Price } from '../../../orders/model/value-objects/Price';
import { InvoiceLineType } from '../vo/InvoiceLineType';

export class InvoiceLine {

    constructor(
        protected _id: InvoiceLineId,
        protected _itemId: string,
        protected _lineType: InvoiceLineType,
        protected _lineObjectNumber: string,
        protected _description: string,
        protected _quantity: ProductQuantity,
        protected _unitPrice: Price,
        protected _discountAmount: Price,
        protected _discountPercent: number,
        protected _amountExcludingTax: number,
        protected _taxCode: string,
        protected _taxPercent: number,
        protected _netAmount: Price,
        protected _shipmentDate: Date,
        protected _locationId: string,
        protected _qualityCode: string,
        protected _tone: string,
        protected _caliber: string,
        protected _packagingNo: string,
        protected _originalQuantity: ProductQuantity,
        protected _actualQuantity: ProductQuantity,
        protected _actualUnitPrice: Price,
        protected _actualLineDiscount: Price,
    ) {}

    get id(): InvoiceLineId {
        return this._id;
    }

    get itemId(): string {
        return this._itemId;
    }

    get lineType(): InvoiceLineType {
        return this._lineType;
    }

    get lineObjectNumber(): string {
        return this._lineObjectNumber;
    }

    get description(): string {
        return this._description;
    }

    get quantity(): ProductQuantity {
        return this._quantity;
    }

    get unitPrice(): Price {
        return this._unitPrice;
    }

    get discountAmount(): Price {
        return this._discountAmount;
    }

    get discountPercent(): number {
        return this._discountPercent;
    }

    get amountExcludingTax(): number {
        return this._amountExcludingTax;
    }

    get taxCode(): string {
        return this._taxCode;
    }

    get taxPercent(): number {
        return this._taxPercent;
    }

    get netAmount(): Price {
        return this._netAmount;
    }

    get shipmentDate(): Date {
        return this._shipmentDate;
    }

    get locationId(): string {
        return this._locationId;
    }

    get qualityCode(): string {
        return this._qualityCode;
    }

    get tone(): string {
        return this._tone;
    }

    get caliber(): string {
        return this._caliber;
    }

    get packagingNo(): string {
        return this._packagingNo;
    }

    get originalQuantity(): ProductQuantity {
        return this._originalQuantity;
    }

    get actualQuantity(): ProductQuantity {
        return this._actualQuantity;
    }

    get actualUnitPrice(): Price {
        return this._actualUnitPrice;
    }

    get actualLineDiscount(): Price {
        return this._actualLineDiscount;
    }

    get isItem(): boolean {
        return this._lineType.isItem;
    }

}
