import { ETransportType } from '../../../orders/model/value-objects/transport-type';

export interface TransportFormRegisters {
    courier: string
    comments: string
    liftGate: boolean
    consolidated: boolean
    courierService: string
    responsible: ETransportType
    allowQuoteTransport: boolean
}

export enum TransportFormName {
    COURIER= 'courier',
    COMMENTS= 'comments',
    LIFT_GATE= 'liftGate',
    RESPONSIBLE= 'responsible',
    CONSOLIDATED= 'consolidated',
    COURIER_SERVICE= 'courierService',
    ALLOW_QUOTE_TRANSPORT= 'allowQuoteTransport',
}
