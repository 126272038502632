import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { NO_MORE_RESULTS, OUTLET } from 'i18n/translations/TR';
import { SectionHeaderCatalog } from 'app/shared/SectionHeaderCatalog';
import { Spinner } from 'app/shared/Spinner';
import { PageBanner } from 'app/page-banner/component/PageBanner';
import { ProductsCatalogList } from '../Catalog/components/ProductCatalogList';
import { useProductOutletController } from './useProductOutletController';

interface Props {
}

export const ProductOutletScreen: React.FC<Props> = () => {

    const { t } = useTranslation();
    const controller = useProductOutletController();

    const title = t(OUTLET);

    return (
        <section className="header-section">
            <PageBanner pageId={title} />
            <div className="container">
                <div
                    role="tabpanel"
                    aria-labelledby="pills-catalog-tab"
                >
                    {!controller.isLoading ?
                        (
                            <div>
                                <SectionHeaderCatalog
                                    title={title}
                                />

                                <InfiniteScroll
                                    dataLength={controller.products.length}
                                    next={controller.fetchMore}
                                    hasMore={!controller.hasMore ? false : controller.hasMore}
                                    loader={<Spinner classCss="basic-spinner-container" />}
                                    endMessage={<p className="end-message">{t(NO_MORE_RESULTS)}</p>}
                                >
                                    <ProductsCatalogList productsCatalog={controller.products} />

                                </InfiniteScroll>
                            </div>
                        ) : <Spinner classCss="header-section-spinner" />}
                </div>
            </div>
        </section>
    );
};

ProductOutletScreen.defaultProps = {};
