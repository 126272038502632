import { InvoiceFilter } from '../../../../../invoices/domain/model/InvoiceFilter';
import {
    InvoiceFilterRequest,
    InvoiceFilterRequestItem,
    InvoiceFilterRequestName,
} from '../requests/InvoiceFilterRequest';
import { InvoiceFilterName } from '../../../../../invoices/application/hooks/useInvoiceFilters';
import { InvoiceFilterCollection } from '../../../../../invoices/domain/model/InvoiceFilterCollection';

export class InvoiceFiltersMapper {

    private static fromApplication(filter: InvoiceFilter): InvoiceFilterRequestItem {
        switch (filter.name) {
            case InvoiceFilterName.FINAL_DATE:
                return {
                    name: InvoiceFilterRequestName.FINAL_DATE,
                    value: filter.value as string,
                };
            case InvoiceFilterName.START_DATE:
                return {
                    name: InvoiceFilterRequestName.START_DATE,
                    value: filter.value as string,
                };
            case InvoiceFilterName.ORDER_NUMBER:
                return {
                    name: InvoiceFilterRequestName.ORDER_NUMBER,
                    value: filter.value as string,
                };
            default: throw new Error('Invalid filter name');
        }
    }

    static toWs(filters: InvoiceFilterCollection): InvoiceFilterRequest {
        const filtersDTO: InvoiceFilterRequest = {
            request: [],
        };

        filters.toArray().forEach((item) => {
            filtersDTO.request.push(this.fromApplication(item));
        });

        return filtersDTO;
    }

}
