import { InvoiceNumber } from '../vo/InvoiceNumber';
import { InvoiceLine } from './InvoiceLine';
import { Price } from '../../../orders/model/value-objects/Price';
import { Discount } from '../../../orders/model/value-objects/Discount';
import { Address } from '../vo/Address';
import { OrderId } from '../../../shared/domain/value-objects/OrderId';
import { InvoiceId } from '../vo/InvoiceId';
import { ICreditMemo } from '../../../orders/model/CreditMemo';

export class Invoice {

    constructor(
        protected _id: InvoiceId,
        protected _number: InvoiceNumber,
        protected _customerId: string,
        protected _orderNumber: OrderId,
        protected _discount: Discount,
        protected _date: Date,
        protected _lines: InvoiceLine[],
        protected _bruteAmount: Price,
        protected _netAmount: Price,
        protected _shippingAddress: Address,
        protected _liftGatePrice: Price,
        protected _freightPrice: Price,
        protected _creditMemo?: ICreditMemo,
    ) {
    }

    get id(): InvoiceId {
        return this._id;
    }

    get number(): InvoiceNumber {
        return this._number;
    }

    get date(): Date {
        return this._date;
    }

    get lines(): InvoiceLine[] {
        return this._lines;
    }

    get bruteAmount(): Price {
        return this._bruteAmount;
    }

    get netAmount(): Price {
        return this._netAmount;
    }

    get customerId(): string {
        return this._customerId;
    }

    get orderNumber(): OrderId {
        return this._orderNumber;
    }

    get discount(): Discount {
        return this._discount;
    }

    get shippingAddress(): Address {
        return this._shippingAddress;
    }

    get liftGatePrice(): Price {
        return this._liftGatePrice;
    }

    get freightPrice(): Price {
        return this._freightPrice;
    }

    public get creditMemo(): ICreditMemo | undefined {
        return this._creditMemo;
    }

}
