import { Cif } from './value-objects/cif';
import { ErpUserId } from './value-objects/erp-user-id';
import { Contact } from './contact';
import { ErpUserName } from './value-objects/erp-user-name';
import { Weight } from '../../orders/model/value-objects/Weight';

export class ErpUser {

    private readonly _id: ErpUserId;
    private readonly _name: ErpUserName;
    private readonly _cif: Cif;
    private readonly _contact: Contact;
    private readonly _allowQuoteTransport: boolean;
    private readonly _containerMaxWeight: Weight;
    private readonly _pickHandle: boolean;

    public constructor(
        id:ErpUserId,
        name:ErpUserName,
        cif:Cif,
        contact: Contact,
        allowQuoteTransport: boolean,
        containerMaxWeight: Weight,
        pickHandle: boolean,
    ) {
        this._id = id;
        this._name = name;
        this._cif = cif;
        this._contact = contact;
        this._allowQuoteTransport = allowQuoteTransport;
        this._containerMaxWeight = containerMaxWeight;
        this._pickHandle = pickHandle;
    }

    get id(): ErpUserId {
        return this._id;
    }

    get name(): ErpUserName {
        return this._name;
    }

    get cif(): Cif {
        return this._cif;
    }

    get Contact(): Contact {
        return this._contact;
    }

    get allowQuoteTransport(): boolean {
        return this._allowQuoteTransport;
    }

    get ContainerMaxWeight(): Weight {
        return this._containerMaxWeight;
    }

    get pickHandle(): boolean {
        return this._pickHandle;
    }

}
