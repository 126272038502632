export class Cif {

    private readonly _value: string;

    public constructor(value:string) {
        this.ensureIsNotUndefined(value);
        this._value = value;

    }

    private ensureIsNotUndefined(value: string): void {
        if (value === undefined) {
            throw new Error('Cif cannot be undefined ');
        }
    }

    public get value(): string {
        return this._value;
    }

}
