import React from 'react';
import { InvoiceTableItem } from './InvoiceTableItem';
import { Invoice } from '../../../domain/model/Invoice';
import { InvoiceNumber } from '../../../domain/vo/InvoiceNumber';

interface Props {
    invoices: Invoice[],
    goToDetails: (id: InvoiceNumber) => void,
}
export const InvoicesTableBody = (props: Props): React.ReactElement => {
    return (
        <tbody>
            {props.invoices.map((invoice: Invoice) => (
                <InvoiceTableItem
                    invoice={invoice}
                    key={invoice.id.toString()}
                    goToDetails={props.goToDetails}
                />
            ))}
        </tbody>
    );
};
