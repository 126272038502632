import { ETransportType, TransportType } from '../../../../orders/model/value-objects/transport-type';
import { ETransportTypeDTO } from '../dto/responses/order-shipment-response';

export const TransportTypeMapper = {
    mapToRequest: (transportType: TransportType): ETransportTypeDTO => {
        switch (transportType.value) {
            case ETransportType.WILL_CALL:
                return ETransportTypeDTO.WILL_CALL;
            case ETransportType.OWN_LOGISTIC:
                return ETransportTypeDTO.OWN_LOGISTIC;
            case ETransportType.SMALL_PARCEL:
                return ETransportTypeDTO.SMALL_PARCEL;
            case ETransportType.WOW:
                return ETransportTypeDTO.WOW;
            default:
                throw new Error(`Invalid transport type ${transportType}`);
        }
    },
};
