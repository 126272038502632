import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../../shared/Spinner';
import { useInvoiceDetailScreenController } from './useInvoiceDetailScreenController';
import { InvoiceSummary } from './summary/InvoiceSummary';
import { InvoiceLinesList } from '../../components/InvoiceLinesList';
import { BACK, INVOICE_DETAIL_TITILE } from '../../../../../i18n/translations/TR';

export const InvoiceDetailScreen = (): React.ReactElement => {
    const controller = useInvoiceDetailScreenController();

    const loaderElement = (<Spinner classCss="spinner-infinite_scroll text-center" />);

    const { t } = useTranslation();

    return (
        <div className="screen">
            <div className="screen-invoice-detail">
                <section className="invoice-detail">
                    <div className="container">
                        {controller.invoice === undefined ? (
                            loaderElement
                        ) : (
                            <>
                                <div className="section-header row">
                                    <h4 className="section-title row ">
                                        <span className="col-lg-12">
                                            {t(INVOICE_DETAIL_TITILE, { invoiceNumber: controller.invoice?.number })}
                                        </span>
                                    </h4>
                                </div>
                                <InvoiceSummary
                                    invoice={controller.invoice}
                                />
                                <InvoiceLinesList
                                    lines={controller.invoice.lines}
                                />
                            </>
                        )}
                        <div className="footer-actions">
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={(): void => controller.goBack()}
                            >
                                {t(BACK)}
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};
