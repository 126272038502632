import { useEffect, useState } from 'react';
import { useIsMounted } from '../../shared/hooks/useIsMounted';
import { TencerApiClientSingleton } from '../../shared/hooks/TencerApiClientSingleton';
import { ApiCollection } from '../infrastructure/api/response/ApiCollection';
import { ApiProduct } from '../infrastructure/api/response/ApiProduct';
import { ApiProductCatalog } from '../../product-catalog-collections/Catalog/shared/domain/ApiProductCatalog';

interface useHomeScreenControllerResponse {
    outletProducts: ApiProduct[]
    collections: ApiCollection[]
    isGettingCollections: boolean
    errorGettingCollections: boolean
    isGettingOutletProducts: boolean
    errorGettingOutletProducts: boolean
    isGettingBestSellersProducts: boolean
    errorGettingBestSellersProducts: boolean
    bestSellersProducts: ApiProductCatalog[]
}

export const useHomeScreenController = (): useHomeScreenControllerResponse => {
    const [collections, setCollections] = useState<ApiCollection[]>([]);
    const [isGettingCollections, setIsGettingCollections] = useState<boolean>(true);
    const [errorGettingCollections, setErrorGettingCollections] = useState<boolean>(false);

    const [outletProducts, setOutletProducts] = useState<ApiProduct[]>([]);
    const [isGettingOutletProducts, setIsGettingOutletProducts] = useState<boolean>(true);
    const [errorGettingOutletProducts, setErrorGettingOutletProducts] = useState<boolean>(false);

    const [isGettingBestSellersProducts, setIsGettingBestSellersProducts] = useState<boolean>(true);
    const [errorGettingBestSellersProducts, setErrorGettingBestSellersProducts] = useState<boolean>(false);
    const [bestSellersProducts, setBestSellersProducts] = useState<ApiProductCatalog[]>([]);
    const isMounted = useIsMounted();

    // TODO: (Álvaro) use repositories and domain instead of Api directly
    const getCollections = async (): Promise<void> => {
        await TencerApiClientSingleton.getInstance().getCollections()
            .then((apiCollections) => {
                if (!isMounted) {
                    return;
                }
                setCollections(apiCollections);
            })
            .catch(() => {
                setErrorGettingCollections(true);
            })
            .finally(() => setIsGettingCollections(false));
    };

    const getOutletProducts = async (): Promise<void> => {
        await TencerApiClientSingleton.getInstance()
            .getOutletCatalog(5)
            .then((products) => setOutletProducts(products))
            .catch(() => {
                setErrorGettingOutletProducts(true);
            })
            .finally(() => setIsGettingOutletProducts(false));
    };

    const getBestSellersProducts = async (): Promise<void> => {
        await TencerApiClientSingleton.getInstance()
            .getTrendingProducts(5, 1)
            .then((products) => setBestSellersProducts(products))
            .catch(() => {
                setErrorGettingBestSellersProducts(true);
            })
            .finally(() => setIsGettingBestSellersProducts(false));
    };

    useEffect(() => {
        getCollections();
        getOutletProducts();
        getBestSellersProducts();
    }, []);

    return {
        collections,
        outletProducts,
        bestSellersProducts,
        isGettingCollections,
        isGettingOutletProducts,
        errorGettingCollections,
        errorGettingOutletProducts,
        isGettingBestSellersProducts,
        errorGettingBestSellersProducts,
    };
};
