import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CurrentUserContext } from '../../../../../shared/context/CurrentUserContext';
import { DependencyInjectionContext } from '../../../../../shared/context/DependecyInjectionContext';

interface Response {
    onConfirm(): Promise<void>;
    checkingOut: boolean;

}

interface Props {
    koURL: string;
}

export const usePaymentOkController = (props: Props): Response => {
    const dic = useContext(DependencyInjectionContext);
    const navigate = useNavigate();
    const state = React.useContext(CurrentUserContext);
    const [checkingOut, setCheckingOut] = React.useState(true);

    const onConfirm = async (): Promise<void> => {
        const myCart = await dic.cartRepository.getMyCart();
        if (!myCart) {
            setCheckingOut(false);
            return;
        }

        await dic.cartRepository.checkout();
        state?.setExpirationDate(null);
        state?.setCartItems(undefined);
        setCheckingOut(false);
    };

    React.useEffect(() => {
        onConfirm().catch(() => {
            navigate(props.koURL);
        });
    }, []);

    return {
        onConfirm,
        checkingOut,
    };
};
