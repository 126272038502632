import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import React from 'react';
import { useIsMounted } from 'app/shared/hooks/useIsMounted';
import { ApiFilterFields } from './ApiFilterFields';

interface UseProductList {
    data?: ApiFilterFields;
    error: string;
    loading: boolean;
}

export function useFilterFieldsList(): UseProductList {
    const [data, setData] = React.useState<ApiFilterFields>();
    const [error, setError] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(true);
    const isMounted = useIsMounted();

    React.useEffect(() => {
        TencerApiClientSingleton.getInstance()
            .getFilterValues()
            .then((filters) => { if (!isMounted) { return; } setData(filters); })
            .catch((e) => setError(e))
            .finally(() => { if (!isMounted) { return; } setLoading(false); });
    }, []);

    return {
        data,
        error,
        loading,
    };
}
