import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Email } from 'app/account/domain/value-objects/email';
import {
    LOGIN_REQUEST_ACCESS_CONTACT_ADMIN,
    LOGIN_REQUEST_ACCESS_MODAL_TITLE,
    SEND,
} from '../../../../../i18n/translations/TR';
import { RequestAccessForm } from '../requestAccess/form/RequestAccessForm';
import { RequestAccessFormData } from '../requestAccess/form/RequestAccessFormData';
import { Origin } from '../../../../shared/domain/value-objects/Origin';
import RequestAccessUseCase from '../../../request-access/use-case/RequestAccessUseCase';
import { RequestAccess } from '../../../domain/model/RequestAccess';
import { ApplicationAccessType } from '../../../domain/value-objects/ApplicationAccessType';
import { CActionButton } from '../../../../shared/components/CActionButton/CActionButton';
import './applyAccessModal.scss';
import { DependencyInjectionContext } from '../../../../shared/context/DependecyInjectionContext';

interface Props {
    show: boolean,
    onHide: () => void,
}

export const ApplyAccessModal: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dic = useContext(DependencyInjectionContext);

    const [sending, setSending] = React.useState(false);
    const applicationForm = useForm<RequestAccessFormData>({
        mode: 'onChange',
        defaultValues: {
            companyName: '',
            email: '',
            accessType: ApplicationAccessType.PLACE_ORDERS.toString(),
            origin: '',
        },
    });

    const onSubmit = applicationForm.handleSubmit((data) => {
        setSending(true);
        const requestChange = new RequestAccess(
            new Email(data.email),
            data.companyName,
            new ApplicationAccessType(data.accessType),
            data.origin as Origin,
        );
        RequestAccessUseCase(dic.authenticationRepository, requestChange).then(() => {
            applicationForm.reset();
        }).finally(() => {
            props.onHide();
            setSending(false);
        });
    });

    return (
        <Modal
            show={props.show}
            className="modal fade component-apply-access-modal"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton onHide={props.onHide}>
                <h5 className="modal-title">{t(LOGIN_REQUEST_ACCESS_MODAL_TITLE)}</h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <p>
                    {t(LOGIN_REQUEST_ACCESS_CONTACT_ADMIN)}
                </p>
                <div className="form-wrapper">
                    <RequestAccessForm useForm={applicationForm} />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <CActionButton
                    text={t(SEND)}
                    onClick={():void => { onSubmit(); }}
                    isBusy={sending}
                    disabled={!applicationForm.formState.isValid}
                />
            </Modal.Footer>

        </Modal>
    );
};
