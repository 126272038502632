import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { INVOICES } from '../../../../../i18n/translations/TR';

import { SectionHeaderOrders } from '../../../../shared/SectionHeaderOrders';
import { Spinner } from '../../../../shared/Spinner';
import { useInvoicesScreenController } from './useInvoicesScreenController';
import { InvoicesTable } from '../../components/invoices-table/InvoicesTable';
import { FilterInvoicesModal } from '../../components/filters-modal/FilterInvoicesModal';

export const InvoicesScreen = (): React.ReactElement => {
    const controller = useInvoicesScreenController();
    const { t } = useTranslation();

    const loaderElement = (<Spinner classCss="spinner-infinite_scroll text-center" />);

    return (
        <div className="screen">
            <div className="screen-invoices">
                <section className="invoices">
                    <div className="container">
                        {controller.invoices.length === 0 && controller.isLoading ? (
                            loaderElement
                        ) : (
                            <InfiniteScroll
                                dataLength={controller.invoices.length}
                                next={controller.fetch}
                                hasMore={controller.paginator.hasMore}
                                loader={loaderElement}
                                endMessage={<p className="end-message">{t('NO_MORE_RESULTS')}</p>}
                            >
                                <SectionHeaderOrders
                                    title={t(INVOICES)}
                                    handleShow={controller.handleShowFilters}
                                />
                                <div className="filter-tag-container">
                                    <InvoicesTable
                                        invoices={controller.invoices}
                                        goToDetails={controller.goToDetails}
                                    />
                                </div>
                            </InfiniteScroll>
                        )}
                    </div>
                </section>
                <FilterInvoicesModal
                    show={controller.showFilters}
                    onHide={controller.handleCloseFilters}
                    onApplyFilters={controller.handleApplyFilters}
                />
            </div>
        </div>
    );
};
