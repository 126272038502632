export class Country {

    public constructor(
        private _countryId: string,
        private _name: string,
        private _region: string,

    ) {
        this.ensureIsNotUndefined(_countryId);
        this.ensureIsNotUndefined(_name);

    }

    public get region(): string {
        return this._region;
    }
    public get name(): string {
        return this._name;
    }
    public get countryId(): string {
        return this._countryId;
    }

    private ensureIsNotUndefined(value: string): void {
        if (value === undefined) {
            throw new Error('CountryId and CountryName cannot be undefined ');
        }
    }

}
