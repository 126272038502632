import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { CheckoutForm } from './CheckoutForm';
import { Price } from '../../../../orders/model/value-objects/Price';

interface CartPaymentProps {
    stripePromise: Promise<Stripe | null>;
    secret: string;
    amount: Price | undefined;
    onReset?: () => void;
}

export const CartPayment = (props: CartPaymentProps): React.ReactElement => {
    return (
        <Elements stripe={props.stripePromise} options={{ clientSecret: props.secret }}>
            <CheckoutForm amount={props.amount} onReset={props.onReset} />
        </Elements>
    );
};
