import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BESTSELLERS, OUTLET, BOX_UNIT, PIECE_UNIT, ADD_TO_CART } from 'i18n/translations/TR';
import { NumberFormatComponentPieceUnit } from 'app/shared/NumberFormatPieceUnit';
import { Link } from 'react-router-dom';
import { ApiProduct } from '../home/infrastructure/api/response/ApiProduct';
import { ApiProductDetail } from '../product-details/shared/domain/ApiProductDetail';
import { ShoppingModal } from '../product-details/components/ShoppingModal';
import { getWeight } from './getWeight';
import { getArea } from './getArea';
import { getSquareMeasurement } from './getSquareMeasurement';
import { TencerRoutes } from './tencer-routing/TencerRoutes';
import { getRoundedValues } from './getRoundedValues';
import { ReactComponent as IcSize } from '../../assets/img/icons/ic-box.svg';
import { ReactComponent as IcWeight } from '../../assets/img/icons/ic-weight.svg';
import { ReactComponent as IcArea } from '../../assets/img/icons/ic-area.svg';
import { ReactComponent as IcLocation } from '../../assets/img/icons/ic-location-black.svg';
import { ReactComponent as IcStock } from '../../assets/img/icons/ic-stock.svg';
import { Currency } from './Currency';
import { TencerApiClientSingleton } from './hooks/TencerApiClientSingleton';
import { MarketingCollectionId } from '../products/domain/model/MarketingCollectionId';
import { usePermission } from './hooks/use-Permission';

interface ProductCarouselItemProps {
    product: ApiProduct;
    title: string
}

export const SwiperCard: React.FC<ProductCarouselItemProps> =
    (
        { product, title },
    ) => {
        const { t } = useTranslation();

        const urlRouter = TencerRoutes.PRODUCT_DETAILS;
        const urlRouterFormat = urlRouter.replace(':id', '');
        const [productDetail, setProductDetail] = useState<ApiProductDetail>();

        const stockClass = classNames(
            'product-stock',
            { 'd-none': title === t(BESTSELLERS) || title === t(OUTLET) },
        );

        const permission = usePermission();

        const [show, setShow] = useState(false);
        const [showForce, setShowForce] = useState(false);
        const mounted = useRef(false);
        const handleClose = (): void => {
            setShow(false);
        };
        const handleShow = (): void => {
            setShow(true);
        };
        const handleCloseForce = (): void => setShowForce(false);
        const handleShowForce = (): void => setShowForce(true);

        React.useEffect(() => {
            TencerApiClientSingleton.getInstance().getProduct(product.product)
                .then((fetchedProduct) => setProductDetail(fetchedProduct));
            return (): void => {
                mounted.current = false;
            };
        }, []);

        return (
            <div className="product-detail-image grid-item">
                <Link
                    to={`${urlRouterFormat}${product.product}`}
                    style={{ textDecoration: 'none' }}
                    className="grid-item"
                    key={product.product}
                >
                    <div className="product-image">
                        <img src={product.image} alt="product" />
                        {window.location.pathname === TencerRoutes.CATALOG ?
                            (
                                <div className={stockClass}>
                                    {product.outlet ?
                                        (
                                            <IcStock className="icon-mini" />
                                        ) : null}
                                </div>
                            ) : null}
                    </div>
                    <div className="product-item-details">
                        <div className="product-title">{product.description}</div>
                        <div className="product-description">{product.collection}</div>
                        {product.outlet ?
                            (
                                <div className="product-location">
                                    <IcLocation className="icon-mini" />
                                    {product.family}
                                </div>
                            ) : null}
                        {product.boxPiece > 0 ?
                            (
                                <div className="product-size">
                                    <IcSize className="icon-mini" />
                                    {`${product.boxPiece} ${t(PIECE_UNIT)}/${t(BOX_UNIT)}`}
                                </div>
                            ) : null}
                        {product.boxMeter > 0 ? (
                            <div className="product-size">
                                <IcArea className="icon-mini" />
                                <NumberFormatComponentPieceUnit
                                    value={getRoundedValues(product.boxMeter, 3)}
                                    suffix={` ${getSquareMeasurement()}/${t(BOX_UNIT)}`}
                                />
                            </div>
                        ) : null }
                        {product.boxWeight > 0 ? (
                            <div className="product-weight">
                                <IcWeight className="icon-mini" />
                                <NumberFormatComponentPieceUnit
                                    value={getRoundedValues(product.boxWeight)}
                                    suffix={` ${getWeight()}/${t(BOX_UNIT)}`}
                                />
                            </div>
                        ) : null}
                    </div>
                </Link>
                <div className="product-item-detail">
                    <div className="product-item-footer">
                        <div
                            className="product-price"
                            style={{ marginLeft: '20px', justifyContent: 'space-around' }}
                        >
                            {product.price !== null ?
                                (
                                    <div className="price-section">
                                        <Currency
                                            className="border-0 import"
                                            value={product?.price.value}
                                        />
                                        <div
                                            className="units-type-mod"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            {getArea(product.unit)}
                                        </div>
                                    </div>
                                ) : null}

                            {permission.canDoOrders() && product.collectionId !== MarketingCollectionId ?
                                (
                                    <div style={{ marginBottom: '20px' }}>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={handleShow}
                                        >
                                            {t(ADD_TO_CART)}
                                        </button>
                                    </div>
                                ) : null}
                        </div>
                    </div>
                </div>

                { productDetail ? (
                    <ShoppingModal
                        show={show}
                        onHideShop={handleClose}
                        productDetails={productDetail}
                        handleCloseForce={handleCloseForce}
                        handleShowForce={handleShowForce}
                        showForce={showForce}
                    />
                ) : null}

            </div>

        );
    };
