import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Invoice } from '../../../domain/model/Invoice';
import { InvoiceNumber } from '../../../domain/vo/InvoiceNumber';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';
import { DependencyInjectionContext } from '../../../../shared/context/DependecyInjectionContext';

interface Response {
    invoice?: Invoice,
    goBack: () => void;
}

interface URLParams extends Record<string, string>{
    id: string;
}
export const useInvoiceDetailScreenController = () : Response => {
    const dic = useContext(DependencyInjectionContext);
    const [invoice, setInvoice] = useState<Invoice>();
    const invoiceRepository = dic.invoiceRepository;
    const urlParams = useParams<URLParams>();
    const navigate = useNavigate();

    useEffect(() => {
        if (!urlParams.id) {
            return;
        }
        const invoiceNumber:InvoiceNumber = new InvoiceNumber(urlParams.id);
        invoiceRepository.find(invoiceNumber).then(setInvoice);
    }, []);

    const goBack = (): void => {
        navigate(TencerRoutes.INVOICES);
    };

    return {
        invoice,
        goBack,
    };
};
