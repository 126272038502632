import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    PAYMENT_KO_TEXT,
    PAYMENT_KO_TITLE,
    PAYMENT_TERMS_TITLE,
} from '../../../../../i18n/translations/TR';
import { ReactComponent as IconError } from '../../../../../assets/img/icons/ic-error.svg';
import './paymentTermsKo.scss';

interface Props {
    actions: React.ReactElement;
}

export const PaymentTermsKo: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className="component-payment-terms-ko payment-result payment-result-ko">
            <div className="header">
                <div className="title">
                    <h1 className="section-title">
                        <span className="text">{t(PAYMENT_TERMS_TITLE)}</span>
                        <span className="icon"><IconError /></span>
                        <p>{t(PAYMENT_KO_TITLE)}</p>
                    </h1>
                </div>
            </div>
            <div className="body">
                <p>{t(PAYMENT_KO_TEXT)}</p>
                <div className="actions">
                    {props.actions}
                </div>
            </div>
        </div>
    );
};
