export class Price {

    private readonly _amount: number;
    private readonly _currency: string | undefined;

    public constructor(value: number, currency?: string | undefined) {
        this.ensureIsNotUndefined(value);
        this._amount = value;
        this._currency = currency;
    }

    private ensureIsNotUndefined(value: number): void {
        if (value === undefined) {
            throw new Error('Price value is undefined');
        }
    }

    public get amount(): number {
        return this._amount;
    }

    public get currency(): string | undefined {
        return this._currency;
    }

    public add(other: Price): Price {
        return new Price(this.amount + other.amount);
    }

    public subtract(other: Price):Price {
        return new Price(this.amount - other.amount);
    }

    public diff(other: Price): number {
        return this._amount - other.amount;
    }

    static zero(currency?: string): Price {
        return new Price(0.0, currency);
    }

}
