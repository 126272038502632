import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { useTranslation } from 'react-i18next';
import { NO_MORE_RESULTS, PRODUCTS } from 'i18n/translations/TR';
import { SectionHeaderCatalog } from 'app/shared/SectionHeaderCatalog';
import { Spinner } from 'app/shared/Spinner';
import { PageBanner } from 'app/page-banner/component/PageBanner';
import { useProductCatalogController } from 'app/product-catalog-collections/Catalog/useProductCatalogController';
import { ProductsCatalogList } from './components/ProductCatalogList';
import { FilterModal } from './components/FilterModal';
import { HeaderCatalogCollections } from '../components/HeaderCatalogCollections';

interface Props {
    search: string;
    setSearch: (text: string) => void;
}

export const ProductCatalogScreen: React.FC<Props> = (props: Props) => {
    const classes = classNames(
        'tab-pane fade',
        { 'show active': window.location.pathname === TencerRoutes.CATALOG },
    );

    const { t } = useTranslation();
    const controller = useProductCatalogController({
        search: props.search,
        setSearch: props.setSearch,
    });

    const title = t(PRODUCTS);

    return (
        <section className="header-section">
            <PageBanner pageId={title} />
            <HeaderCatalogCollections />
            <div className="container tab-content" id="pills-tabContent">

                <div
                    className={classes}
                    id="pills-catalog"
                    role="tabpanel"
                    aria-labelledby="pills-catalog-tab"
                >
                    {!controller.isLoading ?
                        (
                            <div>
                                <SectionHeaderCatalog
                                    title={title}
                                    handleShow={controller.handleShowFilters}
                                    filters={controller.filters}
                                    onRemove={controller.removeFilter}
                                />

                                <InfiniteScroll
                                    dataLength={controller.products.length}
                                    next={controller.fetchMore}
                                    hasMore={!controller.hasMore ? false : controller.hasMore}
                                    loader={<Spinner classCss="basic-spinner-container" />}
                                    endMessage={<p className="end-message">{t(NO_MORE_RESULTS)}</p>}
                                >
                                    <>
                                        <ProductsCatalogList productsCatalog={controller.products} />
                                        {controller.showFilters ? (
                                            <FilterModal
                                                show={controller.showFilters}
                                                onHide={controller.handleCloseFilters}
                                                filters={controller.filters}
                                                onReset={controller.resetFilters}
                                                onApply={controller.applyFilters}
                                            />
                                        ) : null}
                                    </>
                                </InfiniteScroll>
                            </div>
                        ) : <Spinner classCss="header-section-spinner" />}
                </div>
            </div>
        </section>
    );
};

ProductCatalogScreen.defaultProps = {};
