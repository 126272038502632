import { InvoiceFilter } from './InvoiceFilter';

export class InvoiceFilterCollection {

    private _filters: { [key: string]: InvoiceFilter } = {};

    public get filters(): { [key: string]: InvoiceFilter; } {
        return this._filters;
    }
    public set filters(value: { [key: string]: InvoiceFilter }) {
        this._filters = value;
    }

    constructor(defaultFilters: InvoiceFilter[]) {
        defaultFilters.forEach((filter: InvoiceFilter) => {
            this.addFilter(filter);
        });
    }

    public getValue(key: string): InvoiceFilter {
        return this._filters[key];
    }

    public addFilter(filter: InvoiceFilter): void {
        this._filters[filter.name] = filter;
    }

    public removeFilter(key: string): void {
        delete this._filters[key];
    }

    public toArray(): InvoiceFilter[] {
        return Object.values(this._filters);
    }

}
