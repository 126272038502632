import React from 'react';
import { useTranslation } from 'react-i18next';
import { COWeight } from '../../../../shared/components/CO-weight';
import { Currency } from '../../../../shared/Currency';
import { getRoundedValues } from '../../../../shared/getRoundedValues';
import {
    CART_DISCOUNTS_APPLIED_LABEL,
    CONSOLIDATED_ORDER, CREDIT_MEMO_LABEL,
    LIFT_GATE_LABEL,
    ORDER_SHIPPING_ADDRESS,
    ORDER_SUMMARY_TITLE,
    ORDER_SUMMARY_TOTAL_PRODUCTS,
    ORDER_SUMMARY_TOTAL_WEIGHT, PICK_HANDLE,
    SHIPPING_INSTRUCTIONS,
    TRANSPORT_LABEL,
} from '../../../../../i18n/translations/TR';
import Order from '../../../model/Order';
import { TotalPrice } from '../../../../cart/components/total-price/total-price';
import { DiscountsSummary } from '../../components/DiscountsSummary/DiscountsSummary';
import { DiscountsSummaryLayout } from '../../components/DiscountsSummary/DiscountsSummaryLayout';
import { ShippingAddress } from '../../../model/ShippingAddress';
import { useOrigin } from '../../../../shared/hooks/useOrigin';
import { Origin } from '../../../../shared/domain/value-objects/Origin';

interface OrderSummaryProps {
    order: Order
    orderShippingAddress: ShippingAddress | undefined
}

export const OrderSummary = (
    {
        order,
        orderShippingAddress,
    }: OrderSummaryProps,
): React.ReactElement => {
    const origin = useOrigin();
    const { t } = useTranslation();

    const shippingInstructionsInfo = (): string => {
        if (!order.transport) {
            return '';
        }
        if (order.transport.liftGate && order.transport.isConsolidated) {
            return `${order.transport.type.translate} (${t(CONSOLIDATED_ORDER)} / ${t(LIFT_GATE_LABEL)})`;
        }
        if (!order.transport?.liftGate && order.transport?.isConsolidated) {
            return `${order.transport?.type.translate} (${t(CONSOLIDATED_ORDER)})`;
        }
        if (order.transport?.liftGate && !order.transport.isConsolidated) {
            return `${order.transport.type.translate} (${t(LIFT_GATE_LABEL)})`;
        }

        return order.transport.type.translate;
    };

    return (
        <div className="summary-container order-summary-container">
            <div className="summary-header order-summary-header">
                <h5 className="card-wow-title">{t(ORDER_SUMMARY_TITLE)}</h5>
                {order.net ? (
                    <TotalPrice
                        price={order.net}
                    />
                ) : null}
            </div>

            <div className="summary-box order-summary-box">
                <div className="summary-box-items-container order-summary-box-items-container">
                    <div className="summary-box-item order-summary-box-item">
                        <p className="summary-box-item-title order-summary-box-item-title">
                            {t(ORDER_SUMMARY_TOTAL_PRODUCTS)}
                        </p>
                        <div className="summary-box-item-body order-summary-box-item-body">
                            <p>{order.lines?.length}</p>
                        </div>
                    </div>
                    <div className="summary-box-item order-summary-box-item">
                        <p className="summary-box-item-title order-summary-box-item-title">
                            {t(ORDER_SUMMARY_TOTAL_WEIGHT)}
                        </p>
                        <div className="summary-box-item-body order-summary-box-item-body">
                            <COWeight
                                value={getRoundedValues(order.weightGross?.weight ?? 0)}
                                className="import"
                            />
                        </div>
                    </div>
                </div>
                <div className="summary-box-items-container order-summary-box-items-container">
                    {orderShippingAddress && origin === Origin.EUROPE ? (
                        <div className="summary-box-item order-summary-box-item">
                            <p className="summary-box-item-title order-summary-box-item-title">
                                {t(ORDER_SHIPPING_ADDRESS)}
                            </p>
                            <div className="summary-box-item-body order-summary-box-item-body">
                                <p>
                                    {orderShippingAddress.buildAddressInfo()}
                                </p>
                            </div>
                        </div>
                    ) : null}
                    {order.shippingAddress && origin === Origin.USA ? (
                        <div className="summary-box-item order-summary-box-item">
                            <p className="summary-box-item-title order-summary-box-item-title">
                                {t(ORDER_SHIPPING_ADDRESS)}
                            </p>
                            <div className="summary-box-item-body order-summary-box-item-body">
                                <p>
                                    {order.shippingAddress.buildAddressInfo()}
                                </p>
                            </div>
                        </div>
                    ) : null}
                    {order.transport ? (
                        <div className="summary-box-item order-summary-box-item">
                            <p className="summary-box-item-title order-summary-box-item-title">
                                {t(SHIPPING_INSTRUCTIONS)}
                            </p>
                            <div className="summary-box-item-body order-summary-box-item-body">
                                <p>
                                    {shippingInstructionsInfo()}
                                </p>
                            </div>
                        </div>
                    ) : null}
                </div>
                {
                    order.discounts.length > 0 ? (
                        <div className="summary-box-items-container order-summary-box-items-container">
                            <div className="summary-box-item order-summary-box-item">
                                <p className="summary-box-item-title order-summary-box-item-title">
                                    {t(CART_DISCOUNTS_APPLIED_LABEL)}
                                </p>
                                <div className="summary-box-item-body order-summary-box-item-body">
                                    <DiscountsSummary
                                        discounts={order.discounts}
                                        gross={order.gross}
                                        layout={DiscountsSummaryLayout.FULL}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                {
                    order.transport?.courier?.price || order.transport?.pickHandlePrice ? (
                        <div className="summary-box-items-container order-summary-box-items-container">
                            <div className="summary-box-items-container order-summary-box-items-container">
                                { order.transport?.courier?.price ? (
                                    <div className="summary-box-item order-summary-box-item">
                                        <p className="summary-box-item-title order-summary-box-item-title">
                                            {t(TRANSPORT_LABEL)}
                                        </p>
                                        <div className="order-summary-box-item-body">
                                            <Currency
                                                className="import"
                                                value={getRoundedValues(order.transport.courier.price.amount)}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                { order.transport?.pickHandlePrice ? (
                                    <div className="summary-box-item order-summary-box-item">
                                        <p className="summary-box-item-title order-summary-box-item-title">
                                            {t(PICK_HANDLE)}
                                        </p>
                                        <div className="order-summary-box-item-body">
                                            <Currency
                                                className="import"
                                                value={getRoundedValues(order.transport.pickHandlePrice.amount)}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : null
                }
                {
                    order.creditMemo ? (
                        <div className="summary-box-items-container order-summary-box-items-container">
                            <div className="summary-box-items-container order-summary-box-items-container">
                                <div className="summary-box-item order-summary-box-item">
                                    <p className="summary-box-item-title order-summary-box-item-title">
                                        {t(CREDIT_MEMO_LABEL)}
                                    </p>
                                    <div className="order-summary-box-item-body">
                                        <Currency
                                            className="import"
                                            value={getRoundedValues(order.creditMemo.amount)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </div>
    );
};
