export class Address {

    private readonly _value: string;

    public constructor(value:string) {
        this.ensureIsNotUndefined(value);
        this._value = value?.trim();

    }

    private ensureIsNotUndefined(value: string): void {
        if (value === undefined) {
            throw new Error('Address cannot be undefined ');
        }
    }

    public get value(): string {
        return this._value;
    }

}
