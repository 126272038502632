enum ApplicationAccessTypeEnum {
    PLACE_ORDERS = 'PLACE_ORDERS',
    INQUIRE = 'INQUIRE',
}

export class ApplicationAccessType {

    constructor(private _value: string) {}

    static PLACE_ORDERS = new ApplicationAccessType(ApplicationAccessTypeEnum.PLACE_ORDERS);
    static INQUIRE = new ApplicationAccessType(ApplicationAccessTypeEnum.INQUIRE);

    get value(): string {
        return this._value;
    }

    public toString(): string {
        return this._value;
    }

    static fromString(value: string): ApplicationAccessType {
        switch (value) {
            case ApplicationAccessTypeEnum.PLACE_ORDERS.toString():
                return ApplicationAccessType.PLACE_ORDERS;
            case ApplicationAccessTypeEnum.INQUIRE.toString():
                return ApplicationAccessType.INQUIRE;
            default:
                throw new Error(`Invalid ApplicationAccessType value: ${value}`);
        }
    }

    equals(other: ApplicationAccessType): boolean {
        return this._value === other.value;
    }

}
