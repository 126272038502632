import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { FILTER, BESTSELLERS, PRODUCTS, OUTLET, HOME, SEARCH, IN_STOCK, NO_STOCK } from 'i18n/translations/TR';
import { useForm } from 'react-hook-form';
import { ProductFilterCollection } from 'app/orders/model/ProductFilterCollection';
import { TencerRoutes } from './tencer-routing/TencerRoutes';
import { ReactComponent as IcFilter } from '../../assets/img/icons/ic-filter.svg';
import { ReactComponent as IcClose } from '../../assets/img/icons/ic-close.svg';

interface Props {
    title: string,
    handleShow?: () => void,
    onRemove?: (key: string) => void,
    filters?: ProductFilterCollection,
}

interface FormInputs {
    search: string,
}

export const SectionHeaderCatalog: React.FC <Props> = (props: Props) => {

    const history = useNavigate();

    const { t } = useTranslation();

    // eslint-disable-next-line no-undef
    const [interval, setInterval] = useState<NodeJS.Timeout>();

    const { register, watch } = useForm<FormInputs>({
        mode: 'onChange',
    });

    const watchSearch = useRef({});
    watchSearch.current = watch('search');

    const goAndSearch = (searchInput: string):void => {
        if (searchInput.length) {
            const form = searchInput;
            if (form.length >= 3) {
                if (interval) {
                    clearInterval(interval);
                }

                const intervalId = setTimeout(() => {
                    history(TencerRoutes.CATALOG, { state: { searchCatalog: form } });
                }, 2000);
                setInterval(intervalId);
            }
        }

    };

    const classes = classNames(
        'actions',
        { 'd-none': props.title === t(OUTLET) || props.title === t(BESTSELLERS) },
    );

    const classFilter = classNames(
        'filter-tag-container',
        Object.keys(props.filters || {}).length > 0 ? 'd-flex' : 'd-none',
    );

    const classBreadcrumb = classNames(
        { 'd-none': props.title === t(PRODUCTS) },
    );

    const goCatalog = ():void => {
        history(TencerRoutes.CATALOG, { state: { productsType: t(PRODUCTS) } });
    };

    const goHome = ():void => {
        history(TencerRoutes.HOME);
    };

    const openFilters = ():void => {
        if (!props.handleShow) {
            return;
        }
        props.handleShow();
    };

    const removeFilter = (key: string):void => {
        if (!props.onRemove) {
            return;
        }
        props.onRemove(key);

    };

    return (
        <div className="section-header-catalog">

            <nav aria-label="breadcrumb" className={classBreadcrumb}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item" onClick={goHome} role="presentation">{t(HOME)}</li>
                    <li className="breadcrumb-item" onClick={goCatalog} role="presentation">{t(PRODUCTS)}</li>
                    <li className="breadcrumb-item active" aria-current="page">{props.title}</li>
                </ol>
            </nav>

            { props.filters ? (

                <>
                    <div className="header-catalog header-actions">
                        <h3 className="section-title">{props.title}</h3>
                        <div className={classes}>
                            <input
                                className="form-control me-2 form-search d-none"
                                type="search"
                                placeholder={t(SEARCH)}
                                aria-label="Search"
                                {...register(
                                    'search',
                                    { required: true },
                                )}
                                defaultValue={props.filters.getValue('search')?.value}
                                onChange={(e): void => goAndSearch(e.target.value)}
                            />
                            <p
                                className="bg-transparent"
                                onClick={openFilters}
                                aria-hidden="true"
                            >
                                <IcFilter />
                                <span>{t(FILTER)}</span>
                            </p>
                        </div>
                    </div>
                    <div className={classFilter}>
                        {props.filters.getValue('material') ? (
                            <div className="filter-tag">
                                <span>{props.filters.getValue('material')?.value}</span>
                                <IcClose
                                    onClick={(): void => { removeFilter('material'); }}
                                    role="presentation"
                                />
                            </div>
                        ) : null}

                        {props.filters.getValue('collection') ? (
                            <div className="filter-tag">
                                <span>{props.filters.getValue('collection')?.value}</span>
                                <IcClose onClick={(): void => removeFilter('collection')} role="presentation" />
                            </div>
                        ) : null}

                        {props.filters.getValue('brand') ? (
                            <div className="filter-tag">
                                <span>{props.filters.getValue('brand')?.value}</span>
                                <IcClose onClick={(): void => removeFilter('brand')} role="presentation" />
                            </div>
                        ) : null}
                        {props.filters.getValue('stock') ? (
                            <div className="filter-tag">
                                <span>{props.filters.getValue('stock')?.value ? t(IN_STOCK) : t(NO_STOCK)}</span>
                                <IcClose onClick={(): void => removeFilter('stock')} role="presentation" />
                            </div>
                        ) : null}
                    </div>
                </>
            ) : null }
        </div>
    );
};

SectionHeaderCatalog.defaultProps = {};
