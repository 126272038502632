import React from 'react';
import { useTranslation } from 'react-i18next';
import { URL } from 'app/shared/domain/value-objects/URL';
import {
    PAYMENT_TERMS_LIMIT_EXCEEDED_ERROR,
    PROCESS_ORDER,
    YOU_MUST_SELECT_A_SHIPPING_ADDRESS,
} from '../../../i18n/translations/TR';
import { CartSummaryFooter } from './components/footer/CartSummaryFooter';
import { useCartSummary } from './hooks/useCartSummary';
import { PageSpinner } from '../../shared/components/page-spinner/page-spinner';
import './cartSummaryScreen.scss';
import { PageBanner } from '../../page-banner/component/PageBanner';
import { CartSummaryBody } from './components/body/CartSummaryBody';
import PageBannerModel from '../../page-banner/model/PageBanner';
import { TencerRoutes } from '../../shared/tencer-routing/TencerRoutes';
import { ModalError } from '../../shared/ModalError';

export const CartSummaryScreen = (): React.ReactElement => {

    const { t } = useTranslation();
    const UseCartSummary = useCartSummary();

    return (
        <main className="screen screen-cart-summary">
            {UseCartSummary.exceedTermsLimit ? (
                <PageBanner
                    pageId={UseCartSummary.pageName}
                    forcedMessage={
                        new PageBannerModel(
                            t(PAYMENT_TERMS_LIMIT_EXCEEDED_ERROR),
                            new URL(TencerRoutes.CART_PAYMENT_TERMS),
                        )
                    }
                    classes={['danger']}
                />
            ) : <PageBanner pageId={UseCartSummary.pageName} />}

            <PageSpinner isBusy={UseCartSummary.cartSummaryScreenIsBusy} />

            {UseCartSummary.cart?.items ? (
                <div className="cart-container">
                    <div className=" cart-header">
                        <h4 className="section-title">{t(PROCESS_ORDER)}</h4>
                    </div>

                    <CartSummaryBody
                        UseCartSummary={UseCartSummary}
                    />

                    {UseCartSummary.cart.orderId ? (
                        <CartSummaryFooter
                            cart={UseCartSummary.cart}
                            onSubmit={UseCartSummary.confirmCart}
                            cartSummaryForm={UseCartSummary.cartSummary.cartCheckoutForm}
                        />
                    ) : null}
                </div>
            ) : null}

            <ModalError
                show={UseCartSummary.showShippingAddressError}
                message={t(YOU_MUST_SELECT_A_SHIPPING_ADDRESS)}
                onHide={UseCartSummary.handleHideShippingAddressError}
            />
        </main>
    );
};
