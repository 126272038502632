import { ICreditMemoResponse } from '../dto/responses/ICreditMemoResponse';
import { ICreditMemo } from '../../../../orders/model/CreditMemo';

export const CreditMemoMapper = {
    map: (creditMemo: ICreditMemoResponse): ICreditMemo => {
        return {
            id: creditMemo.memo_id || '',
            amount: creditMemo.amount || 0.0,
        };
    },
};
