import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {
    CONTACT_SUPPORT,
    PAYMENT_TERMS_REVIEW_ORDER,
    PAYMENT_TERMS_TEXT,
    PAYMENT_TERMS_TITLE,
} from '../../../../i18n/translations/TR';
import { CartPaymentTerms } from '../../../cartSummary/application/components/payment/CartPaymentTerms';
import { CActionButton } from '../../../shared/components/CActionButton/CActionButton';
import { CartPaymentTermsController } from './CartPaymentTermsController';
import { PageSpinner } from '../../../shared/components/page-spinner/page-spinner';
import { PaymentTermsKo } from './PaymentTermsKo/PaymentTermsKo';

export const CartPaymentTermsScreen = (): React.ReactElement => {
    const { t } = useTranslation();
    const pageName = 'cart_payment_terms';
    const controller = CartPaymentTermsController();
    const [paying, setPaying] = React.useState<boolean>(false);
    const onPayClick = (): void => {
        setPaying(true);
        controller.onCheckout().then(() => {
            setPaying(false);
        });
    };

    const canPayButtons = (
        <>
            <CActionButton
                text={t('CREDIT_CARD_PAYMENT_PAY_BUTTON')}
                onClick={onPayClick}
                isBusy={paying}
            />
            <CActionButton
                text={t('CANCEL')}
                onClick={controller.onReset}
                className="btn-primary-outline"
            />
        </>
    );

    const canNotPayActions = (
        <>
            <CActionButton
                text={t(PAYMENT_TERMS_REVIEW_ORDER)}
                textIsBusy={t(PAYMENT_TERMS_REVIEW_ORDER)}
                onClick={controller.onReset}
            />
            <CActionButton
                text={t(CONTACT_SUPPORT)}
                textIsBusy={t(CONTACT_SUPPORT)}
                onClick={controller.onRequestSupport}
                className="btn-primary-outline"
            />
        </>
    );

    return (
        <div>
            <div className={classNames('screen', `screen-${pageName}`)}>
                <div className={classNames('container', `container-${pageName}`, 'screen-container', 'l-modal')}>
                    {
                        controller.isLoading && !controller.error ? (
                            <PageSpinner isBusy />
                        ) : (
                            <>
                                <div className="header">
                                    <div className="title">
                                        <h1 className="section-title">{t(PAYMENT_TERMS_TITLE)}</h1>
                                    </div>
                                </div>
                                <div className="body">
                                    <p>{t(PAYMENT_TERMS_TEXT)}</p>
                                    <div className="card-box">
                                        {controller.terms ?
                                            (
                                                <CartPaymentTerms
                                                    period={controller.terms.period}
                                                    amount={
                                                        controller.terms.credit_limit.diff(
                                                            controller.terms.credit_available,
                                                        )
                                                    }
                                                />
                                            )
                                            : <Skeleton />}
                                    </div>
                                    {controller.canPay ? canPayButtons : canNotPayActions}
                                </div>
                            </>
                        )

                    }
                    {!controller.isLoading && controller.error ? (
                        <PaymentTermsKo actions={canNotPayActions} />
                    ) : null}
                </div>
            </div>
        </div>
    );
};
