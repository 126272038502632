import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CONSOLIDATED_ORDER } from '../../../../../../../../i18n/translations/TR';

interface ConsolidatedFieldProps {
    consolidatedRegister: UseFormRegisterReturn
}

export const ConsolidatedField = (props: ConsolidatedFieldProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="transport-form-container-item">
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="consolidatedField"
                    {...props.consolidatedRegister}
                />
                <label className="form-check-label" htmlFor="consolidatedField">
                    <strong>{t(CONSOLIDATED_ORDER)}</strong>
                </label>
            </div>
        </div>
    );
};
