import { PermissionArray } from '../domain/permission-array';
import { PermissionName } from '../domain/value-objects/PermissionName';
import { Origin } from '../domain/value-objects/Origin';
import { EnvEnum } from '../domain/value-objects/EnvEnum';
import { useToken } from './use-token';

export interface usePermissionResponse {
    erpCode: string | undefined;
    exp: number | undefined;
    iat: number | undefined;
    rol: string | undefined;
    origin: string | undefined;
    username: string | undefined;
    permissions: PermissionArray;
    canDoOrders: () => boolean;
}

export const usePermission = (): {
    canDoOrders: () => boolean;
    canManageLoadingOrders: () => boolean;
    canViewInvoices: () => boolean;
} => {

    const token = useToken();
    const permissions = token.permissions;
    const origin = token.origin;

    const canDoOrders = (): boolean => {
        return permissions.has(PermissionName.ORDER)
            && !permissions.has(PermissionName.NO_PRICE);
    };

    const canManageLoadingOrders = (): boolean => {
        return canDoOrders()
            && origin === Origin.EUROPE
            && process.env.REACT_APP_ENV !== EnvEnum.PROD
            && permissions.has(PermissionName.LOADING_ORDER);
    };

    const canViewInvoices = (): boolean => {
        return origin === Origin.USA
            && process.env.REACT_APP_ENV !== EnvEnum.PROD
            && permissions.has(PermissionName.VIEW_INVOICES);
    };

    return {
        canDoOrders,
        canManageLoadingOrders,
        canViewInvoices,
    };
};
