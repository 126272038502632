import React from 'react';
import './HomeScreen.scss';
import { MainBanner } from './banner/MainBanner';
import { useHomeScreenController } from './useHomeScreenController';
import { CollectionsCarousel } from './carousel/collectionsCarousel/CollectionsCarousel';
import { BestSellersCarousel } from './carousel/bestSellersCarousel/BestSellersCarousel';
import { OutletCarousel } from './carousel/outletCarousel/OutletCarousel';

interface HomeScreenProps {
    title: string,
    url: string
}

export const HomeScreen: React.FC<HomeScreenProps> = () => {
    const controller = useHomeScreenController();

    return (
        <div className="co-app">
            <MainBanner
                collections={controller.collections}
                isGettingCollections={controller.isGettingCollections}
                errorGettingCollections={controller.errorGettingCollections}
            />

            <BestSellersCarousel
                bestSellersProducts={controller.bestSellersProducts}
                isGettingBestSellersProducts={controller.isGettingBestSellersProducts}
                errorGettingBestSellersProducts={controller.errorGettingBestSellersProducts}
            />

            <CollectionsCarousel
                collections={controller.collections}
                isGettingCollections={controller.isGettingCollections}
                errorGettingCollections={controller.errorGettingCollections}
            />

            <OutletCarousel
                outletProducts={controller.outletProducts}
                isGettingOutletProducts={controller.isGettingOutletProducts}
                errorGettingOutletProducts={controller.errorGettingOutletProducts}
            />
        </div>
    );
};
