import React, { ReactElement } from 'react';
import { SwiperSlide } from 'swiper/react';
import { Link, useNavigate } from 'react-router-dom';
import 'swiper/components/pagination/pagination.scss';
import { useTranslation } from 'react-i18next';
import { FEATURED_COLLECTIONS } from '../../../../../i18n/translations/TR';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';
import { FeaturedCollectionItem } from './featuredCollections/FeaturedCollectionItem';
import { ApiCollection } from '../../../infrastructure/api/response/ApiCollection';
import { Carousel } from '../Carousel';

interface CollectionsCarouselProps {
    collections: ApiCollection[]
    isGettingCollections: boolean
    errorGettingCollections: boolean
}

export const CollectionsCarousel = (props: CollectionsCarouselProps): ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goToCollections = ():void => {
        navigate(TencerRoutes.COLLECTIONS);
    };

    return (
        <Carousel
            showAdvice={false}
            onViewAll={goToCollections}
            title={t(FEATURED_COLLECTIONS)}
            error={props.errorGettingCollections}
            isLoading={props.isGettingCollections}
            hasItems={props.collections.length > 0}
        >
            <>
                {(props.collections.map((collection) => (
                    <SwiperSlide key={collection.id} className="swiper-slide">
                        <Link
                            key={collection.id}
                            to={`/product/${collection.id}`}
                            className="text-decoration-none w-100"
                        >
                            <FeaturedCollectionItem
                                collection={collection}
                            />
                        </Link>
                    </SwiperSlide>
                )))}
            </>
        </Carousel>
    );
};
