import React from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice } from '../../../../domain/model/Invoice';
import { TotalPrice } from '../../../../../cart/components/total-price/total-price';
import {
    CART_DISCOUNTS_APPLIED_LABEL, CREDIT_MEMO_LABEL, INVOICE_SUMMARY_SHIPPING_ADDRESS, INVOICE_SUMMARY_TITLE,
    INVOICE_SUMMARY_TOTAL_PRODUCTS, LIFT_GATE_LABEL, TRANSPORT_LABEL,
} from '../../../../../../i18n/translations/TR';
import { DiscountsSummary } from '../../../../../orders/application/components/DiscountsSummary/DiscountsSummary';
import { DiscountsSummaryLayout } from '../../../../../orders/application/components/DiscountsSummary/DiscountsSummaryLayout';
import { Currency } from '../../../../../shared/Currency';

interface Props {
    invoice: Invoice;
}

export const InvoiceSummary = (
    props: Props,
): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="summary-container invoice-summary-container">
            <div className="summary-header invoice-summary-header">
                <h5 className="card-wow-title">{t(INVOICE_SUMMARY_TITLE)}</h5>
                {props.invoice?.netAmount ? (
                    <TotalPrice
                        price={props.invoice.netAmount}
                    />
                ) : null}
            </div>

            {props.invoice ? (
                <div className="summary-box invoice-summary-box">
                    <div className="summary-box-items-container">
                        <div className="summary-box-item">
                            <p className="summary-box-item-title">
                                {t(INVOICE_SUMMARY_TOTAL_PRODUCTS)}
                            </p>
                            <div className="summary-box-item-body">
                                <p>{props.invoice?.lines?.length}</p>
                            </div>
                        </div>
                    </div>
                    <div className="summary-box-items-container">
                        <div className="summary-box-item">
                            <p className="summary-box-item-title">
                                {t(INVOICE_SUMMARY_SHIPPING_ADDRESS)}
                            </p>
                            <div className="summary-box-item-body">
                                <p>
                                    {props.invoice.shippingAddress.toString()}
                                </p>
                            </div>
                        </div>
                    </div>
                    {
                        props.invoice.discount.value > 0 ? (
                            <div className="summary-box-items-container">
                                <div className="summary-box-item">
                                    <p className="summary-box-item-title">
                                        {t(CART_DISCOUNTS_APPLIED_LABEL)}
                                    </p>
                                    <div className="summary-box-item-body">
                                        <DiscountsSummary
                                            discounts={[props.invoice.discount]}
                                            gross={props.invoice.bruteAmount}
                                            layout={DiscountsSummaryLayout.FULL}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                    {props.invoice.liftGatePrice.amount > 0 ? (
                        <div className="summary-box-items-container">
                            <div className="summary-box-item">
                                <p className="summary-box-item-title">
                                    {t(LIFT_GATE_LABEL)}
                                </p>
                                <div className="summary-box-item-body">
                                    <Currency
                                        className="import"
                                        value={props.invoice.liftGatePrice.amount}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {props.invoice.freightPrice.amount > 0 ? (
                        <div className="summary-box-items-container">
                            <div className="summary-box-item">
                                <p className="summary-box-item-title">
                                    {t(TRANSPORT_LABEL)}
                                </p>
                                <div className="summary-box-item-body">
                                    <Currency
                                        className="import"
                                        value={props.invoice.freightPrice.amount}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {props.invoice.creditMemo ? (
                        <div className="summary-box-items-container">
                            <div className="summary-box-item">
                                <p className="summary-box-item-title">
                                    {t(CREDIT_MEMO_LABEL)}
                                </p>
                                <div className="summary-box-item-body">
                                    <Currency
                                        className="import"
                                        value={props.invoice.creditMemo.amount}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};
