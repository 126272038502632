import * as React from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CART_ADD_WARNING_BIG_RESERVE, INVALID_CREDENTIALS, NO_AVAILABLE_STOCK_ADD_CART } from 'i18n/translations/TR';
import jwtDecode from 'jwt-decode';
import { TencerRoutes } from '../tencer-routing/TencerRoutes';
import { TencerApiClientSingleton } from '../hooks/TencerApiClientSingleton';
import { TokenResponse } from '../infrastructure/tencer-api/dto/responses/TokenResponse';
import { TencerAuthServiceInstance } from '../tencer-routing/tencer-auth-route/TencerAuthService';
import { ErrorCode } from '../domain/value-objects/ErrorCode';
import { DecodedToken, RolName } from '../hooks/use-token';

export type UserType = {
    token: string;
};
export type ErrorType = {
    error: string;
};

export type UserContextType = {
    currentUser?: UserType;
    setCurrentUser: (user: TokenResponse) => void;
    setAuthLoading: (isLoading: boolean) => void;
    authLoading: boolean;
    handleLogin: (email: string, password: string) => void;
    handleLogout: () => void;
    refreshForm: () => void;
    handlePasswordRecovery: (email: string, language: string) => void;
    errorMessage?: ErrorType;
    setError: (error: ErrorType | undefined) => void;
    errorMessagePasswordRecovery?: ErrorType;
    setErrorPasswordRecovery: (error: ErrorType) => void;
    setPasswordSuccess: (isUser: boolean) => void;
    errorMessageModal: string;
    setErrorMessageModal: (error: string) => void;
    passwordRecoverySuccess: boolean;
    setPasswordReset: (isReset: boolean) => void;
    passwordReset: boolean;
    setNoPasswordReset: (isReset: boolean) => void;
    noPasswordReset: boolean;
    setTokenExpired: (isExpired: boolean) => void;
    tokenExpired: boolean;
    itemToCart?: Object;
    setItemToCart: (user: string) => void;
    modifyCart: (
        amount: number, cartItemId: string, units: string, forced: string, callback: () => void,
        afterErrorUSA?: () => void, afterError?: ()=> void,
    ) => Promise<void>;
    setCartModified: (date: Date) => void;
    cartModified: Date| null;
    setNoStockAvailable: (noSTock: boolean) => void;
    noStockAvailable: boolean;
    setExpirationDate: (date: string | null) => void;
    timer: string;
    itemIdRemove: string;
    setItemIdRemove: (id: string) => void;
    force: string;
    setForce: (name: string) => void;
    measureType: string;
    setMeasureType: (name: string) => void;
    amountProductsRequested: number;
    setAmountProductsRequested: (value: number) => void;
    setCartLoading: (isLoading: boolean) => void;
    cartLoading: boolean;
    showAccessInvalidModal: boolean;
    isSubmittingLogin: boolean;
    closeAccessInvalidModal: () => void;
    cartItems: number | undefined
    setCartItems: React.Dispatch<React.SetStateAction<number | undefined>>
};
export const CurrentUserContext = React.createContext<UserContextType | null>(null);

type ProviderProps = {
    children: React.ReactNode;
};

export const CurrentUserProvider = ({ children }: ProviderProps): React.ReactElement => {

    const { t } = useTranslation();

    const [currentUser, setCurrentUser] = React.useState<UserType>();
    const [authLoading, setAuthLoading] = React.useState(false);
    const [errorMessage, setError] = React.useState<ErrorType>();
    const [passwordRecoverySuccess, setPasswordSuccess] = React.useState(false);
    const [errorMessagePasswordRecovery, setErrorPasswordRecovery] = React.useState<ErrorType>();
    const [passwordReset, setPasswordReset] = React.useState(false);
    const [noPasswordReset, setNoPasswordReset] = React.useState(false);
    const [tokenExpired, setTokenExpired] = React.useState(false);
    const [itemToCart, setItemToCart] = React.useState(Object);
    const [cartModified, setCartModified] = React.useState<Date | null>(null);
    const [noStockAvailable, setNoStockAvailable] = React.useState(false);
    const [expirationDate, setExpirationDate] = React.useState<string|null>(null);
    const [timer, setTimer] = React.useState<string>('');
    const [itemIdRemove, setItemIdRemove] = React.useState(String);
    const [force, setForce] = React.useState('');
    const [errorMessageModal, setErrorMessageModal] = React.useState<string>('');
    const [measureType, setMeasureType] = React.useState('');
    const [amountProductsRequested, setAmountProductsRequested] = React.useState(Number);
    const [cartLoading, setCartLoading] = React.useState(false);
    const [showAccessInvalidModal, setShowAccessInvalidModal] = useState(false);
    const [isSubmittingLogin, setIsSubmittingLogin] = useState(false);
    const [secondsRemaining, setSecondsRemaining] = React.useState(0);
    const [cartItems, setCartItems] = React.useState<number|undefined>();

    let currentTime = new Date();
    let expireTime = expirationDate ? new Date(expirationDate) : null;

    const APIClient = TencerApiClientSingleton.getInstance();

    const closeAccessInvalidModal = (): void => {
        setShowAccessInvalidModal(false);
    };

    const padTo2Digits = (num: number): string => {
        return num.toString().padStart(2, '0');
    };

    React.useEffect(() => {
        if (secondsRemaining <= 0 || !secondsRemaining) {
            setTimer('');
            return;
        }
        let seconds = Math.floor((secondsRemaining / 1000));
        let minutes = Math.floor((seconds / 60));
        let hours = Math.floor((minutes / 60));
        seconds %= 60;
        minutes %= 60;
        hours %= 24;
        setTimer(`${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`);
    }, [secondsRemaining]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!expirationDate) {
                setTimer('');
                clearInterval(interval);
                return;
            }
            currentTime = new Date();
            expireTime = new Date(expirationDate);
            setSecondsRemaining(expireTime.getTime() - currentTime.getTime());
            if (secondsRemaining <= 0 || !secondsRemaining) {
                clearInterval(interval);
                setTimer('');
            }
        }, 1000);

        return ():void => clearInterval(interval);
    });

    const handleLogin = async (username: string, password: string): Promise<void> => {
        setError(undefined);
        setIsSubmittingLogin(true);
        await APIClient.postLogin(username, password)
            .then((response) => {
                const decodedToken:DecodedToken = jwtDecode(response.token);
                if (decodedToken.roles[0] === RolName.ADMIN) {
                    setShowAccessInvalidModal(true);
                } else {
                    setCurrentUser({ token: response.token });
                    TencerAuthServiceInstance.setRefreshToken(response.refresh_token);
                    TencerAuthServiceInstance.authenticate(response.token);
                    window.location.pathname = TencerRoutes.HOME;
                }
                setIsSubmittingLogin(false);
            })
            .catch(() => {
                setError(t(INVALID_CREDENTIALS));
                setIsSubmittingLogin(false);
            });
    };

    const refreshForm = (): void => {
        setNoPasswordReset(false);
        setError(undefined);
    };

    const resetCartTimer = (): void => {
        if (!expirationDate) {
            return;
        }
        currentTime = new Date();
        expireTime = new Date(expirationDate);
        setSecondsRemaining(expireTime.getTime() - currentTime.getTime());
    };

    React.useEffect(() => {
        resetCartTimer();
    }, [cartModified]);

    const handlePasswordRecovery = async (email: string, language: string): Promise<void> => {
        TencerApiClientSingleton.getInstance().postPasswordReset(email, null, language)
            .then((res) => {
                setPasswordSuccess(true);
                return res;
            })
            .catch((errr) => {
                setNoPasswordReset(true);
                return errr;
            });

    };

    const handleLogout = async (): Promise<void> => {
        setCurrentUser(undefined);
        setTokenExpired(false);
        TencerAuthServiceInstance.clear();
        window.location.pathname = TencerRoutes.LOGIN_SIGN_IN;

    };

    const modifyCart = async (
        amount: number,
        cartItemId: string,
        units: string,
        forced: string,
        callback: () => void,
        afterErrorUSA?: () => void,
        afterError?: () => void,
    ): Promise<void> => {

        APIClient.putMyCartItems(units, amount, cartItemId, forced !== '')
            .then(() => {
                setCartModified(new Date());
                setForce('');
                setErrorMessageModal('');
                setMeasureType('');
                setItemIdRemove('');
                setAmountProductsRequested(Number);
                callback();
            })
            .catch((err) => {
                const errorCode = err.response.data.code;
                if (errorCode === ErrorCode.CART_NO_STOCK_AVAILABLE_USA && afterErrorUSA) {
                    afterErrorUSA();
                } else {
                    setNoStockAvailable(true);
                    setForce('?force=true');
                    if (err.response.data.code === 30004) {
                        setErrorMessageModal(CART_ADD_WARNING_BIG_RESERVE);
                    } else {
                        setErrorMessageModal(NO_AVAILABLE_STOCK_ADD_CART);
                    }
                    setItemIdRemove(cartItemId);
                    setMeasureType(units);
                    setAmountProductsRequested(amount);
                }
                if (afterError) {
                    afterError();
                }
                throw err;
            });

    };

    const stateValues = useMemo(() => ({
        currentUser,
        setCurrentUser,
        setAuthLoading,
        authLoading,
        handleLogin,
        handleLogout,
        refreshForm,
        handlePasswordRecovery,
        setError,
        errorMessage,
        errorMessageModal,
        setErrorMessageModal,
        setPasswordSuccess,
        passwordRecoverySuccess,
        setErrorPasswordRecovery,
        errorMessagePasswordRecovery,
        setPasswordReset,
        passwordReset,
        setNoPasswordReset,
        noPasswordReset,
        setTokenExpired,
        tokenExpired,
        itemToCart,
        setItemToCart,
        modifyCart,
        setCartModified,
        cartModified,
        setNoStockAvailable,
        noStockAvailable,
        setExpirationDate,
        timer,
        itemIdRemove,
        setItemIdRemove,
        force,
        setForce,
        measureType,
        setMeasureType,
        amountProductsRequested,
        setAmountProductsRequested,
        setCartLoading,
        cartLoading,
        showAccessInvalidModal,
        isSubmittingLogin,
        closeAccessInvalidModal,
        cartItems,
        setCartItems,
    }), [currentUser,
        setCurrentUser,
        setAuthLoading,
        authLoading,
        handleLogin,
        handleLogout,
        refreshForm,
        handlePasswordRecovery,
        setError,
        errorMessage,
        errorMessageModal,
        setErrorMessageModal,
        setPasswordSuccess,
        passwordRecoverySuccess,
        setErrorPasswordRecovery,
        errorMessagePasswordRecovery,
        setPasswordReset,
        passwordReset,
        setNoPasswordReset,
        noPasswordReset,
        setTokenExpired,
        tokenExpired,
        itemToCart,
        setItemToCart,
        modifyCart,
        setCartModified,
        cartModified,
        setNoStockAvailable,
        noStockAvailable,
        setExpirationDate,
        timer,
        itemIdRemove,
        setItemIdRemove,
        force,
        setForce,
        measureType,
        setMeasureType,
        amountProductsRequested,
        setAmountProductsRequested,
        setCartLoading,
        cartLoading,
        showAccessInvalidModal,
        isSubmittingLogin,
        closeAccessInvalidModal,
        cartItems,
        setCartItems,
    ]);

    return (
        <CurrentUserContext.Provider value={stateValues}>
            {children}
        </CurrentUserContext.Provider>
    );
};
