import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
    ADD_TO_CART,
    AREA,
    AVAILABLE_STOCK_FROM,
    BOX_UNIT,
    MATERIAL,
    NO_STOCK,
    OUTLET,
    PIECE_UNIT,
    PRODUCT_LOCATION,
    PRODUCT_SIZE,
    QUANTITY,
    REFERENCE,
    STOCK_AVAILABLE,
    WEIGHT,
} from 'i18n/translations/TR';
import { getWeight } from 'app/shared/getWeight';
import { getArea } from 'app/shared/getArea';
import { getMeasurement } from 'app/shared/getMeasurement';
import { getSquareMeasurement } from 'app/shared/getSquareMeasurement';
import { NumberFormatComponentPieceUnit } from 'app/shared/NumberFormatPieceUnit';
import { getRoundedValues } from 'app/shared/getRoundedValues';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import { Origin } from 'app/shared/domain/value-objects/Origin';
import { ReactComponent as IcStock } from '../../../assets/img/icons/ic-stock.svg';
import { ApiProductDetail } from '../shared/domain/ApiProductDetail';
import { ShoppingModal } from './ShoppingModal';
import { StockAvailable } from './StockAvailable';
import { Currency } from '../../shared/Currency';
import { EstimatedProductionDateLabel } from './EstimatedProductionDateLabel';
import { MarketingCollectionId } from '../../products/domain/model/MarketingCollectionId';
import { WarehouseLocation } from '../shared/domain/WarehouseLocation';
import { usePermission } from '../../shared/hooks/use-Permission';
import ProductQuantity from '../../orders/model/value-objects/ProductQuantity';

interface Props {
    detail: ApiProductDetail
    outOfStockWarehousesLocations: WarehouseLocation[]
}

export const DataSheet: React.FC<Props> = ({ detail, outOfStockWarehousesLocations }) => {
    const { t } = useTranslation();
    const origin = useOrigin();
    const permission = usePermission();

    const [show, setShow] = useState(false);
    const [showForce, setShowForce] = useState(false);
    const handleClose = (): void => setShow(false);
    const handleShow = (): void => setShow(true);
    const handleCloseForce = (): void => setShowForce(false);
    const handleShowForce = (): void => setShowForce(true);

    const stockClass = classNames(
        'product-stock text-start',
        { 'no-stock': detail.stock.amount === 0 && !detail.outlet },
        { 'in-stock': detail.stock.amount > 0 && !detail.outlet },
    );

    const stockStringF = (): string => {
        let stockString: string = '';
        const locale = origin === Origin.EUROPE ? 'es-ES' : 'en-US';

        if (!detail.outlet && detail.stock.amount > 0) {
            stockString = t(STOCK_AVAILABLE);
        } else if (detail.stock.amount === 0 && detail.stockEntryDate?.length) {
            stockString = `${t(AVAILABLE_STOCK_FROM)}${new Date(detail.stockEntryDate)
                .toLocaleDateString(locale)
                .replace(/\//g, '-')}`;
        } else if (!detail.outlet && detail.stock.amount === 0 && !detail.stockEntryDate?.length) {
            stockString = t(NO_STOCK);
        }
        return stockString;
    };

    return (
        <section className="header-section-detail bg-white">
            <div className="container">
                <div className="row">
                    <div
                        className="col-lg-6
                        product-info
                        d-flex
                        justify-content-center
                        align-items-center
                        mb-5"
                    >
                        <img className="img-fluid" src={detail?.image} alt="tile" />
                    </div>

                    <div className="col-lg-6 product-info">
                        <p className="product-subtitle text-start">{detail?.collection}</p>
                        <h3 className="page-title text-start">{detail?.description}</h3>
                        <div className="product-stock-labels-container">
                            <div className="product-stock-label">
                                <span className={stockClass}>
                                    {detail.outlet ? (
                                        <>
                                            <IcStock className="icon-mini" />
                                            <span className="stock-amount">
                                                {parseFloat(detail.stock.amount.toFixed(2)).toLocaleString('de-DE')}
                                            </span>
                                            <span className="stock-unit">
                                                {new ProductQuantity(
                                                    detail.stock.amount,
                                                    detail.stock.unit,
                                                ).unitLabel(origin)}
                                            </span>
                                        </>
                                    ) : (
                                        stockStringF()
                                    )}
                                </span>
                            </div>

                            {detail.outlet ? (
                                <div className="product-outlet-label">
                                    <span className={stockClass}>
                                        {t(OUTLET)}
                                    </span>
                                </div>
                            ) : null}
                        </div>

                        <EstimatedProductionDateLabel
                            amount={detail.stock.amount}
                            isOutlet={detail.outlet}
                            stockEntryDate={detail.stockEntryDate}
                            locale={origin === Origin.EUROPE ? 'es-ES' : 'en-US'}
                        />

                        {detail?.family !== null ? (
                            <p>
                                <strong>{`${t(PRODUCT_LOCATION)}: `}</strong>
                                {detail?.family}
                            </p>
                        ) : null}

                        <p>
                            <strong>{`${t(STOCK_AVAILABLE)}: `}</strong>
                        </p>
                        <div>
                            <StockAvailable
                                origin={origin}
                                amount={detail?.stock.amount}
                                unit={detail?.unit}
                                lot={detail.lotInfo ?? []}
                                outOfStockWarehousesLocations={outOfStockWarehousesLocations}
                            />
                        </div>
                        { detail?.format ?
                            (
                                <p>
                                    <strong>{`${t(PRODUCT_SIZE)}: `}</strong>
                                    {`${detail?.format} ${getMeasurement()}`}
                                </p>
                            )
                            : null}
                        { detail?.boxPiece > 0 ?
                            (
                                <p>
                                    <strong>{`${t(QUANTITY)}: `}</strong>
                                    {`${detail?.boxPiece} ${t(PIECE_UNIT)}/${t(BOX_UNIT)}`}
                                </p>
                            )
                            : null}
                        {detail.boxMeter > 0 ? (
                            <p>
                                <strong>{`${t(AREA)}: `}</strong>
                                <NumberFormatComponentPieceUnit
                                    value={detail.boxMeter > 0 ? getRoundedValues(detail.boxMeter, 3) : ''}
                                    suffix={` ${getSquareMeasurement()}/${t(BOX_UNIT)}`}
                                />
                            </p>
                        ) : null}

                        {detail.boxWeight > 0 ? (
                            <p>
                                <strong>{`${t(WEIGHT)}: `}</strong>
                                <NumberFormatComponentPieceUnit
                                    value={detail.boxWeight > 0 ? getRoundedValues(detail.boxWeight) : ''}
                                    suffix={` ${getWeight()}/${t(BOX_UNIT)}`}
                                />
                            </p>
                        ) : null}
                        <p>
                            <strong>{`${t(MATERIAL)}: `}</strong>
                            {detail?.paste}
                        </p>
                        <p>
                            <strong>{t(REFERENCE)}</strong>
                            {`: ${detail?.product}`}
                        </p>
                        <div className="detail-price mt-5">
                            {detail.price !== null ?
                                (
                                    <div className="product-price">
                                        <div className="import">
                                            <Currency
                                                value={getRoundedValues(detail?.price.value)}
                                            />
                                        </div>
                                        <div className="units-type text-start">{getArea(detail?.unit)}</div>
                                    </div>
                                ) : null}
                            {permission.canDoOrders() && detail.collectionId !== MarketingCollectionId ?
                                (
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={handleShow}
                                    >
                                        {t(ADD_TO_CART)}
                                    </button>
                                ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <ShoppingModal
                show={show}
                onHideShop={handleClose}
                productDetails={detail}
                handleCloseForce={handleCloseForce}
                handleShowForce={handleShowForce}
                showForce={showForce}
            />
        </section>
    );
};
