import React, { ReactElement } from 'react';
import { ApiCollection } from '../../../../infrastructure/api/response/ApiCollection';

interface FeaturedCollectionItemProps {
    collection: ApiCollection;
}

export const FeaturedCollectionItem = (props: FeaturedCollectionItemProps): ReactElement => {
    return (
        <li className="grid-item">
            <div className="product-image">
                <img src={props.collection.image} alt="product" />
            </div>
            <div className="product-item-details">
                <div className="product-title">{props.collection.name}</div>
                <div className="product-description">{props.collection.name}</div>
            </div>
        </li>
    );
};
