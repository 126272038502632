import React from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    DELIVERY_SERVICES,
    OBLIGATORY_FIELD_ERROR,
    SELECT_AN_OPTION,
} from '../../../../../../../../i18n/translations/TR';
import { CourierService } from '../../../../../../../shared/domain/CourierService';
import { Spinner } from '../../../../../../../shared/Spinner';

interface CourierServiceFieldProps {
    isLoading: boolean
    couriersServices: CourierService[]
    courierServiceFieldError: FieldError | undefined
    courierServiceFieldRegister: UseFormRegisterReturn
}

export const CourierServiceField = (props: CourierServiceFieldProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="transport-form-container-item__courier-service">
            <label
                htmlFor="courierService"
                className="form-label"
            >
                <span>{t(DELIVERY_SERVICES)}</span>
            </label>
            <div className="row">
                <div className="col-lg-12">
                    {props.isLoading ? (
                        <Spinner classCss="spinner-container" />
                    ) : (
                        <select
                            id="courierService"
                            className="form-select"
                            {...props.courierServiceFieldRegister}
                        >
                            <option value="">{t(SELECT_AN_OPTION)}</option>
                            {props.couriersServices.map((courierService) => (
                                <option
                                    key={courierService.id.value}
                                    value={courierService.id.value}
                                >
                                    {courierService.name}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
            </div>
            {
                props.courierServiceFieldError ? (
                    <div className="error-input-form">
                        {t(OBLIGATORY_FIELD_ERROR)}
                    </div>
                )
                    :
                    null
            }
        </div>
    );
};
