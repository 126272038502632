import React from 'react';
import classNames from 'classnames';

export const CartPaymentResultScreen: React.FC = ({ children }) => {
    const pageName = 'payment-result';

    return (
        <div>
            <div className={classNames('screen', `screen-${pageName}`)}>
                <div className={classNames('container', `container-${pageName}`, 'screen-container', 'l-modal')}>
                    {children}
                </div>
            </div>
        </div>
    );

};
