import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import { TransportRepository } from '../../domain/repository/TransportRepository';
import { Price } from '../../../orders/model/value-objects/Price';

export class ApiTransportRepository implements TransportRepository {

    private readonly client: TencerApiClient;

    constructor(client: TencerApiClient) {
        this.client = client;
    }

    async getLiftGatePrice(): Promise<number> {
        const response = await this.client.getLiftGatePrice();

        return response.value;
    }

    async getTransportPrice(): Promise<number> {
        const response = await this.client.getTransportPrice();

        return response.value;
    }

    async getAllowQuoteTransport(erpUserId: string): Promise<boolean> {
        const response = await this.client.getErpUser(
            {
                erpUserId,
            },
        );

        return response.allow_quote_transport;
    }

    async getPickHandle(): Promise<Price> {
        const response = await this.client.getPickHandle();

        return new Price(response);
    }

}
