import React from 'react';
import { Invoice } from '../../../domain/model/Invoice';
import { InvoicesTableHead } from './InvoicesTableHead';
import { InvoicesTableBody } from './InvoicesTableBody';
import { InvoiceNumber } from '../../../domain/vo/InvoiceNumber';

interface Props {
    invoices: Invoice[],
    goToDetails: (id: InvoiceNumber) => void,
}

export const InvoicesTable = (props: Props): React.ReactElement => {
    return (
        <table
            className="table orders-list table-responsive align-middle table-striped table-hover table-borderless"
        >
            <InvoicesTableHead />
            <InvoicesTableBody invoices={props.invoices} goToDetails={props.goToDetails} />
        </table>
    );
};
