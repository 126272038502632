import React from 'react';
import { Link } from 'react-router-dom';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { ApiProduct } from 'app/home/infrastructure/api/response/ApiProduct';
import { ListItem } from './ListItem';

interface Props {
    productsCatalog: ApiProduct[]
}

export const ProductsCatalogList: React.FC<Props> = (props: Props) => {

    const urlRouter = TencerRoutes.PRODUCT_DETAILS;
    const urlRouterFormat = urlRouter.replace(':id', '');

    return (
        <div>
            <section className="container section-list 100vh">

                { props.productsCatalog.map((product) => (
                    <Link
                        to={`${urlRouterFormat}${product.product}`}
                        style={{ textDecoration: 'none' }}
                        key={product.code}
                    >
                        <ListItem key={product.code} product={product} />
                    </Link>
                )) }
            </section>
        </div>
    );
};
