import React, { FormEvent, FormEventHandler, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Offcanvas } from 'react-bootstrap';
import { InvoiceFilterCollection } from '../../../domain/model/InvoiceFilterCollection';
import { InvoiceFilter } from '../../../domain/model/InvoiceFilter';
import { InvoiceFilterName } from '../../hooks/useInvoiceFilters';
import {
    APPLY_FILTERS,
    FILTERS,
    ORDER_FILTER_LABEL_DATE_FROM,
    ORDER_FILTER_LABEL_DATE_TO,
    REMOVE_FILTERS,
} from '../../../../../i18n/translations/TR';

interface Props {
    show: boolean;
    onHide: () => void;
    onApplyFilters: (filters: InvoiceFilterCollection) => void;
}

export const FilterInvoicesModal = (props: Props): ReactElement<Props> => {
    const { t } = useTranslation();

    const [filters, setFilters] = useState<InvoiceFilterCollection>(new InvoiceFilterCollection([]));

    const [invoiceDateFrom, setInvoiceDateFrom] = useState<string>('');
    const [orderNumber, setOrderNumber] = useState<string>('');

    useEffect(() => {
        if (invoiceDateFrom) {
            filters.addFilter(
                new InvoiceFilter(InvoiceFilterName.START_DATE, moment(invoiceDateFrom).unix().toString()),
            );
        } else {
            filters.removeFilter(InvoiceFilterName.START_DATE);
        }
        setFilters(filters);
    }, [invoiceDateFrom]);

    const [invoiceDateTo, setInvoiceDateTo] = useState<string>('');
    useEffect(() => {
        if (invoiceDateTo) {
            filters.addFilter(new InvoiceFilter(InvoiceFilterName.FINAL_DATE, moment(invoiceDateTo).unix().toString()));
        } else {
            filters.removeFilter(InvoiceFilterName.FINAL_DATE);
        }
        setFilters(filters);
    }, [invoiceDateTo]);

    useEffect(() => {
        if (orderNumber) {
            filters.addFilter(new InvoiceFilter(InvoiceFilterName.ORDER_NUMBER, orderNumber));
        } else {
            filters.removeFilter(InvoiceFilterName.ORDER_NUMBER);
        }
        setFilters(filters);
    }, [orderNumber]);

    const handleSubmit: FormEventHandler = (event: FormEvent) => {
        event.preventDefault();
        props.onApplyFilters(filters);
    };
    const handleReset: FormEventHandler = () => {
        [
            InvoiceFilterName.START_DATE,
            InvoiceFilterName.FINAL_DATE,
            InvoiceFilterName.ORDER_NUMBER,
        ].forEach((filterName: string) => {
            filters.removeFilter(filterName);
        });
        setInvoiceDateFrom('');
        setInvoiceDateTo('');
        setOrderNumber('');
    };

    return (
        <Offcanvas
            show={props.show}
            placement="end"
            className="offcanvas"
            onHide={(): void => {
                props.onHide();
            }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t(FILTERS)}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <form id="filter-form" onSubmit={handleSubmit}>
                    <section className="filter invoice-filter">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="order-date-from-filter-input" className="form-label">
                                    {t(ORDER_FILTER_LABEL_DATE_FROM)}
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="order-date-from-filter-input"
                                    value={invoiceDateFrom}
                                    onChange={(e): void => {
                                        setInvoiceDateFrom(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="order-date-to-filter-input" className="form-label">
                                    {t(ORDER_FILTER_LABEL_DATE_TO)}
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="order-date-to-filter-input"
                                    value={invoiceDateTo}
                                    onChange={(e): void => {
                                        setInvoiceDateTo(e.target.value || '');
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                    <section className="filter invoice-filter">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="order-number-filter-input" className="form-label">
                                    {t('ORDER_NUMBER')}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="order-number-filter-input"
                                    value={orderNumber}
                                    onChange={(e): void => {
                                        setOrderNumber(e.target.value || '');
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                </form>
            </Offcanvas.Body>
            <div className="filter-footer">
                <button
                    type="submit"
                    className="btn btn-link text-decoration-none"
                    onClick={handleReset}
                >
                    {t(REMOVE_FILTERS)}
                </button>
                <button type="submit" className="btn btn-primary" data-bs-dismiss="offcanvas" form="filter-form">
                    {t(APPLY_FILTERS)}
                </button>
            </div>
        </Offcanvas>
    );

};
