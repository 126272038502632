export interface RequestAccessFormData {
    companyName: string;
    email: string;
    accessType: string;
    origin: string;
}

export enum RequestAccessFormDataName {
    EMAIL = 'email',
    COMPANY_NAME = 'companyName',
    ACCESS_TYPE = 'accessType',
    ORIGIN = 'origin'
}
