export const TR_CUATROOCHENTA = 'TR_CUATROOCHENTA';
export const WELCOME = 'WELCOME';
export const DESCRIPTION_LOGIN = 'DESCRIPTION_LOGIN';
export const LOGIN = 'LOGIN';
export const LOGIN_SENTENCE = 'LOGIN_SENTENCE';
export const CORREO = 'CORREO';
export const PASSWORD = 'PASSWORD';
export const PASSWORD_FORGOTTEN = 'PASSWORD_FORGOTTEN';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_TEXT = 'RECOVER_PASSWORD_TEXT';
export const SEND = 'SEND';
export const ERROR_FORM = 'ERROR_FORM';
export const PASSWORD_MESSAGE = 'PASSWORD_MESSAGE';
export const VIEW_ALL = 'VIEW_ALL';
export const TOP_TRENDING_TILES = 'TOP_TRENDING_TILES';
export const OUTLET = 'OUTLET';
export const ADD_TO_CART = 'ADD_TO_CART';
export const LINK_NAVBAR = 'LINK_NAVBAR';
export const LOG_OUT_NAVBAR = 'LOG_OUT_NAVBAR';
export const NOTIFICATIONS_NAVBAR = 'NOTIFICATIONS_NAVBAR';
export const TIMER_NAVBAR = 'TIMER_NAVBAR';
export const WOW_BANNER = 'WOW_BANNER';
export const DESIGN_BANNER = 'DESIGN_BANNER';
export const IN_BANNER = 'IN_BANNER';
export const WE_ALWAYS_GO_BANNER = 'WE_ALWAYS_GO_BANNER';
export const FOR_THE_BANNER = 'FOR_THE_BANNER';
export const CHECK_STOCK_BANNER = 'CHECK_STOCK_BANNER';
export const CHECK_THE_AVAILABLE_BANNER = 'CHECK_THE_AVAILABLE_BANNER';
export const BOOKING_BUY_BANNER = 'BOOKING_BUY_BANNER';
export const MAKE_THE_RESERVATION_BANNER = 'MAKE_THE_RESERVATION_BANNER';
export const SHIPPING_BANNER = 'SHIPPING_BANNER';
export const WE_TAKE_CARE_BANNER = 'WE_TAKE_CARE_BANNER';
export const OUR_COLLECTION_BANNER = 'OUR_COLLECTION_BANNER';
export const FEATURED_COLLECTIONS = 'FEATURED_COLLECTIONS';
export const DISCLAIMER_FOOTER = 'DISCLAIMER_FOOTER';
export const TERMS_CONDITIONS_FOOTER = 'TERMS_CONDITIONS_FOOTER';
export const PRIVACY_POLICY_FOOTER = 'PRIVACY_POLICY_FOOTER';
export const WE_CARE_ABOUT_FOOTER = 'WE_CARE_ABOUT_FOOTER';
export const CONTACT_US_FOOTER = 'CONTACT_US_FOOTER';
export const CONTACT_FOOTER = 'CONTACT_FOOTER';
export const ALL_RIGHTS_FOOTER = 'ALL_RIGHTS_FOOTER';
export const FACEBOOK_FOOTER = 'FACEBOOK_FOOTER';
export const LINKEDIN_FOOTER = 'LINKEDIN_FOOTER';
export const INSTAGRAM_FOOTER = 'INSTAGRAM_FOOTER';
export const PINTEREST_FOOTER = 'PINTEREST_FOOTER';
export const ENGLISH_FOOTER = 'ENGLISH_FOOTER';
export const SPANISH_FOOTER = 'SPANISH_FOOTER';
export const FRANCES_FOOTER = 'FRANCES_FOOTER';
export const DEUTSCHE_FOOTER = 'DEUTSCHE_FOOTER';
export const ITALIANO_FOOTER = 'ITALIANO_FOOTER';
export const STOCK_AVAILABLE = 'STOCK_AVAILABLE';
export const PRODUCT_SIZE = 'PRODUCT_SIZE';
export const COLOR = 'COLOR';
export const GAUGE = 'GAUGE';
export const MATERIAL = 'MATERIAL';
export const REFERENCE = 'REFERENCE';
export const ADD = 'ADD';
export const DELETE_LINE_CART_QUESTION = 'DELETE_LINE_CART_QUESTION';
export const ADDING = 'ADDING';
export const CANCEL = 'CANCEL';
export const CONTINUE = 'CONTINUE';
export const QUANTITY = 'QUANTITY';
export const NAME = 'NAME';
export const CHECK_CALIBER_TITLE = 'CHECK_CALIBER_TITLE';
export const CHECK_CALIBER_TEXT = 'CHECK_CALIBER_TEXT';
export const LOT_SELECT_TITLE = 'LOT_SELECT_TITLE';
export const LOT_SELECT_TEXT = 'LOT_SELECT_TEXT';
export const LOT_REFERENCE = 'LOT_REFERENCE';
export const AVAILABLE_QUANTITY = 'AVAILABLE_QUANTITY';
export const PER_UNIT = 'PER_UNIT';
export const AVAILABLE_LOTS = 'AVAILABLE_LOTS';
export const DOWNLOADS = 'DOWNLOADS';
export const DATA_SHEET = 'DATA_SHEET';
export const UPDATED = 'UPDATED';
export const INSTRUCTIONS = 'INSTRUCTIONS';
export const MATERIALS = 'MATERIALS';
export const DOWNLOAD = 'DOWNLOAD';
export const SIZE = 'SIZE';
export const ERROR_LOGIN = 'ERROR_LOGIN';
export const COLLECTION = 'COLLECTION';
export const UNIT = 'UNIT';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const REMOVE_FILTERS = 'REMOVE_FILTERS';
export const FILTERS = 'FILTERS';
export const FORMAT = 'FORMAT';
export const COLLECTIONS = 'COLLECTIONS';
export const SELECT = 'SELECT';
export const FROM = 'FROM';
export const TO = 'TO';
export const SHOW_ONLY = 'SHOW_ONLY';
export const FILTER = 'FILTER';
export const PRODUCTS = 'PRODUCTS';
export const NEW_PASSWORD = 'NEW_PASSWORD';
export const PASSWORD_RESET_TITLE = 'PASSWORD_RESET_TITLE';
export const PASSWORD_RESET_TEXT = 'PASSWORD_RESET_TEXT';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED';
export const PASSWORDS_NO_MATCH = 'PASSWORDS_NO_MATCH';
export const PASSWORDS_REPEAT = 'PASSWORDS_REPEAT';
export const PASSWORD_CURRENT = 'PASSWORD_CURRENT';
export const EMAIL_NOT_VALID = 'EMAIL_NOT_VALID';
export const BESTSELLERS = 'BESTSELLERS';
export const SEARCH = 'SEARCH';
export const ORDERS = 'ORDERS';
export const INVOICES = 'INVOICES';
export const ACCOUNT = 'ACCOUNT';
export const AVAILABLE_USA = 'AVAILABLE_USA';
export const REQUEST_ACCESS = 'REQUEST_ACCESS';
export const WOW = 'WOW';
export const EURO = 'EURO';
export const DOLLAR = 'DOLLAR';
export const NO_RESULTS = 'NO_RESULTS';
export const NO_MORE_RESULTS = 'NO_MORE_RESULTS';
export const TRACKING = 'TRACKING';
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';
export const SETTLEMENT_SENTENCE = 'SETTLEMENT_SENTENCE';
export const PRODUCT_LOCATION = 'PRODUCT_LOCATION';
export const NO_MORE_DATA = 'NO_MORE_DATA';
export const FLOOR_TILES = 'FLOOR_TILES';
export const WALL_TILES = 'WALL_TILES';
export const SPECIAL_PIECES = 'SPECIAL_PIECES';
export const DOWNLOAD_HD_IMAGES = 'DOWNLOAD_HD_IMAGES';
export const DOWNLOAD_CATALOG = 'DOWNLOAD_CATALOG';
export const SIZES = 'SIZES';
export const COLORS = 'COLORS';
export const FINISHES = 'FINISHES';
export const SPECIFICATIONS = 'SPECIFICATIONS';
export const TYPES_OF_TILES = 'TYPES_OF_TILES';
export const LOADING = 'LOADING';
export const HOME = 'HOME';
export const SELECT_PASTE = 'SELECT_PASTE';
export const SELECT_COLLECTION = 'SELECT_COLLECTION';
export const BOTH_TILES = 'BOTH_TILES';
export const OPEN_HOURS = 'OPEN_HOURS';
export const COPYRIGHT = 'COPYRIGHT';
export const OPEN_HOURS_VA = 'OPEN_HOURS_VA';
export const OPEN_HOURS_CA = 'OPEN_HOURS_CA';
export const LOCATION_VA = 'LOCATION_VA';
export const LOCATION_CA = 'LOCATION_CA';
export const TEL = 'TEL';
export const IN_STOCK = 'IN_STOCK';
export const NO_STOCK = 'NO_STOCK';
export const ERROR_500 = 'ERROR_500';
export const WELCOME_WOW = 'WELCOME_WOW';
export const DOWN = 'DOWN';
export const LOADS = 'LOADS';
export const SEE_DOWNLOADS = 'SEE_DOWNLOADS';
export const TEXT_DOWNLOADS = 'TEXT_DOWNLOADS';
export const REF = 'REF';
export const WEIGHT_UNIT = 'WEIGHT_UNIT';
export const CM_UNIT = 'CM_UNIT';
export const M2_UNIT = 'M2_UNIT';
export const BOX_UNIT = 'BOX_UNIT';
export const PALLET_UNIT = 'PALLET_UNIT';
export const PIECE_UNIT = 'PIECE_UNIT';
export const PER_PIECE = 'PER_PIECE';
export const PER_M2 = 'PER_M2';
export const AREA = 'AREA';
export const WEIGHT = 'WEIGHT';
export const BRANDS = 'BRANDS';
export const POUND_WEIGHT = 'POUND_WEIGHT';
export const FEET2 = 'FEET2';
export const FEET = 'FEET';
export const PER_FEET = 'PER_FEET';
export const TOTAL_PRODUCTS = 'TOTAL_PRODUCTS';
export const TOTAL_BOXES = 'TOTAL_BOXES';
export const TOTAL_WEIGHT = 'TOTAL_WEIGHT';
export const STOCK_TIME_REMAINING = 'STOCK_TIME_REMAINING';
export const TOTAL_AMOUNT = 'TOTAL_AMOUNT';
export const ORDER_TOTAL_AMOUNT_LABEL = 'ORDER_TOTAL_AMOUNT_LABEL';
export const CART_SUMMARY = 'CART_SUMMARY';
export const PROCESS_ORDER = 'PROCESS_ORDER';
export const YOUR_CART = 'YOUR_CART';
export const EMPTY_CART_MESSAGE = 'EMPTY_CART_MESSAGE';
export const UNIT_SEND_TO_CART = 'UNIT_SEND_TO_CART';
export const M2_SEND_TO_CART = 'M2_SEND_TO_CART';
export const CALIFORNIA_LOCATION = 'CALIFORNIA_LOCATION';
export const VIRGINIA_LOCATION = 'VIRGINIA_LOCATION';
export const ERROR_UNITS = 'ERROR_UNITS';
export const CART_ITEM_SELECT_UNIT_TYPE_LABEL = 'CART_ITEM_SELECT_UNIT_TYPE_LABEL';
export const AVAILABLE_STOCK_FROM = 'AVAILABLE_STOCK_FROM';
export const BOXES = 'BOXES';
export const PALLETS = 'PALLETS';
export const UNIT_STRING = 'UNIT_STRING';
export const BOX_UNIT_SEND = 'BOX_UNIT_SEND';
export const PALLET_UNIT_SEND = 'PALLET_UNIT_SEND';
export const UNIT_PRICE = 'UNIT_PRICE';
export const DISCOUNT = 'DISCOUNT';
export const NO_AVAILABLE_STOCK_ADD_CART = 'NO_AVAILABLE_STOCK_ADD_CART';
export const CART_ADD_WARNING_BIG_RESERVE = 'CART_ADD_WARNING_BIG_RESERVE';
export const LOGIN_REQUEST_ACCESS_CONTACT_ADMIN = 'LOGIN_REQUEST_ACCESS_CONTACT_ADMIN';
export const LOGIN_REQUEST_ACCESS_MODAL_TITLE = 'LOGIN_REQUEST_ACCESS_MODAL_TITLE';
export const ORDER_PHASE_PREPARING = 'ORDER_PHASE_PREPARING';
export const ORDER_PHASE_ORDERED = 'ORDER_PHASE_ORDERED';
export const ORDER_PHASE_PREPARED = 'ORDER_PHASE_PREPARED';
export const ORDER_PHASE_INVOICED = 'ORDER_PHASE_INVOICED';
export const ORDER_PHASE_CARRIED = 'ORDER_PHASE_CARRIED';
export const ORDER_FILTER_LABEL_STATUS = 'ORDER_FILTER_LABEL_STATUS';
export const ORDER_PHASE_OPEN = 'ORDER_PHASE_OPEN';
export const ORDER_PHASE_RELEASED = 'ORDER_PHASE_RELEASED';
export const ORDER_FILTER_LABEL_PO = 'ORDER_FILTER_LABEL_PO';
export const INPUT_TYPE_TO_SEARCH = 'INPUT_TYPE_TO_SEARCH';
export const ORDER_FILTER_LABEL_DATE_FROM = 'ORDER_FILTER_LABEL_DATE_FROM';
export const ORDER_FILTER_LABEL_DATE_TO = 'ORDER_FILTER_LABEL_DATE_TO';
export const ORDERS_TABLE_COLUMN_ORDER_NO = 'ORDERS_TABLE_COLUMN_ORDER_NO';
export const ORDERS_TABLE_COLUMN_PO = 'ORDERS_TABLE_COLUMN_PO';
export const ORDERS_TABLE_COLUMN_DATE = 'ORDERS_TABLE_COLUMN_DATE';
export const PIECE_UNIT_SINGLE = 'PIECE_UNIT_SINGLE';
export const ORDERS_TABLE_COLUMN_STATUS = 'ORDERS_TABLE_COLUMN_STATUS';
export const ORDERS_TABLE_COLUMN_SHIPING_ADDRESS = 'ORDERS_TABLE_COLUMN_SHIPING_ADDRESS';
export const ORDERS_TABLE_COLUMN_AMOUNT = 'ORDERS_TABLE_COLUMN_AMOUNT';
export const ORDERS_VIEW_ORDER_DETAILS = 'ORDERS_VIEW_ORDER_DETAILS';
export const PALLETS_SINGLE = 'PALLETS_SINGLE';
export const SUMMARY = 'SUMMARY';
export const IMPORTANT = 'IMPORTANT';
export const BANNER_OUT_STOCK = 'BANNER_OUT_STOCK';
export const BANNER_OUT_STOCK_SUB = 'BANNER_OUT_STOCK_SUB';
export const PRODUCTION_ORDER = 'PRODUCTION_ORDER';
export const PICK_UP_STOCK = 'PICK_UP_STOCK';
export const PICK_UP_SAME_TIME = 'PICK_UP_SAME_TIME';
export const NO_STOCK_NO_MANUFACTURE = 'NO_STOCK_NO_MANUFACTURE';
export const DELIVERY = 'DELIVERY';
export const SHIPPING_METHOD = 'SHIPPING_METHOD';
export const TENCER = 'TENCER';
export const CARE_MYSELF = 'CARE_MYSELF';
export const DELIVERY_ADDRESS = 'DELIVERY_ADDRESS';
export const PICK_AT_ONCE = 'PICK_AT_ONCE';
export const YES = 'YES';
export const NO = 'NO';
export const PICK_UP_DATE = 'PICK_UP_DATE';
export const DATE = 'DATE';
export const TIME = 'TIME';
export const ESTIMATED_DATE = 'ESTIMATED_DATE';
export const PICK_UP_PRODUCTS_STOCK = 'PICK_UP_PRODUCTS_STOCK';
export const PICK_UP_PRODUCTS_MANUFACTURE = 'PICK_UP_PRODUCTS_MANUFACTURE';
export const VALIDATE_CART = 'VALIDATE_CART';
export const SELECT_ANOTHER = 'SELECT_ANOTHER';
export const EDIT = 'EDIT';
export const SELECT_DIFFERENT_ADDRESS = 'SELECT_DIFFERENT_ADDRESS';
export const SHIPPING_COSTS = 'SHIPPING_COSTS';
export const STIMATED_PRODUCTION_DATE = 'STIMATED_PRODUCTION_DATE';
export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE = 'DELETE';
export const DELETING = 'DELETING';
export const CART_SUMMARY_PRODUCT_LINE_FOR_MANUFACTURING = 'CART_SUMMARY_PRODUCT_LINE_FOR_MANUFACTURING';
export const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';
export const ACCEPT = 'ACCEPT';
export const DOWNLOAD_PROFORMA = 'DOWNLOAD_PROFORMA';
export const CART_COMBITATION_CONFLICTS_TITLE = 'CART_COMBITATION_CONFLICTS_TITLE';
export const CART_COMBINATION_CONFLICTS_DESCRIPTION = 'CART_COMBINATION_CONFLICTS_DESCRIPTION';
export const ORDER_DETAIL_TITLE = 'ORDER_DETAIL_TITLE';
export const ORDER_ATTACHED_FILES_LABEL = 'ORDER_ATTACHED_FILES_LABEL';
export const ORDER_ATTACHMENTS_EMPTY = 'ORDER_ATTACHMENTS_EMPTY';
export const ORDER_SUMMARY_TITLE = 'ORDER_SUMMARY_TITLE';
export const ORDER_SUMMARY_TOTAL_PRODUCTS = 'ORDER_SUMMARY_TOTAL_PRODUCTS';
export const ORDER_SUMMARY_TOTAL_WEIGHT = 'ORDER_SUMMARY_TOTAL_WEIGHT';
export const ORDER_SUMMARY_TOTAL_AMOUNT = 'ORDER_SUMMARY_TOTAL_AMOUNT';
export const MY_ACCOUNT = 'MY_ACCOUNT';
export const ORDER_ATTACH_FILE = 'ORDER_ATTACH_FILE';
export const ORDER_ATTACH_FILE_INPUT_LABEL = 'ORDER_ATTACH_FILE_INPUT_LABEL';
export const ERROR_CODE_0 = 'ERROR_CODE_0';
export const ERROR_CODE_10200 = 'ERROR_CODE_10200';
export const ERROR_CODE_10201 = 'ERROR_CODE_10201';
export const SET_PASSWORD_HELP = 'SET_PASSWORD_HELP';
export const ERROR_CODE_10202 = 'ERROR_CODE_10202';
export const ERROR_CODE_10203 = 'ERROR_CODE_10203';
export const ERROR_CODE_10204 = 'ERROR_CODE_10204';
export const ERROR_CODE_10205 = 'ERROR_CODE_10205';
export const MENU_OPTION_MARKETING = 'MENU_OPTION_MARKETING';
export const PACKING_TILE_OTHER = 'PACKING_TILE_OTHER';
export const ORDER_SHIPPING_ADDRESSES = 'ORDER_SHIPPING_ADDRESSES';
export const ORDER_SHIPPING_ADDRESSES_SUBTITLE = 'ORDER_SHIPPING_ADDRESSES_SUBTITLE';
export const ORDER_FILTER_DEFAULT_LABEL = 'ORDER_FILTER_DEFAULT_LABEL';
export const ACCOUNT_CONTACTS_TITLE = 'ACCOUNT_CONTACTS_TITLE';
export const ACCOUNT_CONTACTS_SUBTITLE = 'ACCOUNT_CONTACTS_SUBTITLE';
export const ACCOUNT_SETTINGS_TITLE = 'ACCOUNT_SETTINGS_TITLE';
export const ACCOUNT_SETTINGS_SUBTITLE = 'ACCOUNT_SETTINGS_SUBTITLE';
export const ACCOUNT_FIELD_COMPANY_NAME = 'ACCOUNT_FIELD_COMPANY_NAME';
export const ACCOUNT_FIELD_CIF = 'ACCOUNT_FIELD_CIF';
export const ACCOUNT_FIELD_EMAIL = 'ACCOUNT_FIELD_EMAIL';
export const ACCOUNT_FIELD_PHONE = 'ACCOUNT_FIELD_PHONE';
export const ACCOUNT_ADD_CONTACT = 'ACCOUNT_ADD_CONTACT';
export const ACCOUNT_FIELD_DESCRIPTION = 'ACCOUNT_FIELD_DESCRIPTION';
export const ACCOUNT_NOTIFICATIONS_TITLE = 'ACCOUNT_NOTIFICATIONS_TITLE';
export const ACCOUNT_CONTACT_REMOVE_TITLE = 'ACCOUNT_CONTACT_REMOVE_TITLE';
export const ACCOUNT_CONTACT_REMOVE_CONFIRMATION_BODY = 'ACCOUNT_CONTACT_REMOVE_CONFIRMATION_BODY';
export const WAREHOUSE_SELECTOR_LABEL = 'WAREHOUSE_SELECTOR_LABEL';
export const ORDERS_TABLE_COLUMN_SHIPPING_AGENT_CODE = 'ORDERS_TABLE_COLUMN_SHIPPING_AGENT_CODE';
export const ORDERS_TABLE_COLUMN_PACKAGE_TRACKING_NO = 'ORDERS_TABLE_COLUMN_PACKAGE_TRACKING_NO';
export const OBLIGATORY_FIELD_ERROR = 'OBLIGATORY_FIELD_ERROR';
export const ORDER_STATUS_LABEL_ORDERED = 'ORDER_STATUS_LABEL_ORDERED';
export const ORDER_STATUS_LABEL_IN_PREPARATION = 'ORDER_STATUS_LABEL_IN_PREPARATION';
export const ORDER_STATUS_LABEL_READY = 'ORDER_STATUS_LABEL_READY';
export const ORDER_STATUS_LABEL_CHARGED = 'ORDER_STATUS_LABEL_CHARGED';
export const ORDER_STATUS_LABEL_INVOICED = 'ORDER_STATUS_LABEL_INVOICED';
export const ORDER_LINE_DELIVERY_DATE = 'ORDER_LINE_DELIVERY_DATE';
export const ORDER_LINE_AVAILABILITY_DATE = 'ORDER_LINE_AVAILABILITY_DATE';
export const RESET_PASSWORD_SUCCESSFUL_TITLE = 'RESET_PASSWORD_SUCCESSFUL_TITLE';
export const RESET_PASSWORD_SUCCESSFUL_INFO = 'RESET_PASSWORD_SUCCESSFUL_INFO';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const PRODUCT_INFO_WAREHOUSE_LABEL = 'PRODUCT_INFO_WAREHOUSE_LABEL';
export const COOKIES_CONSENT_TITLE = 'COOKIES_CONSENT_TITLE';
export const COOKIES_CONSENT_BODY = 'COOKIES_CONSENT_BODY';
export const COOKIES_CONSENT_ACCEPT = 'COOKIES_CONSENT_ACCEPT';
export const COOKIES_CONSENT_POLICY_LINK = 'COOKIES_CONSENT_POLICY_LINK';
export const FORM_FIELD_REQUIRED = 'FORM_FIELD_REQUIRED';
export const ORDER_ADD_SHIPPING_ADDRESS = 'ORDER_ADD_SHIPPING_ADDRESS';
export const SHIPPING_ADDRESS_LABEL_COMPANY_NAME = 'SHIPPING_ADDRESS_LABEL_COMPANY_NAME';
export const SHIPPING_ADDRESS_LABEL_CODE = 'SHIPPING_ADDRESS_LABEL_CODE';
export const SHIPPING_ADDRESS_LABEL_DESCRIPTION = 'SHIPPING_ADDRESS_LABEL_DESCRIPTION';
export const SHIPPING_ADDRESS_LABEL_COUNTRY = 'SHIPPING_ADDRESS_LABEL_COUNTRY';
export const SHIPPING_ADDRESS_LABEL_PROVINCE = 'SHIPPING_ADDRESS_LABEL_PROVINCE';
export const SHIPPING_ADDRESS_LABEL_STATE = 'SHIPPING_ADDRESS_LABEL_STATE';
export const SHIPPING_ADDRESS_LABEL_LOCALITY = 'SHIPPING_ADDRESS_LABEL_LOCALITY';
export const SHIPPING_ADDRESS_LABEL_CITY = 'SHIPPING_ADDRESS_LABEL_CITY';
export const SHIPPING_ADDRESS_LABEL_POSTALCODE = 'SHIPPING_ADDRESS_LABEL_POSTALCODE';
export const SHIPPING_ADDRESS_LABEL_ZIPCODE = 'SHIPPING_ADDRESS_LABEL_ZIPCODE';
export const SHIPPING_ADDRESS_LABEL_ADDRESS = 'SHIPPING_ADDRESS_LABEL_ADDRESS';
export const SHIPPING_ADDRESS_LABEL_ADDRESS1 = 'SHIPPING_ADDRESS_LABEL_ADDRESS1';
export const SHIPPING_ADDRESS_LABEL_ADDRESS2 = 'SHIPPING_ADDRESS_LABEL_ADDRESS2';
export const SHIPPING_ADDRESS_LABEL_PHONE = 'SHIPPING_ADDRESS_LABEL_PHONE';
export const SHIPPING_ADDRESS_LABEL_FAX = 'SHIPPING_ADDRESS_LABEL_FAX';
export const LOT = 'LOT';
export const BACK = 'BACK';
export const COMPLETE_ALL_FIELDS = 'COMPLETE_ALL_FIELDS';
export const NO_STOCK_USA_INFO = 'NO_STOCK_USA_INFO';
export const DEFAULT_SHIPPING_ADDRESS_LABEL = 'DEFAULT_SHIPPING_ADDRESS_LABEL';
export const ADDRESS_FINDER = 'ADDRESS_FINDER';
export const CHANGE_SHIPPING_ADDRESS = 'CHANGE_SHIPPING_ADDRESS';
export const SHIPPING_ADDRESS_DELETE_CONFIRM_TITLE = 'SHIPPING_ADDRESS_DELETE_CONFIRM_TITLE';
export const SHIPPING_ADDRESS_DELETE_CONFIRM_BODY = 'SHIPPING_ADDRESS_DELETE_CONFIRM_BODY';
export const SELECT_COUNTRY = 'SELECT_COUNTRY';
export const SELECT_PROVINCE = 'SELECT_PROVINCE';
export const SELECT_STATE = 'SELECT_STATE';
export const SHOW_MORE_RESULTS = 'SHOW_MORE_RESULTS';
export const NO_RESULTS_FOUND = 'NO_RESULTS_FOUND';
export const RESERVED_LABEL = 'RESERVED_LABEL';
export const MANUFACTURING_LABEL = 'MANUFACTURING_LABEL';
export const MANUFACTURED_LABEL = 'MANUFACTURED_LABEL';
export const INVALID_ACCESS_MODAL_INFO = 'INVALID_ACCESS_MODAL_INFO';
export const INVALID_ACCESS_MODAL_TITLE = 'INVALID_ACCESS_MODAL_TITLE';
export const PO_LABEL = 'PO_LABEL';
export const ERROR_CODE_40016 = 'ERROR_CODE_40016';
export const ERROR_CODE_40016_USA = 'ERROR_CODE_40016_USA';
export const ORDER = 'ORDER';
export const ORDER_SHIPPING_ADDRESS = 'ORDER_SHIPPING_ADDRESS';
export const SHIPPING_INSTRUCTIONS = 'SHIPPING_INSTRUCTIONS';
export const ORDER_SHIPPING_METHOD_LABEL = 'ORDER_SHIPPING_METHOD_LABEL';
export const WOW_LABEL = 'WOW_LABEL';
export const WILL_CALL_LABEL = 'WILL_CALL_LABEL';
export const OWN_LOGISTIC_LABEL = 'OWN_LOGISTIC_LABEL';
export const SMALL_PARCEL_LABEL = 'SMALL_PARCEL_LABEL';
export const DELIVERY_SERVICES = 'DELIVERY_SERVICES';
export const SELECT_AN_OPTION = 'SELECT_AN_OPTION';
export const CONSOLIDATED_ORDER = 'CONSOLIDATED_ORDER';
export const TRANSPORT_FORM_LIFTGATE_INSTRUCTIONS = 'TRANSPORT_FORM_LIFTGATE_INSTRUCTIONS';
export const ADD_COMMENT_PLACEHOLDER = 'ADD_COMMENT_PLACEHOLDER';
export const PROVIDE_CARRIER_PLACEHOLDER = 'PROVIDE_CARRIER_PLACEHOLDER';
export const ENGLISH_SHORT_FOOTER = 'ENGLISH_SHORT_FOOTER';
export const SPANISH_SHORT_FOOTER = 'SPANISH_SHORT_FOOTER';
export const FRANCES_SHORT_FOOTER = 'FRANCES_SHORT_FOOTER';
export const DEUTSCHE_SHORT_FOOTER = 'DEUTSCHE_SHORT_FOOTER';
export const ITALIANO_SHORT_FOOTER = 'ITALIANO_SHORT_FOOTER';
export const LIFT_GATE_LABEL = 'LIFT_GATE_LABEL';
export const SUBTOTAL_AMOUNT = 'SUBTOTAL_AMOUNT';
export const TRANSPORT_LABEL = 'TRANSPORT_LABEL';
export const CREDIT_AMOUNT_LABEL = 'CREDIT_AMOUNT_LABEL';
export const OUT_OF_STOCK_WAREHOUSES_TITLE = 'OUT_OF_STOCK_WAREHOUSES_TITLE';
export const OUT_OF_STOCK_WAREHOUSES_INFO = 'OUT_OF_STOCK_WAREHOUSES_INFO';
export const LOADING_ORDERS = 'LOADING_ORDERS';
export const CREATE_LOADING_ORDER = 'CREATE_LOADING_ORDER';
export const LOADING_ORDERS_TABLE_COLUMN_SHIPPING_ADDRESS = 'LOADING_ORDERS_TABLE_COLUMN_SHIPPING_ADDRESS';
export const ORDER_DATE = 'ORDER_DATE';
export const LOADING_ORDERS_TABLE_COLUMN_LOADING_ORDER_NO = 'LOADING_ORDERS_TABLE_COLUMN_LOADING_ORDER_NO';
export const LOADING_ORDERS_TABLE_COLUMN_STATUS = 'LOADING_ORDERS_TABLE_COLUMN_STATUS';
export const LOADING_ORDER_VIEW_ORDER_DETAILS = 'LOADING_ORDER_VIEW_ORDER_DETAILS';
export const DOWNLOAD_PDF = 'DOWNLOAD_PDF';
export const ADD_LOADING_ORDER_SELECT_ADDRESS_INSTRUCTIONS = 'ADD_LOADING_ORDER_SELECT_ADDRESS_INSTRUCTIONS';
export const ADD_LOADING_ORDER_ORDERS_TO_LOAD_INSTRUCTIONS = 'ADD_LOADING_ORDER_ORDERS_TO_LOAD_INSTRUCTIONS';
export const ITEMS_TO_ADD = 'ITEMS_TO_ADD';
export const ITEMS_ADDED = 'ITEMS_ADDED';
export const ORDERS_TO_LOAD_TABLE_COLUMN_ORDER_NO = 'ORDERS_TO_LOAD_TABLE_COLUMN_ORDER_NO';
export const ADD_LOADING_ORDER_SELECT_DATE_INSTRUCTIONS = 'ADD_LOADING_ORDER_SELECT_DATE_INSTRUCTIONS';
export const SELECT_LOADING_DATE = 'SELECT_LOADING_DATE';
export const OK = 'OK';
export const ADD_LOADING_ORDER_SUMMARY_INSTRUCTIONS = 'ADD_LOADING_ORDER_SUMMARY_INSTRUCTIONS';
export const LOADING_ORDER_SUMMARY = 'LOADING_ORDER_SUMMARY';
export const TOTAL_PACKAGES = 'TOTAL_PACKAGES';
export const NO_ITEMS_LOADED_MESSAGE = 'NO_ITEMS_LOADED_MESSAGE';
export const LOADING_ORDER_DETAIL_TITLE = 'LOADING_ORDER_DETAIL_TITLE';
export const LOADING_ORDER_CONFIRMED_STATUS = 'LOADING_ORDER_CONFIRMED_STATUS';
export const LOADING_ORDER_SERVED_STATUS = 'LOADING_ORDER_SERVED_STATUS';
export const LOADING_ORDER_IN_PREPARATION_STATUS = 'LOADING_ORDER_IN_PREPARATION_STATUS';
export const LOADING_ORDER_WITHOUT_LINES_MESSAGE = 'LOADING_ORDER_WITHOUT_LINES_MESSAGE';
export const CREATE = 'CREATE';
export const SHIPPING_ADDRESS_SELECTOR_PLACEHOLDER = 'SHIPPING_ADDRESS_SELECTOR_PLACEHOLDER';
export const SHIPPING_ADDRESS_SELECTOR_SEARCH_PLACEHOLDER = 'SHIPPING_ADDRESS_SELECTOR_SEARCH_PLACEHOLDER';
export const CART_MUST_BE_IN_SAME_WAREHOUSE_ERROR = 'CART_MUST_BE_IN_SAME_WAREHOUSE_ERROR';
export const ERROR_LABEL = 'ERROR_LABEL';
export const SHIPPING_ADDRESS_SHOW_ALL_BUTTON = 'SHIPPING_ADDRESS_SHOW_ALL_BUTTON';
export const SHIPPING_ADDRESS_HIDE_ALL_BUTTON = 'SHIPPING_ADDRESS_HIDE_ALL_BUTTON';
export const CART = 'CART';
export const ERROR_CODE_40017 = 'ERROR_CODE_40017';
export const PACKAGES_PALLETS = 'PACKAGES_PALLETS';
export const LOADING_DATE = 'LOADING_DATE';
export const CART_DISCOUNTS_APPLIED_LABEL = 'CART_DISCOUNTS_APPLIED_LABEL';
export const TRUCK_LABEL = 'TRUCK_LABEL';
export const CONTAINER_LABEL = 'CONTAINER_LABEL';
export const SELECT_LOADING_ORDER_TRANSPORT_TYPE = 'SELECT_LOADING_ORDER_TRANSPORT_TYPE';
export const LOADING_ORDER_TRANSPORT_TYPE = 'LOADING_ORDER_TRANSPORT_TYPE';
export const TOTAL_CART_DISCOUNTS_APPLIED_LABEL = 'TOTAL_CART_DISCOUNTS_APPLIED_LABEL';
export const LOADING_ORDER_TRANSPORT_TYPE_INFO = 'LOADING_ORDER_TRANSPORT_TYPE_INFO';
export const EXCEED_CONTAINER_WEIGHT_ERROR = 'EXCEED_CONTAINER_WEIGHT_ERROR';
export const LOADING_ORDER_ACTUAL_WEIGHT = 'LOADING_ORDER_ACTUAL_WEIGHT';
export const LOADING_ORDER_MAX_WEIGHT = 'LOADING_ORDER_MAX_WEIGHT';
export const CREDIT_MEMO_LABEL = 'CREDIT_MEMO_LABEL';
export const CREDIT_MEMO_APPLIED_LABEL = 'CREDIT_MEMO_APPLIED_LABEL';
export const CREDIT_CARD_PAYMENT_TITLE = 'CREDIT_CARD_PAYMENT_TITLE';
export const CREDIT_CARD_PAYMENT_PAY_BUTTON = 'CREDIT_CARD_PAYMENT_PAY_BUTTON';
export const PAYMENT_TERMS_TITLE = 'PAYMENT_TERMS_TITLE';
export const PAYMENT_TERMS_TEXT = 'PAYMENT_TERMS_TEXT';
export const PAYMENT_TERMS_PRICE_LIMIT_LABEL = 'PAYMENT_TERMS_PRICE_LIMIT_LABEL';
export const PAYMENT_TERMS_PRICE_LIMIT_TEXT = 'PAYMENT_TERMS_PRICE_LIMIT_TEXT';
export const PAYMENT_TERMS_PAYMENT_PERIOD_LABEL = 'PAYMENT_TERMS_PAYMENT_PERIOD_LABEL';
export const PAYMENT_TERMS_PAYMENT_PERIOD_TEXT = 'PAYMENT_TERMS_PAYMENT_PERIOD_TEXT';
export const PAYMENT_TERMS_REVIEW_ORDER = 'PAYMENT_TERMS_REVIEW_ORDER';
export const CONTACT_SUPPORT = 'CONTACT_SUPPORT';
export const PAYMENT_TERMS_LIMIT_EXCEEDED_ERROR = 'PAYMENT_TERMS_LIMIT_EXCEEDED_ERROR';
export const PAYMENT_KO_TITLE = 'PAYMENT_KO_TITLE';
export const PAYMENT_KO_TEXT = 'PAYMENT_KO_TEXT';
export const TRY_AGAIN = 'TRY_AGAIN';
export const PAYMENT_OK_TITLE = 'PAYMENT_OK_TITLE';
export const PAYMENT_OK_TEXT = 'PAYMENT_OK_TEXT';
export const APPLICATION_ACCESS_TYPE = 'APPLICATION_ACCESS_TYPE';
export const PORTAL = 'PORTAL';
export const APPLICATION_ACCESS_TYPE_INQUIRE_LABEL = 'APPLICATION_ACCESS_TYPE_INQUIRE_LABEL';
export const APPLICATION_ACCESS_TYPE_PLACE_ORDERS_LABEL = 'APPLICATION_ACCESS_TYPE_PLACE_ORDERS_LABEL';
export const AGENT = 'AGENT';
export const INVOICES_TABLE_COLUMN_ID = 'INVOICES_TABLE_COLUMN_ID';
export const INVOICES_TABLE_COLUMN_DATE = 'INVOICES_TABLE_COLUMN_DATE';
export const INVOICES_TABLE_COLUMN_ORDER = 'INVOICES_TABLE_COLUMN_ORDER';
export const INVOICES_TABLE_COLUMN_AMOUNT_BRUTE = 'INVOICES_TABLE_COLUMN_AMOUNT_BRUTE';
export const INVOICES_TABLE_COLUMN_AMOUNT_NET = 'INVOICES_TABLE_COLUMN_AMOUNT_NET';
export const INVOICES_TABLE_COLUMN_DISCOUNT = 'INVOICES_TABLE_COLUMN_DISCOUNT';
export const INVOICES_VIEW_INVOICE_DETAILS = 'INVOICES_VIEW_INVOICE_DETAILS';
export const INVOICE_SUMMARY_TITLE = 'INVOICE_SUMMARY_TITLE';
export const INVOICE_SUMMARY_TOTAL_PRODUCTS = 'INVOICE_SUMMARY_TOTAL_PRODUCTS';
export const INVOICE_SUMMARY_SHIPPING_ADDRESS = 'INVOICE_SUMMARY_SHIPPING_ADDRESS';
export const INVOICE_DETAIL_TITILE = 'INVOICE_DETAIL_TITILE';
export const ORDER_NUMBER = 'ORDER_NUMBER';
export const FAQ = 'FAQ';
export const SHOW_ORDER_TRACKING = 'SHOW_ORDER_TRACKING';
export const PICK_HANDLE = 'PICK_HANDLE';
export const YOU_MUST_SELECT_A_SHIPPING_ADDRESS = 'YOU_MUST_SELECT_A_SHIPPING_ADDRESS';
export const SKU = 'SKU';
export const SUMMARY_TOTAL_WEIGHT_SUBTITLE_USA = 'SUMMARY_TOTAL_WEIGHT_SUBTITLE_USA';
