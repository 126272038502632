export enum InvoiceFilterRequestName{
    START_DATE = 'dateStart',
    FINAL_DATE = 'dateEnd',
    ORDER_NUMBER = 'orderNumber',
}

export interface InvoiceFilterRequest {
    request: InvoiceFilterRequestItem []
}

export interface InvoiceFilterRequestItem {
    name: InvoiceFilterRequestName,
    value: string,
}
