import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useCartCheckoutForm, UseCartCheckoutFormInterface } from 'app/cartSummary/application/hooks/useCartCheckoutForm';
import { useIsMounted } from '../../../shared/hooks/useIsMounted';
import { ErpUserId } from '../../../account/domain/value-objects/erp-user-id';
import { TencerApiClientSingleton } from '../../../shared/hooks/TencerApiClientSingleton';
import { GetErpUserRequestDTO } from '../../../account/infrastructure/api/dto/request/get-erp-user-request.DTO';
import { ErpUserMapper } from '../../../account/infrastructure/api/data-mapper/erp-user.mapper';
import { ErpUser } from '../../../account/domain/erp-user';
import { useToken } from '../../../shared/hooks/use-token';
import { CurrentUserContext } from '../../../shared/context/CurrentUserContext';
import CartMapper from '../../../cart/infraestructure/repository/api/data-mapper/CartMapper';
import Cart from '../../../cart/domain/model/Cart';
import { TencerRoutes } from '../../../shared/tencer-routing/TencerRoutes';
import { CountryByProvince } from '../../../orders/model/countryByProvince';
import { useShippingAddressRepository } from '../../../orders/application/hooks/useShippingAddressRepository';

export interface UseProductDetailList {
    countries: CountryByProvince[]
    cart?: Cart|null;
    refreshCart(): void;
    isLoading: boolean;
    error: boolean;
    erpUser:ErpUser | undefined
    erpUserId :ErpUserId | undefined,
    userCredentialId: string | undefined,
    cartCheckoutForm: UseCartCheckoutFormInterface,
}

export function useCartSummaryList(): UseProductDetailList {

    const isMounted = useIsMounted();
    const UseToken = useToken();
    const navigate = useNavigate();
    const userContext = useContext(CurrentUserContext);
    const apiClient = TencerApiClientSingleton.getInstance();
    const shippingAddressRepository = useShippingAddressRepository();
    const [cart, setCart] = React.useState<Cart|null>();
    const cartCheckoutForm = useCartCheckoutForm(cart);
    const [countries, setCountries] = useState<CountryByProvince[]>([]);
    const [erpUser, setErpUser] = useState<ErpUser>();
    const [error, setError] = React.useState<boolean>(false);

    const userCredentialId = UseToken.username;
    const erpUserId = erpUser ? erpUser.id : undefined;

    const refreshCart = (): void => {
        const APIClient = TencerApiClientSingleton.getInstance();
        APIClient.getMyCart()
            .then((response) => {
                if (!isMounted) {
                    return;
                }
                const mappedCart = CartMapper.fromResponse(response);
                setCart(mappedCart);
                if (mappedCart) {
                    userContext?.setCartItems(mappedCart.items.length);
                    userContext?.setExpirationDate(new Date(response.free_stock_at).toISOString());
                } else {
                    userContext?.setCartItems(undefined);
                    userContext?.setExpirationDate(null);
                    navigate(TencerRoutes.HOME);
                }
            })
            .catch(() => {
                setError(true);
            });
    };

    const mutationGetUser = useMutation(
        async (getUserRequestDTO: GetErpUserRequestDTO) => apiClient.getErpUser(getUserRequestDTO),
        {
            onSuccess: (data) => {
                setErpUser(ErpUserMapper.toDomain(data));
            },
        },
    );

    const getErpUser = (): void => {
        if (!UseToken.erpCode) {
            return;
        }
        mutationGetUser.mutate({
            erpUserId: UseToken.erpCode,
        });
    };

    const getUserCountries = (): void => {
        const request: void = undefined;
        shippingAddressRepository.getUserCountriesMutation.mutate(request, {
            onSuccess: (data) => {
                setCountries(data);
            },
        });
    };

    React.useEffect(() => {
        cartCheckoutForm.form.setValue('clientRef', cart?.clientInternalRef ?? null);
    }, [cart]);

    React.useEffect(() => {
        getUserCountries();
        refreshCart();
        getErpUser();
    }, []);

    return {
        cart,
        countries,
        refreshCart,
        isLoading: !cart && !error,
        error,
        erpUser,
        erpUserId,
        userCredentialId,
        cartCheckoutForm,
    };
}
