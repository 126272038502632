import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, UseFormReturn } from 'react-hook-form';
import { CourierServiceId } from 'app/shared/domain/value-objects/CourierServiceId';
import { useTranslation } from 'react-i18next';
import { CurrentUserContext, UserContextType } from '../../../shared/context/CurrentUserContext';
import { useIsMounted } from '../../../shared/hooks/useIsMounted';
import { useCartSummaryList, UseProductDetailList } from './useCartSummaryList';
import { useTransport, UseTransportResponse } from './useTransport';
import { useOrigin } from '../../../shared/hooks/useOrigin';
import { useToken, useTokenResponse } from '../../../shared/hooks/use-token';
import { Origin } from '../../../shared/domain/value-objects/Origin';
import Cart from '../../../cart/domain/model/Cart';
import { TencerRoutes } from '../../../shared/tencer-routing/TencerRoutes';
import { TransportFormName } from '../../domain/entity/TransportForm';
import { useCartSummaryRepository } from './useCartSummaryRepository';
import { ETransportType, TransportType } from '../../../orders/model/value-objects/transport-type';
import {
    CreditMemoFieldName,
    CreditMemoFormRegisters,
} from '../../../orders/application/components/CreditMemoForm/CreditMemoForm';
import { PaymentMethod } from '../../../orders/model/value-objects/PaymentMethod';
import { Price } from '../../../orders/model/value-objects/Price';
import { ICreditMemo } from '../../../orders/model/CreditMemo';
import Transport from '../../../orders/model/transport';
import { CourierId } from '../../../shared/domain/value-objects/CourierId';
import { FORM_FIELD_REQUIRED } from '../../../../i18n/translations/TR';
import { DependencyInjectionContext } from '../../../shared/context/DependecyInjectionContext';
import { ShippingAddress } from '../../../orders/model/ShippingAddress';
import { PaymentTerms } from '../../../orders/model/value-objects/PaymentTerms';

export interface UseCartSummaryResponse {
    pageName: string;
    watchLiftGate: boolean;
    exceedTermsLimit: boolean;
    watchConsolidated: boolean;
    origin: Origin | undefined;
    state: UserContextType | null;
    cart: Cart | null | undefined;
    tokenPayload: useTokenResponse;
    cartSummaryScreenIsBusy: boolean;
    watchResponsible: ETransportType;
    confirmCart: () => Promise<void>;
    cartSummary: UseProductDetailList;
    showShippingAddressError: boolean;
    UseTransport: UseTransportResponse;
    creditMemos: Promise<ICreditMemo[]>;
    handleHideShippingAddressError: () => void;
    shippingAddressSelected: ShippingAddress | undefined
    creditMemoForm: UseFormReturn<CreditMemoFormRegisters>;
    afterChangeShippingAddressDefault: (shippingAddress: ShippingAddress) => void;
}

export const useCartSummary = (): UseCartSummaryResponse => {
    const dic = useContext(DependencyInjectionContext);
    const isMounted = useIsMounted();
    const { t } = useTranslation();
    const origin = useOrigin();
    const navigate = useNavigate();
    const tokenPayload = useToken();
    const cartSummary = useCartSummaryList();
    const state = React.useContext(CurrentUserContext);
    const repository = useCartSummaryRepository();

    const pageName = 'cart-summary';
    const cart = cartSummary?.cart;
    const [creditMemos, setCreditMemos] =
        useState<Promise<ICreditMemo[]>>(Promise.resolve([]));
    const [exceedTermsLimit, setExceedTermsLimit] = useState(false);
    const [shippingAddressSelected, setShippingAddressSelected] = useState<ShippingAddress | undefined>();
    const [showShippingAddressError, setShowShippingAddressError] = useState(false);
    const [paymentTerms, setPaymentTerms] = useState<PaymentTerms | undefined>();

    const creditMemoForm = useForm<CreditMemoFormRegisters>({
        defaultValues: {
            creditMemo: '',
        },
    });

    const UseTransport = useTransport();
    const watchCourier = UseTransport.UseTransportForm.watch(TransportFormName.COURIER);
    const watchLiftGate = UseTransport.UseTransportForm.watch(TransportFormName.LIFT_GATE);
    const watchResponsible = UseTransport.UseTransportForm.watch(TransportFormName.RESPONSIBLE);
    const watchConsolidated = UseTransport.UseTransportForm.watch(TransportFormName.CONSOLIDATED);

    const watchCreditMemo = creditMemoForm.watch(CreditMemoFieldName.CREDIT_MEMO);

    const handleShowShippingAddressError = (): void => {
        setShowShippingAddressError(true);
    };

    const handleHideShippingAddressError = (): void => {
        setShowShippingAddressError(false);
    };

    const checkoutCart = async (): Promise<void> => {
        if (!cart) {
            return Promise.resolve();
        }
        await dic.cartRepository.checkout();
        state?.setExpirationDate(null);
        state?.setCartItems(undefined);
        navigate(TencerRoutes.ORDERS);
        return Promise.resolve();
    };

    const handleCheckoutForEU = (): Promise<void> => {
        if (!shippingAddressSelected) {
            handleShowShippingAddressError();
            return Promise.resolve();
        }
        return checkoutCart();
    };

    const handlePOError = (): Promise<void> => {
        cartSummary.cartCheckoutForm.form.setError('clientRef', {
            type: 'required',
            message: t(FORM_FIELD_REQUIRED),
        });
        cartSummary.cartCheckoutForm.form.setFocus('clientRef');
        return Promise.resolve();
    };

    const validatePO = (): boolean => {
        const purchaseOrder = cartSummary.cartCheckoutForm.form.getValues('clientRef');
        if (!purchaseOrder) {
            handlePOError();
            return false;
        }
        return true;
    };

    const validateShippingAddress = (): boolean => {
        if (!shippingAddressSelected && watchResponsible !== ETransportType.WILL_CALL) {
            handleShowShippingAddressError();
            return false;
        }
        return true;
    };

    const handlePaymentNavigation = (paymentMethod: PaymentMethod): void => {
        switch (paymentMethod) {
            case PaymentMethod.CREDIT_CARD:
                navigate(TencerRoutes.CART_PAYMENT_CARD);
                break;
            case PaymentMethod.TERMS:
                navigate(TencerRoutes.CART_PAYMENT_TERMS);
                break;
            default:
                navigate(TencerRoutes.CART_PAYMENT_CARD);
                break;
        }
    };

    const confirmCart = async (): Promise<void> => {
        if (origin !== Origin.USA) {
            await handleCheckoutForEU();
            return Promise.resolve();
        }

        if (!cart) {
            return Promise.resolve();
        }

        if (!validatePO()) {
            return Promise.resolve();
        }

        if (!validateShippingAddress()) {
            return Promise.resolve();
        }

        cartSummary.cartCheckoutForm.form.clearErrors();

        await UseTransport.UseTransportForm.handleSubmit(async (data) => {
            const courier = UseTransport.findCourier(new CourierId(data.courier));
            const courierService = UseTransport.findCourierService(new CourierServiceId(data.courierService));

            const pickHandlePrice = !watchConsolidated && cartSummary.erpUser?.pickHandle ?
                UseTransport.pickHandlePrice :
                undefined;

            cart.transport = new Transport(
                new TransportType(data.responsible),
                data.consolidated,
                data.comments,
                courier,
                courierService,
                data.liftGate,
                undefined,
                pickHandlePrice,
            );

            await creditMemos.then((elements: ICreditMemo[]) => {
                if (elements.length > 0) {
                    cart.creditMemo = elements.find((creditMemo) => creditMemo.id === watchCreditMemo) ?? undefined;
                }
            });

            try {
                await dic.cartRepository.confirm(cart);
            } catch (error) {
                navigate(TencerRoutes.CART_PAYMENT_KO);
                return Promise.reject(error);
            }

            const paymentMethod = tokenPayload.paymentMethod;

            handlePaymentNavigation(paymentMethod as PaymentMethod);

            return Promise.resolve();
        })();

        return Promise.resolve();
    };

    const cartSummaryScreenIsBusy =
        cartSummary.isLoading ||
        repository.mutationCheckout.isLoading;

    const afterChangeShippingAddressDefault = (shippingAddress: ShippingAddress): void => {
        if (origin === Origin.USA) {
            if (cart?.transport) {
                cart.transport.courier = undefined;
            }
            UseTransport.afterChangeShippingAddressDefault();
        }
        setShippingAddressSelected(shippingAddress);
    };

    const updateCartTransportAfterCourierSelection = (): void => {
        if (!cart) {
            return;
        }

        if (!cart?.transport) {
            cart.transport = new Transport(
                new TransportType(watchResponsible),
                watchConsolidated,
                '',
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
            );
        }

        if (watchCourier) {
            cart.transport.courier = UseTransport.findCourier(new CourierId(watchCourier));
        }

        if (!watchCourier) {
            cart.transport.courier = undefined;
        }
    };

    const handleTransportPriceOnPickHandleChange = (): void => {
        if (!cart || !UseTransport.pickHandlePrice) {
            return;
        }

        if (!cart?.transport) {
            cart.transport = new Transport(
                new TransportType(watchResponsible),
                watchConsolidated,
                '',
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
            );
        }

        if (!watchConsolidated && cartSummary.erpUser?.pickHandle) {
            cart.transport.pickHandlePrice = UseTransport.pickHandlePrice;
        }

        if (watchConsolidated || !cartSummary.erpUser?.pickHandle) {
            cart.transport.pickHandlePrice = undefined;
        }
    };

    useEffect(() => {
        const token = useToken();
        const paymentMethod = token.paymentMethod;

        if (!paymentTerms) {
            dic.erpUserRepository.getPaymentTerms().then(setPaymentTerms);
        }
        setCreditMemos(dic.creditMemoRepository.getAvailableCreditMemos());

        if (paymentMethod === PaymentMethod.TERMS) {
            const termsCreditAvailable = paymentTerms?.credit_available ?? Price.zero();
            const termsCreditLimit = paymentTerms?.credit_limit ?? Price.zero();

            if (termsCreditAvailable.add(cart?.totalAmount ?? Price.zero()).amount > termsCreditLimit.amount) {
                setExceedTermsLimit(true);
            }
        }

    }, [paymentTerms, cart]);

    useEffect((): void => {
        if (!isMounted) {
            return;
        }

        if (cart?.freeStockAt) {
            state?.setExpirationDate(cart.freeStockAt.toISOString());
        }

        if (cart?.creditMemo) {
            creditMemoForm.setValue('creditMemo', cart.creditMemo.id);
        }

    }, [cart]);

    useEffect(() => {
        creditMemos.then((elements: ICreditMemo[]) => {
            const selected = elements.find((element: ICreditMemo) => {
                return element.id === watchCreditMemo;
            });
            if (cartSummary.cart) {
                cartSummary.cart.creditMemo = selected;
            }
        });
    }, [watchCreditMemo]);

    useEffect(() => {
        if (cartSummary.erpUser) {
            UseTransport.UseTransportForm.setValue(
                TransportFormName.ALLOW_QUOTE_TRANSPORT,
                // @ts-ignore
                cartSummary.erpUser.allowQuoteTransport,
            );
        }
    }, [cartSummary.erpUser]);

    useEffect((): void => {
        updateCartTransportAfterCourierSelection();
    }, [watchCourier, UseTransport.liftGateCouriers, UseTransport.noLiftGateCouriers, UseTransport.watchLiftGate]);

    useEffect(() => {
        handleTransportPriceOnPickHandleChange();
    }, [cart, UseTransport.pickHandlePrice, watchConsolidated, cartSummary.erpUser]);

    return {
        cart,
        state,
        origin,
        pageName,
        creditMemos,
        confirmCart,
        cartSummary,
        UseTransport,
        tokenPayload,
        watchLiftGate,
        creditMemoForm,
        watchResponsible,
        exceedTermsLimit,
        watchConsolidated,
        cartSummaryScreenIsBusy,
        shippingAddressSelected,
        showShippingAddressError,
        handleHideShippingAddressError,
        afterChangeShippingAddressDefault,
    };
};
