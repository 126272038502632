import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AddShippingAddressForm } from '../../../../../../../orders/application/components/ShippingAddressForm/AddShippingAddressForm';
import { EditShippingAddressForm } from '../../../../../../../orders/application/components/ShippingAddressForm/EditShippingAddressForm';
import { CountryByProvince } from '../../../../../../../orders/model/countryByProvince';
import { ShippingAddress } from '../../../../../../../orders/model/ShippingAddress';
import { ORDER_SHIPPING_ADDRESSES } from '../../../../../../../../i18n/translations/TR';

interface Props {
    onClose: () => void
    show: boolean
    countries: CountryByProvince[]
    address?: ShippingAddress
    onSuccess: (shippingAddress: ShippingAddress) => void
    mode: ShippingAddressModalMode
}

export enum ShippingAddressModalMode {
    EDIT,
    ADD,
}

export const ShippingAddressModal = (
    props :Props,
):React.ReactElement => {
    const { t } = useTranslation();
    const [formErrorMessage, setFormErrorMessage] = useState<string>();
    const handleFormErrorMessage = (error: undefined | string): void => {
        setFormErrorMessage(error);
    };
    const renderForm = (): React.ReactElement => {
        switch (props.mode) {
            case ShippingAddressModalMode.ADD:
                return (
                    <AddShippingAddressForm
                        countries={props.countries}
                        onCancel={props.onClose}
                        onSuccess={props.onSuccess}
                        formErrorMessage={formErrorMessage}
                        handleFormErrorMessage={handleFormErrorMessage}
                    />
                );
            case ShippingAddressModalMode.EDIT:
            default:
                if (!props.address) {
                    return <div />;
                }
                return (
                    <EditShippingAddressForm
                        countries={props.countries}
                        onCancel={props.onClose}
                        address={props.address}
                        onSuccess={props.onSuccess}
                        formErrorMessage={formErrorMessage}
                        handleFormErrorMessage={handleFormErrorMessage}
                    />
                );
        }
    };

    return (
        <Modal
            show={props.show}
            className="modal fade"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header>
                <h5 className="modal-title">{t(ORDER_SHIPPING_ADDRESSES)}</h5>
            </Modal.Header>
            <Modal.Body className="">
                {props.show ? renderForm() : null}
            </Modal.Body>
        </Modal>
    );
};

ShippingAddressModal.defaultProps = {
    address: undefined,
};
