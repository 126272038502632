export type LanguageCode = 'es' | 'en' | 'fr' | 'it' | 'de';
export class Language {

    private _language: LanguageCode;

    public constructor(value: LanguageCode) {
        this.ensureIsNotUndefined(value);
        this._language = value;
    }

    private ensureIsNotUndefined(value: string | null): void {
        if (value === undefined || value === '') {
            throw new Error('Language cannot be undefined ');
        }
    }

    public get language(): LanguageCode {
        return this._language;
    }

}
