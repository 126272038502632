import { CourierServiceId } from './value-objects/CourierServiceId';

export class CourierService {

    private _id: CourierServiceId;
    private _name: string;

    public constructor(
        id: CourierServiceId,
        name: string,
    ) {
        this._id = id;
        this._name = name;
    }

    get id(): CourierServiceId {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

}
