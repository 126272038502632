import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    PAYMENT_TERMS_PAYMENT_PERIOD_LABEL,
    PAYMENT_TERMS_PAYMENT_PERIOD_TEXT,
    PAYMENT_TERMS_PRICE_LIMIT_LABEL,
    PAYMENT_TERMS_PRICE_LIMIT_TEXT,
} from '../../../../../i18n/translations/TR';
import { Currency } from '../../../../shared/Currency';

interface Props {
    amount: number;
    period: string;
}

export const CartPaymentTerms = (props: Props): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <ul className="cart-payment-terms">
            <li className="cart-payment-terms-limit">
                <span className="label">
                    {`${t(PAYMENT_TERMS_PRICE_LIMIT_LABEL)}:`}
                </span>
                <span className="text">
                    {t(PAYMENT_TERMS_PRICE_LIMIT_TEXT)}
                    <Currency
                        value={props.amount}
                        className="cart-payment-terms-limit-price"
                    />
                </span>
            </li>
            <li className="cart-payment-terms-period">
                <span className="label">
                    {`${t(PAYMENT_TERMS_PAYMENT_PERIOD_LABEL)}:`}
                </span>
                <span
                    className="text"
                    dangerouslySetInnerHTML={{
                        __html: t(PAYMENT_TERMS_PAYMENT_PERIOD_TEXT, {
                            period: props.period,
                        }),
                    }}
                />
            </li>
        </ul>
    );
};
