import { ApiProduct } from 'app/home/infrastructure/api/response/ApiProduct';
import { ProductFilter } from 'app/orders/model/ProductFilter';
import { ProductFilterCollection } from 'app/orders/model/ProductFilterCollection';
import { useContext, useEffect, useState } from 'react';
import { TencerApiClientSingleton } from '../../shared/hooks/TencerApiClientSingleton';
import { DependencyInjectionContext } from '../../shared/context/DependecyInjectionContext';

export interface Response {
    applyFilters: (filters: ProductFilterCollection) => void;
    removeFilter(key: string): void;
    resetFilters: () => void;
    addFilter: (filter: ProductFilter) => void;
    getFilter: (key: string) => ProductFilter|null;
    getAllFilters: () => {[key: string]: ProductFilter};
    refreshFilters: () => void;
    filters: ProductFilterCollection,
    products: ApiProduct[],
    fetchProducts: (filterCollection: ProductFilterCollection, search: string, page: number) => Promise<void>;
    isLoading: boolean,
    hasMore: boolean,
    fetchMore: () => Promise<void>,
    showFilters: boolean,
    handleCloseFilters: () => void,
    handleShowFilters: () => void,
}

export interface FilterValue {
    value: string|number|boolean|undefined,
    label: string
}

interface Props {
    search: string;
    setSearch: (text: string) => void;
}

export const useProductCatalogController = (props: Props): Response => {
    const resultsPerPage = 20;
    const dic = useContext(DependencyInjectionContext);
    const APIClient = TencerApiClientSingleton.getInstance();
    const [filters, setFilters] = useState<ProductFilterCollection>(new ProductFilterCollection([]));
    const [products, setProducts] = useState<ApiProduct[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState(true);
    const [actualPage, setActualPage] = useState(1);

    const [showFilters, setShowFilters] = useState(false);

    const handleShowFilters = (): void => setShowFilters(true);
    const handleCloseFilters = (): void => setShowFilters(false);

    const fetchProducts = async (
        filtersCollection: ProductFilterCollection,
        search: string,
        page: number,
    ): Promise<void> => {
        let result = [];
        if (page === 1) {
            setIsLoading(true);
        } else {
            setHasMore(true);
        }

        if (search !== '') {

            result = await APIClient.getCatalogSearch(
                props.search,
                page,
                resultsPerPage,
            );
        } else {
            result = await APIClient.getPackingCatalog(
                [],
                resultsPerPage,
                page,
                filtersCollection.getValue('collection')?.value.toString(),
                filtersCollection.getValue('material')?.value.toString(),
                filtersCollection.getValue('brand')?.value.toString(),
                filtersCollection.getValue('stock') ? true : undefined,
            );
        }

        setHasMore(result.length >= resultsPerPage);
        setActualPage(page);
        const productsMapped = result.map<ApiProduct>((product: ApiProduct) => product);

        if (page === 1) {
            setProducts(productsMapped);
        } else {
            setProducts([...products, ...productsMapped]);
        }

        setIsLoading(false);
    };
    const refreshFilters = async (filtersToApply?: ProductFilterCollection, search?: string): Promise<void> => {
        setHasMore(true);
        setActualPage(1);
        setShowFilters(false);
        let resultFilters = null;
        if (filtersToApply) {
            resultFilters = filtersToApply;
        } else {
            resultFilters = await dic.productFilterRepository.findAll();
        }
        setFilters(resultFilters);
        await fetchProducts(resultFilters, search || '', 1);
    };

    const resetFilters = async (): Promise<void> => {
        await dic.productFilterRepository.reset().then(() => {
            refreshFilters();
        });
    };

    const addFilter = (filter: ProductFilter):void => {
        dic.productFilterRepository.add(filter).then(() => {
            refreshFilters();
        });
    };

    const applyFilters = async (filtersCollection: ProductFilterCollection):Promise<void> => {
        await dic.productFilterRepository.reset();
        setShowFilters(false);
        filtersCollection.toArray().forEach(async (filter: ProductFilter) => {
            await dic.productFilterRepository.add(filter);
        });
        if (props.search === '') {
            fetchProducts(filtersCollection, props.search, 1);
            setFilters(filtersCollection);
        } else {
            props.setSearch('');
        }
        return Promise.resolve();
    };

    const getFilter = (key: string): ProductFilter | null => {
        return filters.getValue(key) || null;
    };

    const getAllFilters = (): {[key: string]: ProductFilter} => {
        return filters.filters;
    };

    const removeFilter = async (filterName: string):Promise<void> => {
        await dic.productFilterRepository.remove(filterName);
        refreshFilters();
    };

    const fetchMore = async (): Promise<void> => {
        const nextPage = actualPage + 1;

        if (hasMore) {
            await fetchProducts(filters, props.search, nextPage);

        }

        return Promise.resolve();
    };

    useEffect(() => {
        if (props.search !== '') {
            const newFilters = new ProductFilterCollection([]);
            dic.productFilterRepository.reset().then(() => {
                setFilters(newFilters);
                fetchProducts(newFilters, props.search, 1);
            });
        } else {
            fetchProducts(filters, props.search, 1);
        }
    }, [props.search]);

    return {
        addFilter,
        getFilter,
        getAllFilters,
        resetFilters,
        refreshFilters,
        removeFilter,
        filters,
        applyFilters,
        products,
        fetchProducts,
        isLoading,
        hasMore,
        fetchMore,
        showFilters,
        handleCloseFilters,
        handleShowFilters,
    };
};
