import React, { ReactElement } from 'react';
import { Swiper } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/components/pagination/pagination.scss';
import { useTranslation } from 'react-i18next';
import { ServerErrorMessage } from '../../../shared/ServerErrorMessage';
import { SETTLEMENT_SENTENCE, VIEW_ALL } from '../../../../i18n/translations/TR';
import { Spinner } from '../../../shared/Spinner';

SwiperCore.use([Pagination]);

interface CarouselProps {
    title: string
    error: boolean
    hasItems: boolean
    isLoading: boolean
    showAdvice: boolean
    onViewAll: () => void
    children: ReactElement
}

const swiperBreakpoints = {
    640: {
        width: 640,
        slidesPerView: 1,
    },
    768: {
        width: 768,
        slidesPerView: 2,
    },
    1200: {
        width: 1200,
        slidesPerView: 3,
    },
};

const pagination = {
    el: '.swiper-pagination',
    clickable: true,
};

export const Carousel = (props: CarouselProps): ReactElement | null => {
    const { t } = useTranslation();
    return (
        <div>
            {!props.isLoading ?
                (
                    <div>
                        {!props.error ?
                            (
                                <div>
                                    {props.hasItems ? (
                                        <section className="wow-section bg-white">
                                            <h3 className="container section-title">
                                                {props.title}
                                            </h3>
                                            <Swiper
                                                spaceBetween={32}
                                                breakpoints={swiperBreakpoints}
                                                pagination={pagination}
                                                className="container
                                                    section-grid
                                                    swiper-container
                                                    swiper-card-products"
                                            >
                                                {props.children}
                                            </Swiper>
                                            <button
                                                type="button"
                                                onClick={props.onViewAll}
                                                className="btn btn-primary"
                                            >
                                                {t(VIEW_ALL)}
                                            </button>
                                            {props.showAdvice ? (
                                                <p className="small text-muted mt-4">
                                                    {t(SETTLEMENT_SENTENCE)}
                                                </p>
                                            ) : null}
                                        </section>
                                    ) : null}
                                </div>
                            ) : <ServerErrorMessage />}
                    </div>
                )
                : <Spinner classCss="basic-spinner-container" />}
        </div>
    );
};
