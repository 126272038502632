import { Address } from 'app/account/domain/value-objects/address';
import { PostalCode } from 'app/account/domain/value-objects/postal-code';
import { Country } from './country';
import { ShippingAddressId } from './ShippingAddressId';
import { Province } from './Province';
import { ShippingAddress } from './ShippingAddress';
import { ProvinceId } from './value-objects/ProvinceId';
import { Phone } from '../../account/domain/value-objects/phone';
import { Name } from '../../shared/domain/value-objects/name';

export class ShippingAddressDefault extends ShippingAddress {

    constructor(
        code: ShippingAddressId,
        description: string,
        country: Country | null,
        postalCode: PostalCode,
        locality: string,
        address: Address,
        address1: Address,
        address2: Address,
        provinceId: ProvinceId,
        private _province: Province | null,
        protected _portal: boolean,
        phone: Phone | null,
        contact: Name | null,
    ) {
        super(
            code,
            description,
            country,
            provinceId,
            postalCode,
            locality,
            address,
            address1,
            address2,
            _portal,
            phone,
            contact,
        );
    }

    public get province(): Province | null {
        return this._province;
    }

}
