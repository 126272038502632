import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CANCEL, TOTAL_AMOUNT, VALIDATE_CART } from '../../../../../i18n/translations/TR';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';
import { Currency } from '../../../../shared/Currency';
import { UseCartCheckoutFormInterface } from '../../hooks/useCartCheckoutForm';
import Cart from '../../../../cart/domain/model/Cart';
import { useIsMounted } from '../../../../shared/hooks/useIsMounted';

interface Props {
    cart: Cart;
    onSubmit: () => Promise<void>;
    cartSummaryForm: UseCartCheckoutFormInterface;
}

export const CartSummaryFooter: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const isMounted = useIsMounted();
    const navigate = useNavigate();
    const [confirmInProgress, setConfirmInProgress] = React.useState(false);

    const backToCart = (): void => {
        navigate(TencerRoutes.CART);
    };

    const onSubmit = async (): Promise<void> => {
        if (!isMounted) {
            return;
        }
        setConfirmInProgress(true);
        await props.onSubmit().then(() => setConfirmInProgress(false));
    };

    return (
        <div className="cart-summary-footer">
            <div className="cart-summary-footer-price-container">
                <div className="cart-summary-footer-total-price-title">{t(TOTAL_AMOUNT)}</div>
                <div className="cart-summary-footer-total-price-import">
                    <Currency value={props.cart.totalAmount.amount} />
                </div>
            </div>
            <div className="cart-summary-footer-buttons-container">
                <button type="button" className="btn btn-primary-outline" onClick={backToCart}>
                    {t(CANCEL)}
                </button>

                <button
                    className="c-action-button btn action-button "
                    type="button"
                    disabled={(props.cartSummaryForm && props.cartSummaryForm.POIsBusy) || confirmInProgress}
                    onClick={onSubmit}
                >
                    {
                        confirmInProgress
                            ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />)
                            : null
                    }
                    <span className="text">{t(VALIDATE_CART)}</span>
                </button>
            </div>
        </div>
    );
};
