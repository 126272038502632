import { Origin } from '../../../shared/domain/value-objects/Origin';
import { ApplicationAccessType } from '../value-objects/ApplicationAccessType';
import { Email } from '../../../account/domain/value-objects/email';

export class RequestAccess {

    constructor(
        private _email: Email,
        private _companyName: string,
        private _accessType: ApplicationAccessType,
        private _origin: Origin,
    ) {}

    get email(): Email {
        return this._email;
    }

    get companyName(): string {
        return this._companyName;
    }

    get accessType(): ApplicationAccessType {
        return this._accessType;
    }

    get origin(): Origin {
        return this._origin;
    }

}
