import React, { ReactElement } from 'react';
import { SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import 'swiper/components/pagination/pagination.scss';
import { useTranslation } from 'react-i18next';
import { OUTLET } from '../../../../../i18n/translations/TR';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';
import { ApiProduct } from '../../../infrastructure/api/response/ApiProduct';
import { SwiperCard } from '../../../../shared/SwiperCard';
import { Carousel } from '../Carousel';

interface OutletCarouselProps {
    outletProducts: ApiProduct[]
    isGettingOutletProducts: boolean
    errorGettingOutletProducts: boolean
}

export const OutletCarousel = (props: OutletCarouselProps): ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goToCatalog = ():void => {
        navigate(TencerRoutes.CATALOG, { state: { productsType: t(OUTLET) } });
    };

    return (
        <Carousel
            showAdvice
            title={t(OUTLET)}
            onViewAll={goToCatalog}
            error={props.errorGettingOutletProducts}
            isLoading={props.isGettingOutletProducts}
            hasItems={props.outletProducts.length > 0}
        >
            <>
                {(props.outletProducts.map((product) => (
                    <SwiperSlide key={product.product} className="swiper-slide">
                        <div
                            className="text-decoration-none w-100"
                            key={product.product}
                        >
                            <SwiperCard
                                product={product}
                                title={t(OUTLET)}
                            />
                        </div>
                    </SwiperSlide>
                )))}
                <div className="swiper-pagination" />
            </>
        </Carousel>
    );
};
