import React from 'react';
import OrderLine from '../../model/OrderLine';
import { OrderLineItem } from './OrderLineItem';
import { PalletFeeLine } from './palletFeeLine/PalletFeeLine';

interface LinesListProps {
    lines: OrderLine[],
    palletFeeLine: OrderLine | null
}

export const OrderLinesList = (
    {
        lines,
        palletFeeLine,
    }: LinesListProps,
): React.ReactElement => {
    return (
        <ul className="section-list">
            {
                (
                    lines.map((line: OrderLine) => (
                        <OrderLineItem
                            line={line}
                            key={line.idx.value}
                        />
                    ))
                )
            }
            {
                palletFeeLine ?
                    (
                        <PalletFeeLine line={palletFeeLine} />
                    ) :
                    null
            }
        </ul>
    );
};
