import { ErrorCode } from 'app/shared/domain/value-objects/ErrorCode';
import { Origin } from 'app/shared/domain/value-objects/Origin';
import { QuantityUnit } from 'app/shared/domain/value-objects/QuantityUnit';
import { useToken } from 'app/shared/hooks/use-token';
import {
    BOXES,
    CART_ADD_WARNING_BIG_RESERVE,
    ERROR_CODE_0,
    FEET2,
    M2_UNIT,
    NO_AVAILABLE_STOCK_ADD_CART,
    PALLETS,
    UNIT_STRING,
} from 'i18n/translations/TR';
import React, { useState } from 'react';
import {
    FieldErrors,
    SubmitHandler,
    useForm,
    UseFormClearErrors,
    UseFormHandleSubmit,
    UseFormRegister,
    UseFormSetFocus,
    UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCartItemsList } from '../../cart/shared/useCartItemList';
import { ApiProductDetail } from '../shared/domain/ApiProductDetail';
import { WarehouseLocation } from '../shared/domain/WarehouseLocation';
import { ShoppingModalFormInterface } from './Form/ShoppingModalFormInterface';

interface UseShoppingModalInterface {
    handleSubmit: UseFormHandleSubmit<ShoppingModalFormInterface>;
    register: UseFormRegister<ShoppingModalFormInterface>;
    setValue: UseFormSetValue<ShoppingModalFormInterface>;
    setFocus: UseFormSetFocus<ShoppingModalFormInterface>;
    submitHandler: SubmitHandler<ShoppingModalFormInterface>;
    clearErrors: UseFormClearErrors<ShoppingModalFormInterface>;
    closeModal: () => void;
    origin: string | undefined;
    unitOptions: { text: string; unit: string; }[];
    unitM2: { text: string; unit: string; };
    unitPiece: { text: string; unit: string; };
    warehouses: WarehouseLocation[];
    errors: FieldErrors<ShoppingModalFormInterface>;
    errorMessage: string;
    quantityStep: number;
    maxQuantity?: number;
    showNoStockUsaModal: boolean
    handleCloseNoStockUsaModal: () => void
    handleDifferentWarehousesModal: () => void
    showDifferentWarehousesModal: boolean
}

interface ForceProductAmount {
    productId: string;
    amount: number;
}

export const useShoppingModal = (
    productDetails: ApiProductDetail,
    onHideShop: () => void,
    handleShowForce: () => void,
    handleCloseForce: () => void,
):UseShoppingModalInterface => {
    const [force, setForce] = useState<ForceProductAmount|null>(null);
    const [showNoStockUsaModal, setShowNoStockUsaModal] = useState<boolean>(false);
    const [showDifferentWarehousesModal, setShowDifferentWarehousesModal] = useState<boolean>(false);
    const { t } = useTranslation();

    const handleCloseNoStockUsaModal = (): void => {
        setShowNoStockUsaModal(false);
    };

    const handleDifferentWarehousesModal = (): void => {
        setShowDifferentWarehousesModal(false);
    };

    const cartItemsList = useCartItemsList();

    const [warehouses, setWarehouses] = React.useState<WarehouseLocation[]>([]);
    const userOrigin = useToken();

    const {
        register,
        handleSubmit,
        setValue,
        setFocus,
        watch,
        reset,
        formState: { errors },
        clearErrors,
    } = useForm<ShoppingModalFormInterface>({ defaultValues: {
        valueInput: 1,
    } });

    const fetchWarehouses = (): void => {
        const collection:WarehouseLocation[] = [];
        productDetails.lotInfo?.forEach((value) => {
            if (!value.warehouse_location) {
                return;
            }
            if (!collection.find((item) => item.code === value.warehouse_location?.code)) {
                collection.push(value.warehouse_location);
            }

        });
        setWarehouses(collection);
    };
    const watchValue = watch('valueInput', 1);
    const watchOriginalUnit = watch('unit');

    const [quantityStep, setQuantityStep] = React.useState<number>(1);
    const [quantityMax, setQuantityMax] = React.useState<number>();

    React.useEffect(() => {
        const allowDecimals = [QuantityUnit.FEET2.valueOf(), QuantityUnit.M2.valueOf()].includes(watchOriginalUnit);
        setQuantityStep(allowDecimals ? 0.001 : 1);
        setQuantityMax(QuantityUnit.PALLET === watchOriginalUnit ? 100 : undefined);
        if (!allowDecimals) {
            setValue('valueInput', Math.round(watchValue as number));
        }
    }, [watchOriginalUnit]);

    React.useEffect(() => {
        fetchWarehouses();
    }, []);

    const [errorMessage, setErrorMessage] = React.useState<string>('error');

    const closeModal = (): void => {
        onHideShop();
    };

    const submitHandler: SubmitHandler<ShoppingModalFormInterface> =
        async (data: ShoppingModalFormInterface): Promise<void> => {

            const mustForce = force?.productId === productDetails.product && force?.amount === data.valueInput;
            const addToCartResponse = await cartItemsList.addToCart(
                data.valueInput,
                data.unit,
                productDetails.product,
                mustForce ? '?force=true' : '',
                data.warehouse,
            ).catch((err) => {
                const errorCode = err.response.data.code;
                if (errorCode === ErrorCode.CART_NO_STOCK_AVAILABLE_USA) {
                    setShowNoStockUsaModal(true);
                } else if (errorCode === ErrorCode.CART_MUST_BE_IN_SAME_WAREHOUSE) {
                    setShowDifferentWarehousesModal(true);
                } else if (errorCode === ErrorCode.CART_BIG_RESERVE) {
                    setErrorMessage(CART_ADD_WARNING_BIG_RESERVE);
                    handleShowForce();
                    setForce({ productId: productDetails.product, amount: data.valueInput });
                } else if (
                    errorCode === ErrorCode.CART_NO_STOCK_AVAILABLE ||
                    errorCode === ErrorCode.CART_NO_STOCK_AVAILABLE_ON_SINGLE_LOT
                ) {
                    setErrorMessage(NO_AVAILABLE_STOCK_ADD_CART);
                    handleShowForce();
                    setForce({ productId: productDetails.product, amount: data.valueInput });
                } else {
                    setErrorMessage(ERROR_CODE_0);
                    handleShowForce();
                    setForce({ productId: productDetails.product, amount: data.valueInput });
                }
            });

            if (addToCartResponse) {
                onHideShop();
                handleCloseForce();
                reset();
                setForce(null);
            } else {
                closeModal();
            }
        };

    const origin = userOrigin?.origin;

    const unitOptions = [
        {
            text: t(BOXES),
            unit: QuantityUnit.BOX,
        },
        {
            text: t(PALLETS),
            unit: QuantityUnit.PALLET,
        },
    ];

    const unitM2 = origin && origin.match(Origin.USA) ?
        {
            text: t(FEET2),
            unit: QuantityUnit.M2,
        } :
        {
            text: t(M2_UNIT),
            unit: QuantityUnit.M2,
        };

    const unitPiece =
        {
            text: t(UNIT_STRING),
            unit: QuantityUnit.PIECE,
        };

    return {
        unitOptions,
        unitM2,
        unitPiece,
        handleSubmit,
        register,
        setValue,
        setFocus,
        submitHandler,
        clearErrors,
        closeModal,
        origin,
        warehouses,
        errors,
        errorMessage,
        quantityStep,
        maxQuantity: quantityMax,
        showNoStockUsaModal,
        handleCloseNoStockUsaModal,
        handleDifferentWarehousesModal,
        showDifferentWarehousesModal,
    };
};
