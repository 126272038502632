import { Invoice } from 'app/invoices/domain/model/Invoice';
import { InvoiceRepository } from '../../../domain/repository/InvoiceRepository';
import { TencerApiClient } from '../../../../shared/infrastructure/tencer-api/TencerApiClient';
import { InvoicesResponse } from './response/InvoicesResponse';
import { InvoicesMapper } from './mapper/InvoicesMapper';
import { InvoiceResponse } from './response/InvoiceResponse';
import { InvoiceMapper } from './mapper/InvoiceMapper';
import { InvoiceNumber } from '../../../domain/vo/InvoiceNumber';
import { InvoiceFilterCollection } from '../../../domain/model/InvoiceFilterCollection';
import { InvoiceFiltersMapper } from '../../../../orders/infrastructure/repository/api/mappers/InvoiceFiltersMapper';

export class ApiInvoiceRepository implements InvoiceRepository {

    constructor(private client: TencerApiClient) {
    }

    async find(id: InvoiceNumber): Promise<Invoice> {
        const invoice: InvoiceResponse = await this.client.getInvoice(id.toString());
        return InvoiceMapper(invoice);
    }
    async findAll(page: number, results: number, filters?: InvoiceFilterCollection): Promise<Invoice[]> {
        const paramFilters = InvoiceFiltersMapper.toWs(
            filters || new InvoiceFilterCollection([]),
        );

        const invoice: InvoicesResponse = await this.client.getInvoiceList(page, results, paramFilters);
        return InvoicesMapper(invoice);
    }

}
