import React from 'react';
import { ApiCollection } from 'app/home/infrastructure/api/response/ApiCollection';

interface FeaturedCollectionItemProps {
    collection: ApiCollection;
}

export const CollectionItem: React.FC<FeaturedCollectionItemProps> =

    (
        { collection },
    ) => {

        return (
            <li className="grid-item">
                <div className="product-image">
                    <img src={collection.image} alt="product" />
                </div>
                <div className="product-item-details">
                    <div className="product-title">{collection.name}</div>
                    <div className="product-description">{collection.name}</div>
                </div>
            </li>
        );
    };
