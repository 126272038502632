import { CourierResponse } from '../../../../../cartSummary/infrastructure/repository/api/dto/response/CourierResponse';
import { CourierServiceResponse } from '../../../../../cartSummary/infrastructure/repository/api/dto/response/CourierServiceResponse';

export interface OrderShipmentResponse {
    type:{
        name: string,
        value: ETransportTypeDTO,
    },
    consolidated: boolean | null
    description: string,
    lift_gate: boolean | undefined
    courier: CourierResponse | null
    courier_service: CourierServiceResponse | null
    loadId?: string
}

export enum ETransportTypeDTO {
    WOW = 1,
    OWN_LOGISTIC = 2,
    WILL_CALL = 3,
    SMALL_PARCEL = 4,
}
