import { Address } from 'app/account/domain/value-objects/address';
import { PostalCode } from 'app/account/domain/value-objects/postal-code';
import { Phone } from 'app/account/domain/value-objects/phone';
import { ShippingAddressDefaultResponse } from '../../../../model/responses/ShippingAddressesResponse';
import { ShippingAddressResponse } from '../../../../model/responses/ShippingAddressResponse';
import { ShippingAddress } from '../../../../model/ShippingAddress';
import { ShippingAddressDefault } from '../../../../model/ShippingAddressDefault';
import { ShippingAddressId } from '../../../../model/ShippingAddressId';
import { ProvinceId } from '../../../../model/value-objects/ProvinceId';
import { CountryMapper } from './CountryMapper';
import { ProvinceMapper } from './ProvinceMapper';
import { TypeAheadDTO } from '../../../../../shared/infrastructure/tencer-api/dto/typeAhead.DTO';
import { TypeAheadArrayDTO } from '../../../../../shared/infrastructure/tencer-api/dto/typeAheadArray.DTO';
import { Name } from '../../../../../shared/domain/value-objects/name';

export class ShippingAddressMapper {

    static fromResponse(response: ShippingAddressResponse): ShippingAddress {
        return new ShippingAddress(
            new ShippingAddressId(response.code),
            response.description?.trim(),
            response.country ? CountryMapper.fromResponse(response.country) : null,
            new ProvinceId(response.province_id ? response.province_id : ''),
            new PostalCode(response.postal_code),
            response.locality?.trim(),
            new Address(response.address),
            new Address(response.address1),
            new Address(response.address2),
            response.portal,
            response.phone ? new Phone(response.phone) : null,
            response.contact ? new Name(response.contact) : null,
        );
    }

    static fromDefaultResponse(response: ShippingAddressDefaultResponse): ShippingAddressDefault {
        return new ShippingAddressDefault(
            new ShippingAddressId(response.code),
            response.description?.trim(),
            response.country ? CountryMapper.fromResponse(response.country) : null,
            new PostalCode(response.postal_code),
            response.locality?.trim(),
            new Address(response.address),
            new Address(response.address1),
            new Address(response.address2),
            new ProvinceId(response.province_id ? response.province_id : ''),
            response.province ? ProvinceMapper.fromResponse(response.province) : null,
            response.portal,
            response.phone ? new Phone(response.phone) : null,
            response.contact ? new Name(response.contact) : null,
        );
    }

    static toTypeAHead(response: ShippingAddress): TypeAheadDTO {
        return {
            labelKey: response.description,
            value: response.code.value,
            item: response,
        };
    }

    static toTypeAHeadArray(response: ShippingAddress[]): TypeAheadArrayDTO {
        const array: TypeAheadArrayDTO = [] as unknown as TypeAheadArrayDTO;
        response.forEach((item): void => {
            array.push(this.toTypeAHead(item));
        });
        return array;
    }

}
