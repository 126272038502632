import { CourierService } from '../../../../../shared/domain/CourierService';
import { CourierServiceId } from '../../../../../shared/domain/value-objects/CourierServiceId';
import { CourierServiceResponse } from '../dto/response/CourierServiceResponse';

export class CourierServiceMapper {

    public static fromArrayResponse(response: CourierServiceResponse[]): CourierService[] {

        return response.map((courierServiceResponse) => {
            return this.fromResponse(courierServiceResponse);
        });

    }

    public static fromResponse(courierServiceResponse: CourierServiceResponse): CourierService {
        return new CourierService(
            new CourierServiceId(courierServiceResponse.code),
            courierServiceResponse.name,
        );
    }

}
