import { useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { useOrderRepository } from '../../../orders/application/hooks/useOrderRepository';
import Cart from '../../../cart/domain/model/Cart';
import { UserCredentialId } from '../../../shared/domain/value-objects/UserCredentialId';
import { Environment } from '../../../../environment';
import { ResponseError } from '../../../shared/domain/value-objects/ResponseError';
import { TencerRoutes } from '../../../shared/tencer-routing/TencerRoutes';

export interface UseCartPaymentResponse {
    clientSecret: string | undefined,
    stripePromise: Promise<Stripe | null> | undefined
}

interface UseCartPaymentProps {
    cart: null | Cart | undefined,
    userCredentialsId: UserCredentialId | undefined,
}

export const useCartPayment = (
    props: UseCartPaymentProps,
): UseCartPaymentResponse => {

    const orderRepository = useOrderRepository();
    const navigate = useNavigate();
    const [
        stripePromise,
        setStripePromise,
    ] = useState<Promise<Stripe | null>>();

    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        if (!props.cart || !props.cart.orderId || !props.userCredentialsId) {
            return;
        }

        orderRepository.fetchPaymentIntentMutation.mutate({
            orderId: props.cart.orderId,
            applicantId: props.userCredentialsId,
        }, {
            onSuccess: (response) => {
                setClientSecret(response.payment_intent_id);
            },
            onError: (error: ResponseError): void => {
                if (error.response.data.code === 80006) {
                    navigate(TencerRoutes.CART_PAYMENT_OK);
                }
            },
        });

        setStripePromise(loadStripe(Environment.STRIPE_PUBLISHING_KEY));
    }, [props.cart]);

    return {
        clientSecret,
        stripePromise,
    };
};
