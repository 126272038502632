import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Invoice } from '../../../domain/model/Invoice';
import { usePagination, usePaginationInterface } from '../../../../shared/hooks/usePagination';
import { InvoiceNumber } from '../../../domain/vo/InvoiceNumber';
import { useToken } from '../../../../shared/hooks/use-token';
import { useInvoiceFilters } from '../../hooks/useInvoiceFilters';
import { InvoiceFilterCollection } from '../../../domain/model/InvoiceFilterCollection';
import { DependencyInjectionContext } from '../../../../shared/context/DependecyInjectionContext';

interface Response {
    invoices: Invoice[],
    fetch: () => void,
    paginator: usePaginationInterface,
    isLoading: boolean,
    goToDetails: (id: InvoiceNumber) => void,
    handleApplyFilters: (appliedFilters: InvoiceFilterCollection) => void,
    showFilters: boolean,
    handleCloseFilters: () => void,
    handleShowFilters: () => void,
}

export const useInvoicesScreenController = (): Response => {
    const dic = useContext(DependencyInjectionContext);

    const [invoices, setInvoices] = useState<Invoice[]>([]);

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const handleCloseFilters = (): void => setShowFilters(false);
    const handleShowFilters = (): void => setShowFilters(true);

    const RESULTS_PER_PAGE = 15;
    const paginator = usePagination();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const history = useNavigate();
    const token = useToken();
    const invoiceFilters = useInvoiceFilters();

    const fetch = (): void => {
        setIsLoading(true);
        dic.invoiceRepository.findAll(paginator.actualPage, RESULTS_PER_PAGE, invoiceFilters.filters)
            .then((invoicesFound) => {
                const allResults = [...invoices, ...invoicesFound];
                setInvoices(allResults);
                paginator.refresh(invoicesFound.length);
                setIsLoading(false);
            });
    };

    const goToDetails = (id: InvoiceNumber): void => {
        history(`/invoices/${id}`);
    };

    useEffect(() => {
        setIsLoading(true);
        paginator.setActualPage(1);
        if (token) {
            fetch();
        }
    }, [invoiceFilters.filters]);

    const handleApplyFilters = (appliedFilters: InvoiceFilterCollection): void => {
        invoiceFilters.applyFilters(appliedFilters);
        paginator.setActualPage(1);
        setInvoices([]);
    };

    return {
        invoices,
        fetch,
        paginator,
        isLoading,
        goToDetails,
        handleApplyFilters,
        handleCloseFilters,
        handleShowFilters,
        showFilters,
    };
};
