import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TencerRoutes } from '../../../shared/tencer-routing/TencerRoutes';
import { CurrentUserContext } from '../../../shared/context/CurrentUserContext';
import { PaymentTerms } from '../../../orders/model/value-objects/PaymentTerms';
import { useToken } from '../../../shared/hooks/use-token';
import { CONTACT_EMAIL } from '../../../constants/hrefConstants';
import Cart from '../../domain/model/Cart';
import { DependencyInjectionContext } from '../../../shared/context/DependecyInjectionContext';
import { Price } from '../../../orders/model/value-objects/Price';

interface ICartPaymentTermsResponse {
    terms: PaymentTerms | undefined
    onReset: () => void
    onCheckout: () => Promise<void>
    onRequestSupport: () => void
    canPay: boolean
    error: boolean
    isLoading: boolean
}

export const CartPaymentTermsController = (): ICartPaymentTermsResponse => {
    const dic = useContext(DependencyInjectionContext);
    const navigate = useNavigate();
    const state = React.useContext(CurrentUserContext);
    const token = useToken();

    const [error, setError] = React.useState<boolean>(false);
    const [canPay, setCanPay] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [terms, setTerms] = React.useState<PaymentTerms | undefined>();

    useEffect(() => {
        if (!token.erpCode) {
            return;
        }

        dic.erpUserRepository.getPaymentTerms()
            .then((termsResponse) => {
                if (!termsResponse) {
                    return;
                }
                setTerms(termsResponse);
                dic.cartRepository.getMyCart()
                    .then(
                        (myCart: Cart|null) => {
                            const cartAmount = myCart?.totalAmount ?? Price.zero();
                            setCanPay((
                                termsResponse.credit_available.add(
                                    cartAmount,
                                )).amount <= termsResponse.credit_limit.amount);
                            setIsLoading(false);
                        },
                    )
                    .catch(() => {
                        setError(true);
                        setIsLoading(false);
                    });
            })
            .catch(() => {
                setError(true);
                setIsLoading(false);
            });

    }, []);

    const onCheckout = async (): Promise<void> => {
        const myCart = await dic.cartRepository.getMyCart();
        if (!myCart) {
            return Promise.reject();
        }

        return dic.cartRepository.checkout()
            .then(() => {
                state?.setExpirationDate(null);
                state?.setCartItems(undefined);
                navigate(TencerRoutes.CART_PAYMENT_OK);
            })
            .catch(() => {
                navigate(TencerRoutes.CART_PAYMENT_KO);
            });
    };

    const onReset = (): void => navigate(TencerRoutes.CART_SUMMARY);
    const onRequestSupport = (): void => {
        window.location.href = `mailto:${CONTACT_EMAIL}`;
    };

    return {
        terms,
        onReset,
        onCheckout,
        onRequestSupport,
        canPay,
        error,
        isLoading,
    };

};
