import { Country } from '../../../../model/country';
import { CountryResponse } from '../../../../model/responses/CountryResponse';

export class CountryMapper {

    static fromResponse(response: CountryResponse): Country {
        return new Country(response.country_id ?? '', response.name.trim(), response.region);
    }

}
