enum InvoiceLineTypeValues {
    Item = 'Item',
}
export class InvoiceLineType {

    constructor(
        public readonly value: string,
    ) {
    }

    get isItem(): boolean {
        return this.value === InvoiceLineTypeValues.Item;
    }

}
