import { ProductFilter } from './ProductFilter';

export class ProductFilterCollection {

    private _filters: { [key: string]: ProductFilter } = {};

    public get filters(): { [key: string]: ProductFilter; } {
        return this._filters;
    }
    public set filters(value: { [key: string]: ProductFilter }) {
        this._filters = value;
    }

    public get length(): number {
        return Object.values(this._filters).length;
    }

    constructor(defaultFilters: ProductFilter[]) {
        defaultFilters.forEach((filter: ProductFilter) => {
            this.addFilter(filter);
        });
    }

    public getValue(key: string): ProductFilter {
        return this._filters[key];
    }

    public addFilter(filter: ProductFilter): void {
        this._filters[filter.name] = filter;
    }

    public removeFilter(key: string): void {
        delete this._filters[key];
    }

    public toArray(): ProductFilter[] {
        return Object.values(this._filters);
    }

}
